import {useMutation, useQueryClient} from "@tanstack/react-query";
import {ChannelGroup, ChannelGroupUpdateRequest} from "../../model/channelGroup/channelGroup";
import {AxiosError} from "axios";
import channelGroupRepository from "../../respsitory/ChannelGroupRepository";

const useUpdateChannelGroup = () => {
    const queryClient = useQueryClient();
    return useMutation<ChannelGroup, AxiosError, ChannelGroupUpdateRequest & { _id: string }>({
        mutationKey: ["channelGroup", "update"],
        mutationFn: async (body: ChannelGroupUpdateRequest & { _id: string }) => {
            try {
                const {_id, ...extra} = body
                const {data} = await channelGroupRepository.update(_id, extra);
                return data.result;
            } catch (e) {
                throw e;
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["channelGroup"],
                exact: false
            })
        }
    })
}

export default useUpdateChannelGroup;
