import React, {useEffect, useMemo, useRef, useState} from "react";

import _ from "lodash";
import toast from "react-hot-toast";

import {useDialog} from "../../hook/useDialog";
import moment from "moment";
import {useIntl} from "react-intl";
import useCreatePrompt from "../../query/prompt/useCreatePrompt";
import useDeletePrompt from "../../query/prompt/useDeletePrompt";
import useUpdatePrompt from "../../query/prompt/useUpdatePrompt";
import {
    LLMType,
    Prompt,
    PromptBase,
    PromptContentItem,
    PromptCreateRequest,
    PromptType,
    PromptTypeList,
    PromptUpdateRequest,
} from "../../model/prompt/prompt";
import PromptRepository from "../../respsitory/PromptRepository";
import LLMPrompt from "./LLMPrompt";
import {TrashIcon} from "@heroicons/react/24/outline";
import {useList} from "@react-hookz/web";
import * as uuid from "uuid";
import {DictType} from "../../model";
import PromptHistoryModal, {PROMPT_HISTORY_MODAL_ID} from "./promptHistoryModal";
import useModal from "../../hook/useModal";
import {useQueryClient} from "@tanstack/react-query";

type PromptSettingFormProps = {
    prompt?: Prompt
    copyPrompt?: PromptBase
    onCancel: () => void;
    onDelete: () => void;
    onSubmit: (specificationId: string) => void;
    onClickCopyPrompt: (prompt: PromptBase) => void
}


const INIT_INVALID = {
    key: false,
    llmType: false,
    promptCheck: false
}

const PromptSettingForm: React.FC<PromptSettingFormProps> = (props) => {
    const {prompt, copyPrompt, onCancel, onDelete, onSubmit, onClickCopyPrompt} = props;
    const intl = useIntl();
    const dialog = useDialog();
    const modal = useModal();
    const queryClient = useQueryClient();

    const {mutateAsync: createPrompt} = useCreatePrompt();
    const {mutateAsync: updatePrompt} = useUpdatePrompt();
    const {mutateAsync: deletePrompt} = useDeletePrompt();

    const keyRef = useRef<HTMLInputElement>(null);
    const descriptionRef = useRef<HTMLInputElement>(null);

    const [invalid, setInvalid] = useState<{ key: boolean, llmType: boolean, promptCheck: boolean }>(INIT_INVALID);
    const [llmType, setLlmType] = useState<LLMType>(LLMType.GPT);
    const [content, setContent] = useState<PromptContentItem[]>([]);
    const [selectedLLMUseCase, setSelectedLLMUseCase] = useState<PromptType>(PromptType.PASSAGE_ANSWER);


    const promptKey = useMemo(()=> {
        return prompt ? prompt.key : copyPrompt?.key
    }, [prompt, copyPrompt])
    const description = useMemo(()=> {
        return prompt ? prompt.description : copyPrompt?.description
    }, [prompt, copyPrompt])

    const modelParamRefs = useRef<{ key: HTMLInputElement | null, value: HTMLInputElement | null }[]>([]);
    const [modelParams, {
        set: setModelParams,
        push: pushModelParams,
        removeAt: removeAtModelParams,
    }] = useList<{ id: string, key?: string, value?: any }>([]);

    const onChangeKey = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        const key = e.target.value;
        if (prompt?.key === key) return;
        PromptRepository.existed(key).then(({data}) => {
            setInvalid(prev => ({
                ...prev,
                key: data.result
            }))
        }).catch(() => {
            toast.error(intl.formatMessage({id: "i0167"}))
        })
    }, 200);

    const onChangePromptType = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedLLMUseCase(e.target.value as PromptType);
    }

    const onChangeLLM = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setLlmType(e.target.value as LLMType);
    }

    const onClickAddParam = () => {
        pushModelParams({id: uuid.v4()})
    }

    const onClickDeleteParam = (index: number) => {
        modelParamRefs.current = [];
        removeAtModelParams(index);
    }

    const onClickDelete = () => {
        if (!prompt) {
            return;
        }

        dialog.open({
            variant: "danger",
            title: "프롬프트 정보 삭제",
            content: "프롬프트 정보를 삭제하시겠습니까?",
            onConfirm: async () => {
                try {
                    await deletePrompt(prompt._id);
                    toast.success("프롬프트 정보를 삭제했습니다.");
                    onDelete();
                } catch (e) {
                    toast.error("프롬프트를 삭제하는 도중 에러가 발생했습니다.");
                }
            }
        });

    }

    const onClickCancel = () => {
        onCancel();
    }

    const onClickSubmit = async () => {
        const key = keyRef.current?.value;
        const description = descriptionRef.current?.value;
        const promptCheck = content.length === 0
        const invalid = {
            key: !key,
            llmType: !llmType,
            promptCheck
        }
        setInvalid(invalid);

        if (Object.values(invalid).some(v => v)) {
            toast.error(intl.formatMessage({id: "i0176"}))
            return;
        }

        const validModelParams: { key: string, value: string }[] = [];
        modelParamRefs.current.forEach((ref) => {
            if (ref.key?.value && ref.value?.value) {
                validModelParams.push({key: ref.key.value, value: ref.value.value});
            }
        })

        const parameters = validModelParams.reduce((result, param) => {
            result[param.key] = param.value;
            return result
        }, {} as DictType<any>)

        const systemContent = content.filter(item => item.role === "system")
        const userContent = content.filter(item => item.role === "user")
        let contentData: PromptContentItem[] = []

        if (llmType === LLMType.GPT) {
            contentData = [...contentData, ...systemContent, ...userContent];
        } else if (llmType === LLMType.LLM42) {
            contentData = [...contentData, ...userContent];
        }

        if (prompt) {
            const body: PromptUpdateRequest = {
                key: key!,
                description,
                type: selectedLLMUseCase,
                llmType: llmType,
                content: contentData,
                llmParameters: parameters
            }

            dialog.open({
                variant: "danger",
                title: `프롬프트 수정`,
                content: `프롬프트를 수정하려고 합니다. 계속 하시겠습니까?`,
                onConfirm: async () => {
                    try {
                        await updatePrompt({_id: prompt._id, ...body});
                        toast.success("프롬프트 정보를 수정했습니다.")
                        await queryClient.invalidateQueries(["promptHistory", prompt._id], {exact: true});
                        onSubmit(prompt._id);
                    } catch (e) {
                        toast.error("프롬프트 정보를 수정하는 도중 에러가 발생했습니다.")
                    }
                }
            });
        } else {
            const body: PromptCreateRequest = {
                key: key!,
                description,
                type: selectedLLMUseCase,
                llmType: llmType,
                content: contentData,
                llmParameters: parameters
            }

            try {
                await createPrompt(body);
                toast.success("프롬프트를 생성했습니다.")
                onSubmit(body.key);
            } catch (e) {
                toast.error("프롬프트를 생성하는 도중 에러가 발생했습니다.")
            }
        }
    }

    const onClickCopy = async () => {
        if (!prompt) return;
        const key = prompt.key
        const currentDate = moment().format('YYYY-MM-DD HH:mm');
        const copiedKey = `${key}_copied(${currentDate})`
        const copyPrompt = {...prompt, key: copiedKey}
        onClickCopyPrompt(copyPrompt)
    }

    const onClickShowHistoryModal = () => {
        modal.open(PROMPT_HISTORY_MODAL_ID);
    }

    useEffect(() => {
        const data = prompt ?? copyPrompt
        if (data) {
            setLlmType(data.llmType);
            setContent(data.content);
            setSelectedLLMUseCase(data.type);
            setModelParams(Object.entries(data.llmParameters).map(([key, value]) => ({id: uuid.v4(), key, value})))
        } else {
            setContent([]);
            setModelParams([])
        }
    }, [prompt, copyPrompt, setModelParams])

    return (
        <div>
            <div className="text-xl font-semibold mb-2">
                <div className="flex items-center justify-end pt-3 space-x-2">
                    {prompt &&
                        <div className="flex items-center justify-between space-x-2">
                            <button className="btn bg-purple-800 text-white transition-colors duration-200"
                                    onClick={onClickShowHistoryModal}>
                                변경이력 보기
                            </button>
                            <PromptHistoryModal promptId={prompt._id}/>
                            <button className="btn bg-green-500 text-white transition-colors duration-200"
                                    onClick={onClickCopy}>
                                사본생성
                            </button>
                        </div>
                    }
                    <button className="btn btn-primary transition-colors duration-200" onClick={onClickSubmit}>
                        {prompt ? intl.formatMessage({id: "i0200"}) : intl.formatMessage({id: "i0201"})}
                    </button>
                    {prompt &&
                        <button className="btn btn-danger transition-colors duration-200" onClick={onClickDelete}>
                            {intl.formatMessage({id: "i0199"})}
                        </button>
                    }
                    <button className="btn btn-secondary-outline transition-colors duration-200"
                            onClick={onClickCancel}>
                        {intl.formatMessage({id: "i0198"})}
                    </button>
                </div>
            </div>
            <div className="border border-gray-400 rounded-md shadow-lg p-5 flex flex-col overflow-y-auto">
                <div className="text-2xl font-semibold mb-3 border-b-2 pb-3">
                    <div className="flex items-center justify-between">
                        <div className={"flex justify-between items-center"}>
                            <p>프롬프트 설정</p>
                        </div>
                    </div>
                </div>
                <div className="form-input-group flex">
                    <label className="form-label min-w-[200px] text-lg">
                        프롬프트 키
                    </label>
                    <div className="w-full">
                        <input defaultValue={promptKey}
                               ref={keyRef}
                               onChange={onChangeKey}
                               className="form-input w-full focus:outline-none"
                        />
                        {invalid.key && <small className="text-red-500">{intl.formatMessage({id: "i0184"})}</small>}
                    </div>
                </div>

                <div className="form-input-group flex">
                    <label className="form-label min-w-[200px] text-lg">
                        설명
                    </label>
                    <input defaultValue={description}
                           ref={descriptionRef}
                           className="form-input w-full focus:outline-none"
                    />
                </div>

                <div className="form-input-group flex">
                    <label className="form-label min-w-[200px] text-lg">
                        프롬프트 유형
                    </label>
                    <select className="form-select w-full px-2 focus:outline-none"
                            value={selectedLLMUseCase}
                            onChange={onChangePromptType}
                    >
                        {PromptTypeList.map((item) => {
                                const {name, type} = item
                                return (
                                    <option key={type} value={type}>{name}</option>
                                )
                            }
                        )}
                    </select>
                </div>

                <div className="form-input-group flex items-start">
                    <label className="form-label min-w-[200px] text-lg whitespace-pre-wrap">
                        {"Model Parameters\n(Key-Value Pair)"}
                    </label>
                    <div className="w-full">
                        {modelParams.map((modelParam, index) => {
                            return (
                                <div key={modelParam.id}
                                     className="flex items-center space-x-2">
                                    <input className="form-input"
                                           defaultValue={modelParam.key}
                                           placeholder={"파라미터 이름"}
                                           ref={(el: HTMLInputElement) => {
                                               if (el) {
                                                   if (!modelParamRefs.current[index]) {
                                                       modelParamRefs.current[index] = { key: null, value: null};
                                                   }
                                                   modelParamRefs.current[index].key = el;
                                               }
                                           }}/>
                                    <input className="form-input"
                                           defaultValue={modelParam.value}
                                           placeholder={"파라미터 값"}
                                           ref={(el: HTMLInputElement) => {
                                               if (el) {
                                                   if (!modelParamRefs.current[index]) {
                                                       modelParamRefs.current[index] = { key: null, value: null};
                                                   }
                                                   modelParamRefs.current[index].value = el;
                                               }
                                           }}/>
                                    <TrashIcon className="w-12 h-12 cursor-pointer"
                                               onClick={() => onClickDeleteParam(index)}/>
                                </div>
                            )
                        })}

                        <button className="btn btn-secondary-outline transition-colors duration-200 btn-sm w-full"
                                onClick={onClickAddParam}>
                            + 파라미터 추가하기
                        </button>
                    </div>
                </div>

                {prompt &&
                    <>
                        <div className="form-input-group flex items-center">
                            <label className="form-label min-w-[150px] text-lg">
                                {intl.formatMessage({id: "i0196"})}
                            </label>
                            <p>{moment.utc(prompt.createdAt).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                        </div>

                        <div className="form-input-group flex items-center">
                            <label className="form-label min-w-[150px] text-lg">
                                {intl.formatMessage({id: "i0197"})}
                            </label>
                            <p>{moment.utc(prompt.updatedAt).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                        </div>
                    </>
                }
            </div>
            <div className="border border-gray-400 rounded-md shadow-lg p-5 pb-7 mt-5">
                <div className="text-2xl font-semibold mb-3 border-b-2 pb-3">
                    <div className="flex items-center justify-between">
                        <div className={"flex justify-between items-center"}>
                            <p>프롬프트 내용</p>
                        </div>
                    </div>
                </div>
                <LLMPrompt llmType={llmType} content={content} setContent={setContent} onChangeLLM={onChangeLLM}
                           invalid={invalid} selectedPromptType={selectedLLMUseCase}/>
            </div>
        </div>

    )
};

export default PromptSettingForm;
