import {useMutation, useQueryClient} from "@tanstack/react-query";
import {SiteBunnyAdminError, toSiteBunnyAdminError} from "../../error";
import {GlobalVariables, GlobalVariablesUpdateRequest} from "../../model/GlobalVariables";
import globalVariablesRepository from "../../respsitory/GlobalVariablesRepository";


export const useUpdateGlobalVariables = () => {
    const queryClient = useQueryClient();
    return useMutation<GlobalVariables, SiteBunnyAdminError, GlobalVariablesUpdateRequest & {_id: string}>({
        mutationKey: ['globalVariable', 'update'],
        mutationFn: async (body: GlobalVariablesUpdateRequest & {_id: string}) => {
            try {
                const {_id, ...extra} = body;
                const { data } = await globalVariablesRepository.update(_id, extra);
                return data.result;
            } catch (e) {
                throw toSiteBunnyAdminError(e);
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(['globalVariable'])
        }
    })
};
