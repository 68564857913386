import {useMutation, useQueryClient} from "@tanstack/react-query";
import {
    GlobalVariableGroup, GlobalVariableGroupCreateRequest,
} from "../../model/globalVariable/GlobalVariableGroup";
import {AxiosError} from "axios";
import GlobalVariableGroupRepository from "../../respsitory/GlobalVariableGroupRepository";

const useCreateGlobalVariableGroup = () => {
    const queryClient = useQueryClient();
    return useMutation<GlobalVariableGroup, AxiosError, GlobalVariableGroupCreateRequest>({
        mutationKey: ["globalVariableGroup", "create"],
        mutationFn: async (body: GlobalVariableGroupCreateRequest) => {
            try {
                const {data} = await GlobalVariableGroupRepository.create(body);
                return data.result
            } catch (e) {
                throw e;
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["globalVariableGroup"],
                exact: false
            })
        }
    })
}

export default useCreateGlobalVariableGroup;
