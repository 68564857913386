import React, {ReactNode, useCallback, useMemo} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import * as authRepository from "../respsitory/AuthRepository"
import toast from "react-hot-toast";
// @ts-ignore
import logo from "../assets/svgs/onboarding_logo.svg";
import {
    ChartBarIcon,
    CircleStackIcon,
    Cog8ToothIcon,
    CreditCardIcon,
    FunnelIcon,
    LanguageIcon,
    RectangleGroupIcon,
    UsersIcon
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import useProfile from "../query/auth/useProfile";

const NAVIGATOR_ITEMS = [
    {
        name: "채널관리",
        path: "/channels",
        field: "channel",
        icon: <RectangleGroupIcon className="w-6 h-6"/>
    },
    {
        name: "대시보드",
        path: "/dashboard",
        field: "dashboard",
        icon: <ChartBarIcon className="w-6 h-6"/>
    },
    {
        name: "결제 관리",
        path: "/payments",
        field: "payments",
        icon: <CreditCardIcon className="w-6 h-6"/>
    },
    {
        name: "고객 관리",
        path: "/user",
        field: "user",
        icon: <UsersIcon className="w-6 h-6"/>
    },
    {
        name: "프롬프트 관리",
        path: "/prompt",
        field: "prompt",
        icon: <LanguageIcon className="w-6 h-6"/>
    },
    {
        name: "검색 관리",
        path: "/search",
        field: "search",
        icon: <CircleStackIcon className="w-6 h-6"/>
    },
    {
        name: "LLM 관리",
        path: "/llms",
        field: "llm",
        icon: <FunnelIcon className="w-6 h-6"/>
    },
    {
        name: "설정",
        path: "/setting",
        field: "setting",
        icon: <Cog8ToothIcon className="w-6 h-6"/>
    },
    // {
    //     name: "문서 검색",
    //     path: "dashboard"
    // },
    // {
    //     name: "문서 인덱싱",
    //     path: "dashboard"
    // }
]
const LeftNavigatorProvider: React.FC<{element: ReactNode}> = ({element}) => {
    const navigate = useNavigate()
    const location = useLocation();

    const {data: user, remove} = useProfile({useErrorBoundary: false});
    const currentPath = useMemo(() => {
        return location.pathname
    }, [location])

    const logout = useCallback(async () => {
        try {
            await authRepository.logout()
            remove()
            navigate("/login", {replace: true});
        } catch (e) {
            toast.error("실패!")
        }
    }, [navigate, remove])
    return (
        <div className='flex h-full'>
            <div className="flex-[0_0_20%] min-w-[300px] bg-gradient-to-b from-gray-900 to-gray-800 border-r border-r-2 flex flex-col text-white">
                <div className="m-5 space-x-3 flex items-center">
                    <img src={logo} alt="sitebunny" width={40}/>
                    <p className="text-2xl text-gray-300 font-bold">SiteBunny Admin</p>
                </div>

                <div className='flex flex-col grow m-3 space-y-2'>
                    {NAVIGATOR_ITEMS.map((item) => {
                        const active = currentPath.indexOf(item.path) === 0;
                        const name = item.name;
                        const field = item.field;
                        const authority = user?.accessAuthority.filter(item => item === field)[0]
                        if (!authority) return null;
                        return (
                            <Link key={`nav-item-${name}`} to={item.path}
                                  className={classNames(
                                      "transition-colors duration-200 hover:bg-gray-700 rounded-md p-3 flex items-center space-x-2",
                                      active ? "bg-gray-800 text-white" : "text-gray-400"
                                  )}>
                                {item.icon}
                                <p className="font-semibold text-lg">{name}</p>
                            </Link>
                        )
                    })}

                </div>
                <button onClick={logout} className="btn btn-dark-outline hover:bg-gray-700 transition-colors duration-200 m-3 w-auto">
                    Logout
                </button>

            </div>

            <div className="flex-[0_0_80%] overflow-y-auto">
                {element}
            </div>
        </div>
    )
}

export default  LeftNavigatorProvider