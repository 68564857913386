import queryLogRepository from "../../respsitory/QueryLogRepository";
import {useQuery} from "@tanstack/react-query";
import { QueryLog } from "../../model/QueryLog";
import {AxiosError} from "axios";

const getData = async (queryLogId: string) => {
    try {
        const {data} = await queryLogRepository.read(queryLogId);
        return data.result;
    } catch (e) {
        throw e;
    }
}

const useQueryLog = (queryLogId: string) => {
    return useQuery<QueryLog, AxiosError>({
        queryKey: ["queryLog", queryLogId],
        queryFn: async () => getData(queryLogId),
        retry: false,
        suspense: false
    })
}

export default useQueryLog;