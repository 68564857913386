import axios from "axios";
import {API_HOST} from "../core/variables";
import {GlobalVariablesBase, GlobalVariablesUpdateRequest} from "../model/GlobalVariables";


class GlobalVariablesRepository {
    async find(key?: string, group?: string) {
        return axios.get(`${API_HOST}/v1/global-variables`,
            {
                params: {
                    key: key,
                    group: group
                }
            })
    }

    async findHistory(id: string) {
        return axios.get(`${API_HOST}/v1/global-variable-history/${id}`)
    }


    async existed(key: string) {
        return axios.get(`${API_HOST}/v1/global-variables/existed`,
            {
                params: {
                    key: key
                }
            })
    }

    async create(body: GlobalVariablesBase) {
        return axios.post(`${API_HOST}/v1/global-variables`, body)
    }

    async delete(id: string) {
        return axios.delete(`${API_HOST}/v1/global-variables/${id}`)
    }

    async update(id: string, body: GlobalVariablesUpdateRequest) {
        return axios.put(`${API_HOST}/v1/global-variables/${id}`, body);
    }

}

const globalVariablesRepository = new GlobalVariablesRepository();

export default globalVariablesRepository;
