import React, {useCallback, useMemo, useState} from "react";
import useGlobalVariables from "../../../query/globalVariables/useGlobalVariables";
import toast from "react-hot-toast";
import {defaultVariable} from "./DefaultVariable";
import ThinScrollBarStyle from "../../ThinScrollBar";
import {CheckIcon, DocumentDuplicateIcon} from "@heroicons/react/24/outline";

type DefaultVariableGuideProps = {
    selectedPromptType: string,
    close: () => void
}
const VariableGuide: React.FC<DefaultVariableGuideProps> = (props) => {
    const {selectedPromptType, close} = props;
    const {data: globalVariables} = useGlobalVariables();

    const [copiedKey, setCopiedKey] = useState<string>();

    const AnalyzeQueryDefaultVariable = useMemo(() => {
        return defaultVariable.filter(item => item.key === selectedPromptType)[0].data
    }, [selectedPromptType])

    const handleCopyClipBoard = useCallback((text: string) => {
        try {
            navigator.clipboard.writeText(`{${text}}`);
            setCopiedKey(text)
            toast.success('변수명이 복사 되었습니다.');
        } catch (e) {
            toast.error('변수명이 복사 되었습니다.');
        }
    }, [])

    return (
        <div className="w-full h-full">
            <div className={"flex flex-col gap-3 border-gray-900 border-b h-full bg-indigo-600 text-white p-5"}>
                <div className={"h-1/5 flex justify-between"}>
                    <div className={"text-3xl font-semibold"}>
                        변수 목록
                    </div>
                    <button className={"border border-white w-8 h-8 rounded-lg"} onClick={close}>X</button>
                </div>
            </div>
            <ThinScrollBarStyle className="w-full h-full bg-white rounded-md border">
                <ThinScrollBarStyle
                    className="m-4 max-h-[90vh] overflow-y-scroll scrollbar-thumb-gray-300 flex flex-row items-center flex-wrap">
                    <div className="mb-2 w-[600px]">
                        <label className="text-2xl">
                            디폴트 변수
                        </label>
                        <div className="mt-3 ml-3">
                            {AnalyzeQueryDefaultVariable?.map((item) => {
                                const {key, description} = item;
                                return (
                                    <div key={key} className="mb-2 flex w-full">
                                        <button
                                            className="border-2 rounded-2xl w-[50%] p-2 duration-200 hover:border-black mr-2"
                                            onClick={() => handleCopyClipBoard(key)}
                                        >
                                            <div className="flex items-center justify-between ml-2">
                                                {key}
                                                <div>
                                                    {copiedKey !== key
                                                        ? <DocumentDuplicateIcon className="w-5 h-5 text-gray-400"/>
                                                        : <CheckIcon className="w-5 h-5 text-green-500"/>
                                                    }
                                                </div>
                                            </div>
                                        </button>

                                        <input
                                            className="border-2 rounded-2xl mr-2 p-2 w-[50%]"
                                            defaultValue={description}
                                            readOnly={true}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="mb-2 w-[600px]">
                        <label className="text-2xl ">
                            커스텀 프롬프트 변수
                        </label>
                        <div className="mt-3 ml-3">
                            <div className="mb-2 flex w-full">
                                <button
                                    className="border-2 rounded-2xl mr-2 p-2 w-[50%] duration-200 hover:border-black"
                                    onClick={() => handleCopyClipBoard("attitude")}
                                >
                                    <div className="flex items-center justify-between">
                                        답변말투 프롬프트
                                        <div>
                                            {copiedKey !== "attitude"
                                                ? <DocumentDuplicateIcon className="w-5 h-5 text-gray-400"/>
                                                : <CheckIcon className="w-5 h-5 text-green-500"/>
                                            }
                                        </div>
                                    </div>
                                </button>
                                <input
                                    className="border-2 rounded-2xl mr-2 p-2 w-[50%]"
                                    defaultValue="답변말투 커스텀 프롬프트"
                                    readOnly={true}
                                />
                            </div>
                            <div className="mb-2 flex w-full">
                                <button
                                    className="border-2 rounded-2xl mr-2 p-2 w-[50%] duration-200 hover:border-black"
                                    onClick={() => handleCopyClipBoard("length")}
                                >
                                    <div className="flex items-center justify-between">
                                        답변길이 프롬프트
                                        <div>
                                            {copiedKey !== "length"
                                                ? <DocumentDuplicateIcon className="w-5 h-5 text-gray-400"/>
                                                : <CheckIcon className="w-5 h-5 text-green-500"/>
                                            }
                                        </div>
                                    </div>
                                </button>
                                <input
                                    className="border-2 rounded-2xl mr-2 p-2 w-[50%]"
                                    defaultValue="답변길이 커스텀 프롬프트"
                                    readOnly={true}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="mb-2 w-[600px]">
                        <label className="text-2xl">
                            글로벌 변수
                        </label>
                        <div className="mt-3 ml-3">
                            {globalVariables?.map((item) => {
                                const {key, description} = item;
                                return (
                                    <div key={key} className="mb-2 flex w-full">
                                        <button
                                            className="w-[50%] border-2 rounded-2xl mr-2 p-2 duration-200 hover:border-black truncate"
                                            onClick={() => handleCopyClipBoard(key)}
                                        >
                                            <div className="flex items-center justify-between">
                                                {key}
                                                <div>
                                                    {copiedKey !== key
                                                        ? <DocumentDuplicateIcon className="w-5 h-5 text-gray-400"/>
                                                        : <CheckIcon className="w-5 h-5 text-green-500"/>
                                                    }
                                                </div>
                                            </div>
                                        </button>
                                        <input
                                            className="border-2 rounded-2xl mr-2 p-2 w-[50%] truncate"
                                            defaultValue={description}
                                            readOnly={true}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </ThinScrollBarStyle>
            </ThinScrollBarStyle>
        </div>
    )
}

export default VariableGuide
