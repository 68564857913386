import React, {useMemo} from 'react';
import {IntlProvider} from "react-intl";
import {createBrowserRouter, Navigate, Outlet, RouteObject, useRoutes} from "react-router-dom";
import {ErrorBoundary} from "react-error-boundary";
import AuthRequiredErrorBoundary from "./component/AuthRequiredErrorBoundary";
import LoginView from "./view/auth/LoginView";
import ErrorFallback from "./view/ErrorFallbackView";
import {Toaster} from "react-hot-toast";
import Authenticator from "./component/Authenticator";
import LeftNavigatorProvider from "./component/LeftNavigatorProvider";
import {DictType} from "./model";
import ko from "./i18n/ko";
import ChannelDetailTab from "./view/channel/setting/ChannelDetailTab";
import LLMMain from "./view/llm/LLMMain";
import BaseDialog from "./hook/useDialog";
import SettingTab from "./view/setting/SettingTab";
import SearchSettingView from "./view/searchSpecification/SearchSettingView";
import PromptSettingView from "./view/prompt/PromptSettingView";
import ChannelTab from "./view/channel/ChannelTab";
import UserView from "./view/user/UserView";
import UserDetail from "./view/user/UserDetail";
import QueryLogDetail from "./component/queryLog/QueryLogDetail";
import PaymentsHistoryView from "./view/payments/PaymentsHistoryView";
import NewDashboardView from "./view/dashboard/NewDashboardView";
import ErrorBoundaryLayout from "./component/error/ErrorBoundaryLayout";

const AuthRequiredRoutes = () => {
    return useRoutes([
        {
            path: "/",
            element: <ErrorBoundaryLayout/>,
            children: [
                {
                    path: "",
                    element: <Authenticator/>,
                    children: [
                        {path: 'dashboard', element: <LeftNavigatorProvider element={<NewDashboardView/>}/>},
                        {
                            path: "channels",
                            children: [
                                {
                                    index: true,
                                    element: <LeftNavigatorProvider element={<ChannelTab/>}/>
                                },
                                {
                                    path: ":channelId",
                                    element: <LeftNavigatorProvider element={<ChannelDetailTab/>}/>
                                },
                                {
                                    path: ":channelId/query-logs/:queryLogId",
                                    element: <LeftNavigatorProvider element={<QueryLogDetail/>}/>
                                }
                            ]
                        },
                        {
                            path: "payments",
                            element: <LeftNavigatorProvider element={<PaymentsHistoryView/>}/>
                        },
                        {
                            path: "user",
                            children: [
                                {
                                    index: true,
                                    element: <LeftNavigatorProvider element={<UserView/>}/>
                                },
                                {
                                    path: ":userId",
                                    element: <LeftNavigatorProvider element={<UserDetail/>}/>
                                }
                            ]
                        },
                        {
                            path: "llms",
                            element: <LeftNavigatorProvider element={<LLMMain/>}/>,
                        },
                        {
                            path: "prompt",
                            element: <LeftNavigatorProvider element={<PromptSettingView/>}/>,
                        },
                        {
                            path: "search",
                            element: <LeftNavigatorProvider element={<SearchSettingView/>}/>,
                        },
                        {
                            path: "setting",
                            element: <LeftNavigatorProvider element={<SettingTab/>}/>,
                        },
                        // {
                        //     path: "DocSearching",
                        //     element: <DashboardMain/>,
                        // },
                        // {
                        //     path: "DocIndexing",
                        //     element: <DashboardMain/>,
                        // },
                    ],
                    errorElement: <AuthRequiredErrorBoundary/>
                }
            ]
        },
    ])
}

export const App = () => {
    const messages: DictType<Record<string, string>> = useMemo(() => {
        return {"ko": ko};
    }, []);

    return (
        <IntlProvider locale={'ko'} messages={messages['ko']}>
            <div className='w-full h-full font-sans'>
                <Toaster position="top-center"
                         containerStyle={{top: "15px"}}
                         toastOptions={{
                             style: {
                                 maxWidth: "400px"
                             }
                         }}
                />
                <BaseDialog/>
                <Outlet/>
            </div>
        </IntlProvider>
    );
}

const AppRouterInfo: RouteObject[] = [
    {
        path: "/",
        element: <App/>,
        children: [
            {
                index: true,
                element: <Navigate replace to="dashboard"/>
            },
            {
                path: "/login",
                element: <LoginView/>,
            },
            {
                path: "/*",
                element: <AuthRequiredRoutes/>
            }
        ],
        errorElement: <ErrorBoundary FallbackComponent={ErrorFallback}/>
    },
];

export const appRouterObject = createBrowserRouter(AppRouterInfo);
