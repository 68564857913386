import React from "react";
import PassageSearchSection from "./PassageSearchSection";
import LLMResponseSection from "./LLMResponseSection";
import AnalyzedQuerySection from "./AnalyzedQuerySection";
import ContextBuildSection from "./ContextBuildSection";
import LLMErrorLog from "./LLMErrorLog";
import {QueryLog} from "../../../model/QueryLog";

const ProcessResultSection: React.FC<{queryLog: QueryLog}> = ({queryLog}) => {
    return (
        <>
            {queryLog.analyzedQuery?.result && (
                <AnalyzedQuerySection latency={queryLog.analyzedQuery.latency!}
                                      result={queryLog.analyzedQuery.result} />
            )}
            {queryLog.analyzedQuery?.error && (
                <LLMErrorLog error={queryLog.analyzedQuery.error} />
            )}

            {queryLog.passageSearch?.result && (
                <PassageSearchSection type={queryLog.passageSearch.type}
                                      latency={queryLog.passageSearch.latency!}
                                      result={queryLog.passageSearch.result} />
            )}
            {queryLog.passageSearch?.error && (
                <LLMErrorLog error={queryLog.passageSearch.error} />
            )}


            {queryLog.promptBuild?.result && (
                <ContextBuildSection latency={queryLog.promptBuild.latency!}
                                     result={queryLog.promptBuild.result.slice(-2)} />
            )}
            {queryLog.promptBuild?.error && (
                <LLMErrorLog error={queryLog.promptBuild.error} />
            )}


            {queryLog.llmGenerated?.result && (
                <LLMResponseSection latency={queryLog.llmGenerated.latency!}
                                    result={queryLog.llmGenerated.result} />
            )}
            {queryLog.llmGenerated?.error && (
                <LLMErrorLog error={queryLog.llmGenerated.error} />
            )}

            {queryLog.etcError && (
                <LLMErrorLog error={queryLog.etcError.error} />
            )}
        </>
    );
}

export default ProcessResultSection;
