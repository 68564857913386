import userRepository from "../../respsitory/UserRepository";
import {useQuery} from "@tanstack/react-query";
import {User} from "../../model/User";
import {AxiosError} from "axios";

const getData = async (userId: string) => {
    try {
        const {data} = await userRepository.read(userId)
        return data.result
    } catch (e) {
        throw e;
    }
}

const useUser = (userId: string) => {
    return useQuery<User, AxiosError>({
        queryKey: ["user", userId],
        queryFn: async () => getData(userId),
        retry: false,
        suspense: false
    })
}

export default useUser;
