import {useMutation, useQueryClient} from "@tanstack/react-query";
import {SiteBunnyAdminError, toSiteBunnyAdminError} from "../../error";
import {GlobalVariables} from "../../model/GlobalVariables";
import globalVariablesRepository from "../../respsitory/GlobalVariablesRepository";


export const useDeleteGlobalVariables = () => {
    const queryClient = useQueryClient();

    return useMutation<GlobalVariables, SiteBunnyAdminError, string>({
        mutationKey: ['globalVariable', 'delete'],
        mutationFn: async (id: string) => {
            try {
                const {data} = await globalVariablesRepository.delete(id);
                return data.result;
            } catch (e) {
                throw toSiteBunnyAdminError(e);
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(['globalVariable'])
        }
    })
};
