import React, {useCallback, useMemo, useState} from "react";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import _ from "lodash";
import classNames from "classnames";
import {SearchSpecification, SearchSpecificationBase} from "../../model/searchSpecification";
import SearchEngineSpecificationSettingForm from "../../component/searchSpecification/SearchSpecificationSettingForm";
// import SearchEngineSpecificationGuide from "../../component/searchSpecification/SearchSpecificationGuide";
import {useIntl} from "react-intl";
import useSearchSpecification from "../../query/searchSpecification/useSearchSpecification";


const SearchSettingView: React.FC = () => {
    const intl = useIntl();
    const [searchKey, setSearchKey] = useState<string>();

    const {data: specifications} = useSearchSpecification(searchKey);

    const [showForm, setShowForm] = useState(false);
    const [selectedSpecificationId, setSelectedSpecificationId] = useState<string>();
    const [copySpecification, setCopySpecification] = useState<SearchSpecificationBase>();

    const selectedSpecification = useMemo(() => {
        return specifications?.find(specification => specification._id === selectedSpecificationId);
    }, [specifications, selectedSpecificationId]);

    const hideForm = useCallback(() => {
        setSelectedSpecificationId(undefined);
        setShowForm(false);
    }, [setSelectedSpecificationId, setShowForm]);

    const onChangeSearch = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchKey(e.target.value);
        hideForm();
    }, 300);

    const onClickSpecification = (specification: SearchSpecification) => {
        setSelectedSpecificationId(specification._id)
        setShowForm(true);
    }

    const onClickAddSpecification = () => {
        setCopySpecification(undefined);
        setSelectedSpecificationId(undefined);
        setShowForm(true);
    }

    const onClickCopySpecification = (specification: SearchSpecificationBase) => {
        setCopySpecification(specification);
        setSelectedSpecificationId(undefined);
        setShowForm(true);
    }


    const onClickCancelSpecification = () => {
        hideForm();
    }

    const onClickDeleteSpecification = () => {
        hideForm();
    }

    const onClickSubmitSpecification = (specificationId: string) => {
        setSelectedSpecificationId(specificationId);
    }

    return (
        <div className="flex flex-col h-full">
            {/* Title */}
            <div className="m-5 text-2xl px-2 pb-5 border-b-2 font-semibold">
                검색 관리
            </div>
            <div className="m-5 flex flex-col h-full">
                {/* Content */}
                <div className="flex space-x-5">
                    <div className="w-[30%] flex flex-col space-y-2">
                        <div className="flex items-center justify-between">
                            <p className="text-xl pl-2">{intl.formatMessage({id: "i0203"})}</p>

                            <button className="btn btn-info btn-sm" onClick={onClickAddSpecification}>
                                {intl.formatMessage({id: "i0204"})}
                            </button>
                        </div>

                        {/* Search bar */}
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <MagnifyingGlassIcon className="w-5 h-5"/>
                            </div>
                            <input type="search"
                                   id="search"
                                   className="form-input pl-10"
                                   placeholder="Search by key"
                                   onChange={onChangeSearch}
                            />
                        </div>

                        {/* List */}
                        <div className="flex-grow space-y-2 overflow-y-auto max-h-[1600px]">
                            {specifications?.map((specification) => {
                                const description = specification.description === "" ? "설명 없음" : (specification?.description ?? "설명 없음")
                                return (
                                    <div key={specification.key}
                                         className={classNames(
                                             "border border-gray-600 rounded-lg px-5 py-3 cursor-pointer transition-colors duration-200 hover:bg-gray-200",
                                             selectedSpecificationId === specification._id && "bg-gray-300"
                                         )}
                                         onClick={() => onClickSpecification(specification)}>
                                        <div className="flex items-center justify-between space-x-2">
                                            <p className="text-lg truncate">{specification.key}</p>
                                            {specification.dependency &&
                                                <div
                                                    className="px-3 py-1 rounded-md bg-blue-400 text-white text-sm whitespace-nowrap">
                                                    사용중
                                                </div>
                                            }
                                        </div>
                                        <p className="text-sm">{description}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="flex-grow space-y-2">
                        {showForm &&
                            <SearchEngineSpecificationSettingForm
                                key={`specification-setting-form-${selectedSpecification?.key}`}
                                specification={selectedSpecification}
                                onCancel={onClickCancelSpecification}
                                onDelete={onClickDeleteSpecification}
                                onSubmit={onClickSubmitSpecification}
                                onClickCopySpecification={onClickCopySpecification}
                                copySpecification={copySpecification}
                            />
                        }
                        {/*<SearchEngineSpecificationGuide/>*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchSettingView;
