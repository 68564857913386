import PromptInputForm from "../PromptInputForm";
import React, {Dispatch, useMemo} from "react";
import {PromptContentItem} from "../../../model/prompt/prompt";
import GuideMainView from "../guide/GuideMainView";

type GPTFormProps = {
    invalid: {key: boolean, llmType: boolean, promptCheck: boolean},
    content: PromptContentItem[],
    setContent: Dispatch<React.SetStateAction<PromptContentItem[]>>,
    selectedPromptType: string,
}

const GPTForm: React.FC<GPTFormProps> = (props) => {
    const {invalid, content, setContent, selectedPromptType} = props;

    const gptPrompt = useMemo(() => {
        if (content.length > 0) {
            if (content.every(item => item.role !== "system")) {
                setContent([]);
                return [{role: "system", content: ""}, {role: "user", content: ""}]
            } else {
                return content
            }
        } else {
            return [{role: "system", content: ""}, {role: "user", content: ""}]
        }
    }, [content, setContent])
    const systemPrompt = useMemo(() => {
        return gptPrompt.filter(item => item.role === "system")
    }, [gptPrompt])
    const userPrompt = useMemo(() => {
        return gptPrompt.filter(item => item.role === "user")
    }, [gptPrompt])

    const onChangeSystemPrompt = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        const systemContent: PromptContentItem = {
            role: name,
            content: value
        }
        updateContent(systemContent, "system")
    }

    const onChangeUserPrompt = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        const userContent: PromptContentItem = {
            role: name,
            content: value
        }
        updateContent(userContent, "user")
    }

    const updateContent = (newContent: PromptContentItem, contentType: string) => {
        const contentIndex = gptPrompt.findIndex(item => item.role === contentType);
        if (contentIndex !== -1) {
            gptPrompt[contentIndex] = newContent;
        }
        setContent(gptPrompt);
    }

    return (
        <div className="w-full flex-grow">
            {systemPrompt.map((item) => {
                const {role, content} = item;
                return (
                    <PromptInputForm key={item.role} value="System" name={role} prompt={content}
                                     onChange={onChangeSystemPrompt}
                                     invalid={invalid}/>
                )
            })}
            {userPrompt.map((item) => {
                const {role, content} = item;
                return (
                    <PromptInputForm key={item.role} value="User" name={role} prompt={content}
                                     onChange={onChangeUserPrompt}
                                     invalid={invalid}/>
                )
            })}
            <GuideMainView selectedPromptType={selectedPromptType}/>
        </div>
    )
}
export default GPTForm;
