import {TrashIcon} from "@heroicons/react/24/outline";
import {useList} from "@react-hookz/web";
import {useDialog} from "../../../hook/useDialog";
import {useIntl} from "react-intl";
import * as uuid from "uuid";
import {ChannelResponse, ChannelSettingUpdate} from "../../../model/channel/channel";
import React, {useCallback, useEffect, useState} from "react";

type ChannelSettingVariableViewProps = {
    channel: ChannelResponse
    settingRef: React.MutableRefObject<ChannelSettingUpdate>
    channelParamRefs: React.MutableRefObject<{key: HTMLInputElement | null, value: HTMLInputElement | null}[]>
}
const REQUIRED_MODEL_PARAMETER_KEYS = [
    "companyName"
]


const ChannelSettingVariableView: React.FC<ChannelSettingVariableViewProps> = (props) => {
    const {channel, settingRef, channelParamRefs} = props;
    const intl = useIntl();
    const dialog = useDialog();

    const [companyName, setCompanyName] = useState<string>(channel.setting.companyName);
    const [modelParams, {
        set: setModelParams,
        push: pushModelParams,
        removeAt: removeAtModelParams,
    }] = useList<{ id: string, key?: string, value?: any }>([]);
    const deleteParamByIndex = useCallback((index: number) => {
        channelParamRefs.current = [];
        removeAtModelParams(index);
    }, [channelParamRefs, removeAtModelParams]);
    const onClickDeleteParam = (index: number) => {
        const key = modelParams?.[index]?.key;
        if (key && REQUIRED_MODEL_PARAMETER_KEYS.includes(key)) {
            dialog.open({
                title: intl.formatMessage({id: "i0168"}),
                content: `${key} ${intl.formatMessage({id: "i0215"})}`,
                variant: "danger",
                onConfirm: () => {
                    deleteParamByIndex(index);
                },
                confirmText: intl.formatMessage({id: "i0170"}),
            })
        } else {
            deleteParamByIndex(index);
        }
    }
    const onClickAddParam = () => {
        pushModelParams({id: uuid.v4()})
    }

    useEffect(() => {
        if (channel.setting.variables) {
            setModelParams(Object.entries(channel.setting.variables)
                .map(([key, value]) => ({id: uuid.v4(), key, value})))
        }
    }, [channel, setModelParams, pushModelParams])

    const onChangeCompanyName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyName(e.target.value);
        settingRef.current = ({
            ...settingRef.current,
            companyName : e.target.value
        })
    }

    return (
        <div className="w-full">
            <div className="flex items-center space-x-2">
                <input className="form-input" defaultValue={"company_name"} readOnly={true}/>
                <input className="form-input" value={companyName} onChange={onChangeCompanyName}/>
                <div className="w-12 h-12"></div>
            </div>
            {modelParams.map((modelParam, index) => {
                return (
                    <div key={modelParam.id}
                         className="flex items-center space-x-2">
                        <input className="form-input"
                               defaultValue={modelParam.key}
                               placeholder="채널 변수 키"
                               ref={(el: HTMLInputElement) => {
                                   if (el) {
                                       if (!channelParamRefs.current[index]) {
                                           channelParamRefs.current[index] = {key: null, value: null};
                                       }
                                       channelParamRefs.current[index].key = el;
                                   }
                               }}/>
                        <input className="form-input"
                               defaultValue={modelParam.value}
                               placeholder="채널 변수 값"
                               ref={(el: HTMLInputElement) => {
                                   if (el) {
                                       if (!channelParamRefs.current[index]) {
                                           channelParamRefs.current[index] = {key: null, value: null};
                                       }
                                       channelParamRefs.current[index].value = el;
                                   }
                               }}/>
                        <TrashIcon className="w-12 h-12 cursor-pointer"
                                   onClick={() => onClickDeleteParam(index)}/>
                    </div>
                )
            })}
            <button className="mt-2 btn btn-secondary-outline transition-colors duration-200 btn-sm w-full"
                    onClick={onClickAddParam}>
                {intl.formatMessage({id: "i0195"})}
            </button>
        </div>
    )
}

export default ChannelSettingVariableView;
