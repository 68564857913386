import React, { useEffect, useImperativeHandle, useRef } from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";

export interface ObjectKeySelectRef {
    get: () => string | undefined
    set: (newValue: string) => void
}

interface ObjectKeySelectProps {
    label: string
    items: {[key: string]: any}[]
    selectProp?: string
    showAsTextProp?: string
    onChange?: (newValue: string) => void
    defaultValue?: string
    defaultKey?: string
    defaultKeyName?: string
}

const ObjectKeySelect = React.forwardRef<ObjectKeySelectRef, ObjectKeySelectProps>((props, ref) => {
    const {label, items, selectProp="_id", showAsTextProp="key", onChange, defaultValue = "", defaultKey, defaultKeyName} = props;
    const intl = useIntl();

    const selectRef = useRef<HTMLSelectElement>(null);

    useImperativeHandle(ref, () => {
        return {
            get: () => selectRef.current?.value,
            set: (newValue: string) => {
                if (selectRef.current) selectRef.current.value = newValue;
            }
        }
    }, [])

    useEffect(() => {
        if (selectRef.current) selectRef.current.value = defaultValue;
    }, [defaultValue, items])

    const onChangeSelect = (e: any) => {
        onChange && onChange(e.target.value);
        if (selectRef.current) selectRef.current.value = e.target.value;
    }

    return (
        <div className={"flex space-x-3 items-center"}>
            <div>{`${label}: `}</div>
            <select ref={selectRef}
                    className="bg-gray-50 form-select w-full px-2 focus:outline-none disabled:cursor-default disabled:opacity-80"
                    defaultValue={defaultValue}
                    onChange={(e) => onChangeSelect(e)}>
                <option disabled hidden key={"none"} value={""}>{intl.formatMessage({id: "i11035"})}</option>
                {defaultKey && <option key={"default"} value={"default"}>{defaultKeyName ?? defaultKey}</option>}
                {items.map((item) => (
                    <option key={item[selectProp]} value={item[selectProp]}
                            className={classNames({"bg-yellow-100": item[selectProp] === defaultValue})}>{item[showAsTextProp]}</option>
                ))}
            </select>
        </div>
    )
});

export default ObjectKeySelect;
