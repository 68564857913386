import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {LLMSpecification} from "../../model/llmSpecification";
import llmSpecificationRepository from "../../respsitory/LLMSpecificationRepository";

const useDeleteLLMSpecification = () => {
    const queryClient = useQueryClient();

    return useMutation<LLMSpecification, AxiosError, string>({
        mutationKey: ["llm-specifications", "delete"],
        mutationFn: async (llmSpecificationId: string) => {
            try {
                const { data } = await llmSpecificationRepository.delete(llmSpecificationId);
                return data.result;
            } catch (e) {
                throw e;
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["llm-specifications"],
                exact: false,
            });
        }
    })
};

export default useDeleteLLMSpecification;
