import React, {useRef} from 'react';
import * as authRepository from "../../respsitory/AuthRepository"
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";

const LoginView = () => {
    const navigate = useNavigate()
    const usernameRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!usernameRef.current || !passwordRef.current) return

        const username = usernameRef.current.value;
        const password = passwordRef.current.value;
        try {
            await authRepository.login(username, password);
            navigate("/channels", {replace: true});
        } catch (e) {
            console.log(e)
            toast.error("error");
        }
    }
    return (
        <div className="w-full h-full flex flex-col justify-center items-center space-y-2">
            <div className="text-xl">SiteBunny Admin</div>

            <form className="w-96 space-y-6 p-5 border border-gray-300 rounded-lg shadow-lg" onSubmit={onSubmit}>

                <div className="form-input-group">
                    <label htmlFor="username"
                           className="form-label">
                        이메일 주소
                    </label>
                    <input name="username"
                           id="username"
                           ref={usernameRef}
                           className="form-input"
                           placeholder="name@company.com"/>
                </div>

                <div className="space-y-2">
                    <label htmlFor="password"
                           className="form-label">
                        비밀번호
                    </label>
                    <input type="password" name="password" id="password" placeholder="••••••••"
                           ref={passwordRef}
                           className="form-input"/>

                </div>
                <button type="submit"
                        className="btn btn-primary"
                >
                    로그인
                </button>
            </form>
        </div>
    )
}

export default LoginView