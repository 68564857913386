import React, {useMemo} from "react";
import {AggregationAgent} from "../../../model/ChannelCommonSettingHistory";

type AggregationAgentContentProps = {
    title: string
    agent?: AggregationAgent
}

const AggregationAgentContent: React.FC<AggregationAgentContentProps> = (props) => {
    const {title, agent} = props;
    const content = useMemo(() => {
        return (
            <>
                {agent?.prompt.content.map((item) => {
                    const {role, content} = item;
                    return (
                        <div key={role}
                             className="px-10">
                            <p className="text-lg my-2">{role}</p>
                            <textarea className="form-input h-[150px] text-gray-500 text-lg"
                                      value={content}
                                      readOnly={true}
                            />
                        </div>
                    )
                })}
            </>
        )
    }, [agent?.prompt.content])

    if (!agent) return null;
    return (
        <div>
            <div className="px-2 pt-7 text-lg font-semibold">
                {title}
            </div>
            <div className="form-input-group flex px-7 pt-7">
                <label className="form-label min-w-[150px] text-lg">
                    LLM 키
                </label>
                <div className="form-select w-full px-2 focus:outline-none bg-white">
                    {agent.llm.key}
                </div>
            </div>
            <div className="form-input-group flex px-7 pt-7">
                <label className="form-label min-w-[150px] text-lg">
                    프롬프트 키
                </label>
                <div className="form-select w-full px-2 focus:outline-none bg-white">
                    {agent.prompt.key}
                </div>
            </div>
            <div>
                {content}
            </div>
        </div>
    )
}

export default AggregationAgentContent;
