import React, {Fragment, useCallback, useMemo} from "react";
import {atom, useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {Dialog, Transition} from "@headlessui/react";
import classNames from "classnames";

export const modalStatusState = atom<{[id: string]: boolean}>({
    key: 'modalStatus',
    default: {}
})

const modalDataState = atom<{[id: string]: any}>({
    key: 'modalData',
    default: {}
})

const useModal = () => {
    const [modalStatus, setModalStatus] = useRecoilState(modalStatusState)
    const [modalData, setModalData] = useRecoilState(modalDataState)

    const resetModalStatus = useResetRecoilState(modalStatusState)
    const resetModalData = useResetRecoilState(modalDataState)

    const register = useCallback((id: string, status?: boolean) => {
        setModalStatus(prev => ({...prev, [id]: !!status}))
    }, [setModalStatus])

    const open = useCallback((id: string, data?: any) => {
        if (modalStatus[id] === undefined) {
            register(id)
        }

        setModalStatus(prev => ({
            ...prev,
            [id]: true
        }))

        setModalData(prev => ({
            ...prev,
            [id]: data
        }))
    }, [modalStatus, setModalStatus, setModalData, register])

    const close = useCallback((id: string) => {
        setModalStatus(prev => ({
            ...prev,
            [id]: false
        }))

        setModalData(prev => ({
            ...prev,
            [id]: null
        }))
    }, [setModalStatus, setModalData])

    const clear = useCallback(() => {
        resetModalStatus()
        resetModalData()
    }, [resetModalStatus, resetModalData])

    return useMemo(() => {
        return {
            register,
            open,
            close,
            clear,
            data: modalData,
            status: modalStatus
        }
    }, [register, open, close, clear, modalData, modalStatus])
}

export const Modal: React.FC<ModalProps> = ((props) => {
    const {children, id, title, onClose, className, size = "lg" } = props

    const modal = useModal();
    const modalStatus = useRecoilValue(modalStatusState)

    const handleClose = () => {
        onClose?.();
        modal.close(id);
    };

    const panelClassName = useMemo(() => {
        const commonClassName = "transform overflow-hidden rounded-xl bg-white p-6 shadow-xl transition-all";
        let width = "";
        switch (size) {
            case "sm":
                width = "w-[400px]"
                break
            case "lg":
                width = "w-[700px]"
                break
            case "xl":
                width = "w-[1000px]"
                break
            case "2xl":
                width = "w-[1400px]"
                break
        }
        return classNames(commonClassName, className, width);
    }, [className, size]);

    return (
        <Transition appear show={modalStatus[id] ?? false} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={handleClose}>
                <Transition.Child as={Fragment}
                                  enter="ease-out duration-300"
                                  enterFrom="opacity-0"
                                  enterTo="opacity-100"
                                  leave="ease-in duration-200"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <Transition.Child as={Fragment}
                                          enter="ease-out duration-300"
                                          enterFrom="opacity-0 scale-95"
                                          enterTo="opacity-100 scale-100"
                                          leave="ease-in duration-200"
                                          leaveFrom="opacity-100 scale-100"
                                          leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className={panelClassName}>
                                <Dialog.Title as="h3" className="text-lg font-bold leading-6 text-gray-900 relative">
                                    {title}
                                    <i className="absolute fa-solid fa-close right-0 cursor-pointer" onClick={handleClose}/>
                                </Dialog.Title>
                                {children}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
});

interface ModalProps {
    id: string;
    title?: string;
    onClose?: () => void;
    children?: React.ReactNode;
    className?: string;
    size?: "sm" | "lg" | "xl" | "2xl";
}

export default useModal;
