import axios from "axios";
import {API_HOST} from "../core/variables";
import {ChannelGroupCreateRequest, ChannelGroupUpdateRequest} from "../model/channelGroup/channelGroup";

class ChannelGroupRepository {
    async find(key?: string) {
        return axios.get(`${API_HOST}/v1/channel-group`, {
            params: {key}
        })
    };

    async read(id: string) {
        return axios.get(`${API_HOST}/v1/channel-group/${id}`)
    };

    async create(body: ChannelGroupCreateRequest) {
        return axios.post(`${API_HOST}/v1/channel-group`, body)
    };

    async update(id: string, body: ChannelGroupUpdateRequest) {
        return axios.put(`${API_HOST}/v1/channel-group/${id}`, body)
    };

    async delete(id: string) {
        return axios.delete(`${API_HOST}/v1/channel-group/${id}`)
    };

}
const channelGroupRepository = new ChannelGroupRepository();
export default channelGroupRepository;
