import React from "react";
import {useIntl} from "react-intl";
import useRouteHandler from "../../hook/useRouteHandler";

type View40XProps = {
    code: string;
    title: string;
    description: string;
    customReset?: () => void;
    resetErrorBoundary?: () => void;
}

const ErrorView: React.FC<View40XProps> = (props) => {
    const { code, title, description, customReset, resetErrorBoundary } = props;
    const intl = useIntl();

    const { resetRoute } = useRouteHandler();

    const onClickReturnHome = () => {
        if (customReset) {
            customReset();
        } else {
            resetRoute();
            resetErrorBoundary?.();
        }
    }

    return (
        <div className="bg-error-page bg-cover bg-center">
            <div className="flex flex-col items-center justify-center mx-auto h-screen jus">
                <div className="w-full h-[400px] flex flex-col items-center justify-between bg-white rounded-xl shadow-xl max-w-md p-4">
                    <div>
                        <div className="text-center text-2xl font-bold text-gray-900 my-5">
                            {intl.formatMessage({id: "i0001"})}
                        </div>
                        <h1 className="text-center font-semibold text-xl mb-3">
                            {title} ({code})
                        </h1>
                        <div className="text-center text-lg whitespace-pre-wrap">
                            {description}
                        </div>
                    </div>

                    <button className="mt-5 transition-colors duration-200 text-white bg-blue-300 hover:bg-blue-400 rounded-lg px-5 py-2"
                            onClick={onClickReturnHome}>
                        Return Home
                    </button>
                </div>
            </div>
        </div>
    )
};

type Error404ViewProps = {
    reset?: () => void;
}

export const Error404View: React.FC<Error404ViewProps> = (props) => {
    const intl = useIntl();

    return <ErrorView code="404"
                      title={intl.formatMessage({id: "e0003"})}
                      description={intl.formatMessage({id: "e0004"})}
                      customReset={props.reset}
    />;
};

export default ErrorView;
