import globalVariableGroupRepository from "../../respsitory/GlobalVariableGroupRepository";
import {useQuery} from "@tanstack/react-query";
import {GlobalVariableGroup} from "../../model/globalVariable/GlobalVariableGroup";
import {AxiosError} from "axios";

const getDate = async (searchKey?: string, searchType?: string) => {
    try {
        const {data} = await globalVariableGroupRepository.find(searchKey, searchType)
        return data.result
    } catch (e) {
        throw e;
    }
}

const useGlobalVariableGroup = (searchKey?: string, searchType?: string) => {
    return useQuery<GlobalVariableGroup[], AxiosError>({
        queryKey: ["globalVariableGroup", searchKey, searchType],
        queryFn: async () => getDate(searchKey, searchType),
        retry: false,
        suspense: false
    })
}

export default useGlobalVariableGroup;