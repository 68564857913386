import searchSpecificationRepository from "../../respsitory/SearchSpecificationRepository";
import {useQuery} from "@tanstack/react-query";
import {SearchEngineType, SearchSpecification} from "../../model/searchSpecification";
import {AxiosError} from "axios";


const getData = async (key?: string, SearchEngineType?: SearchEngineType) => {
    try {
        const {data} = await searchSpecificationRepository.find(key, SearchEngineType);
        return data.result
    } catch (e) {
        throw e;
    }
}

const useSearchSpecification = (key?: string, SearchEngineType?: SearchEngineType) => {
    return useQuery<SearchSpecification[], AxiosError>({
        queryKey: ["searchSpecification-specification", key, SearchEngineType],
        queryFn: async () => getData(key, SearchEngineType),
        retry: false,
        suspense: false,
    })
}

export default useSearchSpecification;
