import {AnswerResponse, ErrorLog, ReplyQuestionResponse} from "./llmSearchTestPanel/QueryAnswers";
import AnalyzedQueryResult from "./llmSearchTestPanel/extra/AnalyzedQueryResult";
import PassageSearchResult from "./llmSearchTestPanel/extra/PassageSearchResult";
import {GPTPrompt} from "./llmSpecification";
import LLMResponseResult from "./llmSearchTestPanel/extra/LLMResponseResult";

export enum QueryExecutionSourceType {
    ADMIN = "admin",
    CHAT42 = "chat42",
    OPENAPI = "openapi",
    SITEBUNNY = "sitebunny"
}

export enum IncomingRequestEventType {
    QUERY = "query",
    REPLY_QUESTION = "reply_question",
    WELCOME = "welcome",
    CURATING = "curating",
    WELCOME_QUESTION = "welcome_question"
}

// todo channel common setting snapshot 인터페이스 만들기
export interface QueryLogBase {
    channelId: string;
    channelCommonSettingHistoryId: string;
    source: QueryExecutionSourceType;
    type: IncomingRequestEventType;
    answer: string;
    payload: string;
    analyzedQuery?: {
        latency?: number
        result?: AnalyzedQueryResult
        error?: ErrorLog
    };
    passageSearch?: {
        type: "perfect-answer" | "unstable-answers" | "passages"
        latency?: number
        result?: PassageSearchResult
        error?: ErrorLog
    };
    promptBuild?: {
        latency?: number
        result?: GPTPrompt[]
        error?: ErrorLog
    };
    llmGenerated?: {
        latency?: number
        result?: LLMResponseResult
        error?: ErrorLog
    };
    etcError?: {
        error: ErrorLog
    };
    tokenUsage: {
        embedding: number;
        completion: number;
    };
    response: AnswerResponse | ReplyQuestionResponse | undefined;
}

export interface QueryLog extends QueryLogBase {
    _id: string;
    createdAt: string;
    updatedAt: string;
}

export interface QueryLogResponsePage {
    queryLogs: QueryLog[];
    count: number;
}


export type QueryLogCreateRequest = QueryLogBase
