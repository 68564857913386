import React, {ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import useModal, {Modal} from "./useModal";
import classNames from "classnames";

const GLOBAL_DIALOG_ID = "global-dialog-id";

type dialogData = {
    title?: string;
    content: string | ReactNode;
    onConfirm: VoidFunction;
    confirmText?: string;
    onCancel?: VoidFunction;
    cancelText?: string;
    variant?: "primary" | "danger";
}

export const useDialog = () => {
    const modal = useModal();

    const open = useCallback((params: dialogData) => {
        modal.open(GLOBAL_DIALOG_ID, params);
    }, [modal]);

    return useMemo(() => {
        return {
            open
        }
    }, [open])
};

const BaseDialog: React.FC = () => {
    const modal = useModal();

    const [dialogData, setDialogData] = useState<dialogData>();

    const confirmBtnClassName = useMemo(() => {
        const commonClassName = "transition-colors duration-200 rounded-md px-4 py-2 text-sm font-medium focus:outline-none";

        switch (dialogData?.variant) {
            case "primary":
                return classNames(commonClassName, "text-gray-700 bg-blue-100 hover:bg-blue-200");
            case "danger":
                return classNames(commonClassName, "text-white bg-red-400 hover:bg-red-500");
            default:
                return classNames(commonClassName, "bg-blue-100 hover:bg-blue-200");

        }
    }, [dialogData])

    const onClickCancel = () => {
        if (!dialogData) {
            return;
        }

        dialogData.onCancel?.();
        modal.close(GLOBAL_DIALOG_ID);
    };

    const onClickConfirm = () => {
        if (!dialogData) {
            return;
        }

        dialogData.onConfirm?.();
        modal.close(GLOBAL_DIALOG_ID);
    };

    useEffect(() => {
        const params = modal.data[GLOBAL_DIALOG_ID] as dialogData;
        if (params) {
            setDialogData(params);
        }
    }, [modal])

    return (
        <Modal id={GLOBAL_DIALOG_ID} title={dialogData?.title}>
            <div className="flex flex-col space-y-5">
                <div className="mt-5">
                    {dialogData?.content}
                </div>

                <div className="space-x-3 self-end">
                    <button className="transition-colors duration-200 rounded-md bg-gray-100 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none"
                            onClick={onClickCancel}>
                        {dialogData?.cancelText ?? "취소"}
                    </button>
                    <button className={confirmBtnClassName}
                            onClick={onClickConfirm}>
                        {dialogData?.confirmText ?? "확인"}
                    </button>
                </div>
            </div>
        </Modal>
    )
};

export default BaseDialog;
