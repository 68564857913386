import axios from "axios";
import {API_HOST} from "../core/variables";
import {LLMType, PromptCreateRequest, PromptType, PromptUpdateRequest} from "../model/prompt/prompt";

class PromptRepository {
    async findHistory(id: string) {
        return axios.get(`${API_HOST}/v1/prompt-history/${id}`)
    }

    async find(key?: string, promptType?: PromptType, llmType?: LLMType) {
        return axios.get(`${API_HOST}/v1/prompt`, {
            params: {
                key: key,
                prompt_type: promptType,
                llm_type: llmType
            }
        })
    }

    async readById(id?: string) {
        return axios.get(`${API_HOST}/v1/prompt/detail`, {
            params: {
                prompt_id: id
            }
        })
    }

    async existed(key: string) {
        return axios.get(`${API_HOST}/v1/prompt/existed`, {params: {key}})
    }

    async create(body: PromptCreateRequest) {
        return axios.post(`${API_HOST}/v1/prompt`, body)
    }

    async update(id: string, body: PromptUpdateRequest) {
        return axios.put(`${API_HOST}/v1/prompt/${id}`, body)
    }

    async delete(id: string) {
        return axios.delete(`${API_HOST}/v1/prompt/${id}`)
    }
}

const promptRepository = new PromptRepository();

export default promptRepository;