import React, {useCallback, useMemo, useState} from "react";
import DateRangePicker from "../../component/DateRangePicker";
import {DateValueType} from "react-tailwindcss-datepicker/dist/types";
import {subtractDays} from "../../util/dateUtils";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import classNames from "classnames";
import useBasicStatistics from "../../query/analytics/useBasicStatistics";
import moment, {Moment} from "moment";
import useUsageStatistics from "../../query/analytics/useUsageStatistics";
import analyticsRepository from "../../respsitory/AnalyticsRepository";
import {useQueryClient} from "@tanstack/react-query";
import {UsageStatistics} from "../../model/analytics/analytics";
import {useIntl} from "react-intl";

const NewDashboardView: React.FC = () => {
    const intl = useIntl();

    const today = useMemo(() => new Date(), []);

    const queryClient = useQueryClient();

    const [period, setPeriod] = useState<DateValueType>({
        startDate: subtractDays(new Date(today.setHours(0, 0, 0, 0)), 7).toISOString(),
        endDate: new Date(today.setHours(23, 59, 59, 999)).toISOString()
    });

    const convertedPeriod = useMemo<{startDate: Moment, endDate: Moment}>(() => {
        return {
            startDate: moment.utc(period?.startDate),
            endDate: moment.utc(period?.endDate).set({hours: 23, minute: 59, second: 59, milliseconds: 999})
        }
    }, [period])

    const [mutating, setMutating] = useState(false);

    const { data: basicAnalytics } = useBasicStatistics(
        convertedPeriod.startDate.toISOString(),
        convertedPeriod.endDate.toISOString()
    )

    const { data: usageStatistics } = useUsageStatistics();

    // const convertedFileSize = useMemo(() => {
    //     const size = usageStatistics?.totalDataSourceSize ?? 0;
    //     let i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    //     return Number(size / Math.pow(1024, i)).toFixed(2) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    // }, [usageStatistics])

    const convertUnit = useCallback((value: number) => {
        if (value < 10000) {
            return `${intl.formatNumber(value)}`;
        } else if (value < 10000000) {
            return `${intl.formatNumber(Math.floor(value / 1000))}K`
        } else {
            return `${intl.formatNumber(Math.floor(value / 1000000))}M`
        }
    }, [intl])

    const onClickRefresh = async () => {
        if (mutating) {
            return;
        }

        setMutating(true)
        try {
            const { data } = await analyticsRepository.refreshUsageStatistics();
            queryClient.setQueryData<UsageStatistics>(["usage-statistics"], () => {
                return data.result as UsageStatistics
            })
        } catch (e) {

        } finally {
            setMutating(false);
        }
    }

    const llmStatusSummary = useMemo(() => {
        if (!usageStatistics) {
            return undefined
        }

        const failedCount = usageStatistics.llmStatusCount.failed;
        const successCount = usageStatistics.llmStatusCount.success;

        const totalCount = failedCount + successCount;

        if (totalCount === 0) {
            return `데이터 없음`;
        }

        return (
            <div className="flex items-end font-medium space-x-[10px]">
                <p className="text-[20px]">
                    {(successCount / totalCount * 100).toFixed(0)}% / {(failedCount / totalCount * 100).toFixed(0)}%
                </p>
                <p className="text-[14px] pb-1">({intl.formatNumber(successCount)}/{intl.formatNumber(failedCount)})</p>
            </div>
        )
    }, [intl, usageStatistics])

    return (
        <div className="w-full h-full p-10 font-dm bg-[#F5F6FA] space-y-[20px] overflow-y-auto">

            <div className="flex items-center space-x-[5px]">
                <DateRangePicker period={period}
                                 setPeriod={setPeriod}
                                 className={"flex items-center h-[40px] w-[300px] bg-white rounded-[4px] border border-bunny-border-gray cursor-pointer"}
                                 containerClassName={"datepicker bg-transparent rounded-[4px] border-none text-[14px] cursor-default z-10"}
                                 inputClassName={"relative w-full px-[8px] focus:ring-0 focus:outline-none bg-transparent rounded-[4px] text-[14px] text-bunny-label-black font-medium border-none cursor-pointer caret-transparent"}
                />
            </div>

            {basicAnalytics &&
                <div className="space-y-[30px]">
                    <div className="space-y-[11px]">
                        <div className="ml-[4px] text-[20px] font-bold leading-[28px]">채널/고객 현황</div>
                        <div className="flex items-center space-x-[13px]">
                            <StatisticCard label={"총 채널 수 (운영중)"}
                                                   value={basicAnalytics.channel.totalCount}
                                                   inLabel={"생성"}
                                                   inValue={basicAnalytics.channel.newCount}
                                                   outLabel={"삭제"}
                                                   outValue={basicAnalytics.channel.bounceCount}
                            />
                            <StatisticCard label={"총 고객 수"}
                                                   value={basicAnalytics.user.totalCount}
                                                   inLabel={"가입"}
                                                   inValue={basicAnalytics.user.newCount}
                                                   outLabel={"탈퇴"}
                                                   outValue={basicAnalytics.user.bounceCount}
                            />

                        </div>
                    </div>
                    <div className="space-y-[11px]">
                        <div className="ml-[4px] text-[20px] font-bold leading-[28px]">요금제 현황</div>
                        <div className="flex items-center space-x-[13px]">
                            {basicAnalytics.subscriptions.map((subscriptionStatistic) => (
                                <StatisticCard key={`${subscriptionStatistic.planName}`}
                                               label={subscriptionStatistic.planName}
                                               value={subscriptionStatistic.totalCount}
                                               // inLabel={"신규"}
                                               // inValue={subscriptionStatistic.newCount}
                                               // outLabel={"취소"}
                                               // outValue={subscriptionStatistic.bounceCount}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            }

            <hr />

            {usageStatistics &&
                <div className="flex flex-col space-y-[30px]">
                    <div className="flex items-center space-x-[11px]">
                        <ArrowPathIcon className={classNames("cursor-pointer", mutating && "animate-spin")}
                                       height={16}
                                       width={16}
                                       onClick={onClickRefresh}
                        />
                        <p>최근 업데이트 일시: {moment.utc(usageStatistics.updatedAt).local().format("YYYY년 MM월 DD일 HH:mm:ss")}</p>
                        <p className="text-[12px] text-gray-600">(매 6시간마다 자동 업데이트됩니다.)</p>
                    </div>

                    <div className="space-y-[30px]">

                        <div className="flex space-x-[30px]">
                            <div className="space-y-[11px]">
                                <div className="ml-[4px] text-[20px] font-bold leading-[28px]">총 메시지/사용자 수</div>
                                <div className="flex items-center space-x-[13px]">
                                    <StatisticCard label={"총 메시지 수"}
                                                   value={intl.formatNumber(usageStatistics.totalMessages)}
                                    />
                                    <StatisticCard label={"총 사용자(대화방) 수"}
                                                   value={intl.formatNumber(usageStatistics.totalMessagingUsers)}
                                    />
                                    {/*<StatisticCard label={"업로드 데이터 (글자 수)"}*/}
                                    {/*               value={intl.formatNumber(usageStatistics.totalDataSourceSize)}*/}
                                    {/*/>*/}
                                    {/*<StatisticCard label={"총 계정 연결 수"}*/}
                                    {/*               value={usageStatistics.totalAccountIntegrations}*/}
                                    {/*/>*/}
                                </div>
                            </div>
                            <div className="space-y-[11px]">
                                <div className="ml-[4px] text-[20px] font-bold leading-[28px]">총 토큰 사용량</div>
                                <div className="flex items-center space-x-[13px]">
                                    {/*<StatisticCard label={"데이터 소스 업로드시 사용량"}*/}
                                    {/*               value={(*/}
                                    {/*                   <div className="flex items-end space-x-[5px]">*/}
                                    {/*                       <p className="text-[20px]">*/}
                                    {/*                           {intl.formatNumber(usageStatistics.llmTokenUsage.dataSourceEmbedding)}*/}
                                    {/*                       </p>*/}
                                    {/*                       <p className="text-[14px]">tokens</p>*/}
                                    {/*                   </div>*/}
                                    {/*               )}*/}
                                    {/*/>*/}
                                    {/*<StatisticCard label={"사용자 질의시 사용량"}*/}
                                    {/*               value={(*/}
                                    {/*                   <div className="flex items-end space-x-[5px]">*/}
                                    {/*                       <p className="text-[20px]">*/}
                                    {/*                           {intl.formatNumber(usageStatistics.llmTokenUsage.embedding)}*/}
                                    {/*                       </p>*/}
                                    {/*                       <p className="text-[14px]">tokens</p>*/}
                                    {/*                   </div>*/}
                                    {/*               )}*/}
                                    {/*/>*/}
                                    {/*<StatisticCard label={"사용자에게 답변시 사용량"}*/}
                                    {/*               value={(*/}
                                    {/*                   <div className="flex items-end space-x-[5px]">*/}
                                    {/*                       <p className="text-[20px]">*/}
                                    {/*                           {intl.formatNumber(usageStatistics.llmTokenUsage.completion)}*/}
                                    {/*                       </p>*/}
                                    {/*                       <p className="text-[14px]">tokens</p>*/}
                                    {/*                   </div>*/}
                                    {/*               )}*/}
                                    {/*/>*/}
                                    <StatisticCard label={"총 토큰 사용량"}
                                                   className="w-[500px]"
                                                   value={(
                                                       <div className="flex items-end space-x-[10px]">
                                                           <p className="text-[20px]">
                                                               {convertUnit(usageStatistics.llmTokenUsage.completion + usageStatistics.llmTokenUsage.embedding)}
                                                           </p>
                                                           <p className="text-[14px] pb-1">
                                                               ({convertUnit(usageStatistics.llmTokenUsage.completion)} Completions + {convertUnit(usageStatistics.llmTokenUsage.embedding)} Embeddings)
                                                           </p>
                                                       </div>
                                                   )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="space-y-[11px]">
                            <div className="ml-[4px] text-[20px] font-bold leading-[28px]">품질 지표</div>
                            <div className="flex items-center space-x-[13px]">
                                <StatisticCard label={"LLM 요청 성공/실패 비율"}
                                               value={llmStatusSummary}
                                />
                                <StatisticCard label={"User Ratings (Good/Bad)"}
                                               value={`${usageStatistics.userRatings.good} / ${usageStatistics.userRatings.bad}`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

type StatisticCardProps = {
    className?: string;
    label: string;
    value: React.ReactNode;
    inLabel?: string;
    outLabel?: string;
    inValue?: number;
    outValue?: number;
}

const StatisticCard: React.FC<StatisticCardProps> = (props) => {
    const { className, label, value, inLabel, outLabel, inValue, outValue } = props;
    return (
        <div className={classNames("w-[240px] h-[120px] p-[24px] rounded-[4px] border broder-[#EAEAEA] bg-white space-y-[13px]", className)}>
            <p className="text-[16px] font-medium leading-[28px]">{label}</p>
            <div className="flex items-center justify-between">
                <div className="text-[20px] font-medium">{value}</div>

                <div className="flex items-center space-x-[13px]">
                    {inLabel && <p className="text-[#00BD57] text-[14px] font-medium leading-[28px]">{inLabel}: {inValue}</p>}
                    {outLabel && <p className="text-[#FF0000] text-[14px] font-medium leading-[28px]">{outLabel}: {outValue}</p>}
                </div>
            </div>
        </div>
    )
}

export default NewDashboardView;
