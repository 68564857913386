import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useMemo, useRef, useState } from "react";
import classNames from "classnames";

interface CategorySearchBarProps {
    className?: string
    categories: {category: string, expression: string}[]
    defaultCategory?: string
    defaultKeyword?: string
    onSubmit: (category: string, keyword: string) =>  void
    placeholder?: string
}

const CategorySearchBar: React.FC<CategorySearchBarProps> = (props) => {
    const {className, categories, defaultCategory = categories[0].category, defaultKeyword = "", onSubmit, placeholder = ""} = props

    const [category, setCategory] = useState<string>(defaultCategory);

    const keywordRef = useRef<HTMLInputElement>(null);

    const _className = useMemo(() => {
        return classNames("flex", {[className ?? '']: className});
    }, [className]);

    const selectedExpression = useMemo(() => {
        const selectedCategory = categories.find((categoryItem) => categoryItem.category === category);

        if (!selectedCategory) return "";
        return selectedCategory.expression;
    }, [categories, category])

    const onClickCategory = (e: React.MouseEvent, selection: string, close: () => void) => {
        e.preventDefault();
        setCategory(selection);
        close();
    }

    const onClickSubmit = (e:React.MouseEvent) => {
        if (!keywordRef.current) return;

        e.preventDefault();
        onSubmit(category, keywordRef.current.value);
    }

    const onKeyUp = (e: React.KeyboardEvent) => {
        if (!keywordRef.current || e.key !== 'Enter') return

        onSubmit(category, keywordRef.current.value);
    }

    return (
        <div className={_className}>
            <Menu as={"div"} className={"relative w-fit"}>
                <Menu.Button className="flex whitespace-nowrap space-x-2 break-keep items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100">
                    <div>{selectedExpression}</div>
                    <ChevronDownIcon className={"w-4 f-4"} />
                </Menu.Button>
                <Transition as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className="absolute w-full z-50 py-2 text-sm font-medium text-gray-700 bg-white border-2 rounded-lg">
                        {categories.map((categoryItem) => (
                            <Menu.Item key={categoryItem.category}>
                                {({ active, close }) => (
                                    <div className={`cursor-default block px-4 py-2 ${active ? 'bg-blue-500 text-white' : 'text-gray-700'}`}
                                         onClick={(e) => onClickCategory(e, categoryItem.category, close)}
                                    >
                                        {categoryItem.expression}
                                    </div>
                                )}
                            </Menu.Item>
                        ))}
                    </Menu.Items>
                </Transition>
            </Menu>

            <div className="relative h-full w-full">
                <input type="search" ref={keywordRef} onKeyUp={onKeyUp}
                       className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border-l-gray-50 border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                       defaultValue={defaultKeyword}
                       placeholder={placeholder} />
                <button className="absolute top-0 right-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
                        onClick={onClickSubmit}>
                    <MagnifyingGlassIcon className={"w-5 h-5"} />
                    <span className="sr-only">Search</span>
                </button>
            </div>
        </div>
    )
};

export default CategorySearchBar;
