export enum SearchEngineType {
    ADA = "Ada",
    QA42 = "QA42",
}

export interface ADASetting {
    chunkSize: number,
    chunkCursorRange: number,
    passageSize: number,
    passageThreshold: number,
    faqThreshold: number
}

export const ADADefaultSetting = {
    chunkSize: 500,
    chunkCursorRange: 1,
    passageSize: 5,
    passageThreshold: 79,
    faqThreshold: 60,
}


export interface ADAPassageSearch {
    type: SearchEngineType.ADA,
    setting: ADASetting
}

export interface Qa42Setting {
    passageSize: number;
    mrcAnswerThreshold: number;
    mrcThreshold: number;
    faqThreshold: number;
}

export const Qa42DefaultSetting = {
    passageSize: 5,
    mrcAnswerThreshold: 80,
    mrcThreshold: 50,
    faqThreshold: 60,
}

export interface Qa42PassageSearch {
    type: SearchEngineType.QA42;
    setting: Qa42Setting
}

export type PassageSearchSetting = ADAPassageSearch | Qa42PassageSearch

export interface SearchSpecificationBase {
    key: string;
    description?: string;
    passageSearchSetting: PassageSearchSetting;
    dependency: boolean;
}

export interface SearchSpecification extends SearchSpecificationBase {
    _id: string;
    createdAt: string;
    updatedAt: string;
}

export interface SearchSpecificationHistoryBase {
    searchSpecification: SearchSpecification;
    version: number
}

export interface SearchSpecificationHistory extends SearchSpecificationHistoryBase {
    _id: string;
    createdAt: string;
    updatedAt: string;
}

export type SearchSpecificationCreateRequest = Omit<SearchSpecificationBase, "dependency">
export type SearchSpecificationUpdateRequest = Omit<SearchSpecificationBase, "dependency">
