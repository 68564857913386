import React, {useEffect, useMemo, useState} from "react";
import {SubscriptionTransaction} from "../../model/transaction/subscriptionTransaction";
import useModal, {Modal} from "../../hook/useModal";
import moment from "moment";


export const SUBSCRIPTION_TRANSACTION_DETAIL_MODAL_ID = "transaction-detail-modal-id";

const SubscriptionTransactionDetailModal: React.FC = () => {
    const modal = useModal();

    const [subscriptionTransaction, setSubscriptionTransaction] = useState<SubscriptionTransaction>();

    const isRefund = useMemo(() => {
        if (!subscriptionTransaction) {
            return false
        }

        return subscriptionTransaction.transaction.billing.amount < 0;
    }, [subscriptionTransaction])

    useEffect(() => {
        const data = modal.data[SUBSCRIPTION_TRANSACTION_DETAIL_MODAL_ID] as SubscriptionTransaction;
        if (data) {
            setSubscriptionTransaction(data);
        }
    }, [modal])

    return (
        <Modal id={SUBSCRIPTION_TRANSACTION_DETAIL_MODAL_ID}
               title={"결제 내역 세부 정보"}
        >
            {subscriptionTransaction &&
                <div className="mt-3 border border-gray-400 rounded-md divide-y divide-gray-400">
                    <SimpleRow label={"결제 ID"}
                               content={subscriptionTransaction.transaction._id} />
                    <SimpleRow label={"채널 ID"}
                               content={subscriptionTransaction.channel?._id} />
                    <SimpleRow label={"채널 이름"}
                               content={subscriptionTransaction.channel?.name} />
                    <SimpleRow label={"구독 플랜 (요금제)"}
                               content={subscriptionTransaction.channelSubscriptionHistory?.subscription.plan.name} />
                    <SimpleRow label={"구독 기록 ID"}
                               content={subscriptionTransaction.channelSubscriptionHistory?._id} />
                    <SimpleRow label={isRefund ? "환불 금액/단위" : "결제 금액/단위"}
                               content={`${subscriptionTransaction.transaction.billing.amount}/${subscriptionTransaction.transaction.billing.currencyUnit}`} />
                    <SimpleRow label={"결제 일자"}
                               content={moment.utc(subscriptionTransaction.transaction.createdAt).local().format("YYYY-MM-DD HH:mm:ss")} />
                    <SimpleRow label={"카드 번호"}
                               content={`****-****-****-${subscriptionTransaction.transaction.card.cardNumber.slice(-4)}`} />
                    <SimpleRow label={"채널 관리자 ID"}
                               content={subscriptionTransaction.user?._id} />
                    <SimpleRow label={"채널 관리자 이름"}
                               content={subscriptionTransaction.user?.fullName} />
                    <SimpleRow label={"청구서 수령인 이메일"}
                               content={subscriptionTransaction.user?.email} />

                </div>
            }
        </Modal>
    );
};

type SimpleRowProp = {
    label: string;
    content?: React.ReactNode;
}

const SimpleRow: React.FC<SimpleRowProp> = (props) => {
    return (
        <div className="flex items-center">
            <div className="w-[150px] border-r border-gray-400 px-2 py-1">{props.label}</div>
            <div className="px-2 py-1 flex-grow">{props.content ?? "-"}</div>
        </div>
    )
}

export default SubscriptionTransactionDetailModal;
