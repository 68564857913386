import React, {useCallback, useMemo, useState} from "react";
import {useSearchParams} from "react-router-dom";
import useQueryLogs from "../../query/queryLog/useQueryLogs";
import {Switch} from "@headlessui/react";
import classNames from "classnames";
import QueryLogRow from "./QueryLogRow";
import PageBar from "../PageBar";
import {ChannelResponse} from "../../model/channel/channel";
import {IncomingRequestEventType} from "../../model/QueryLog";

type LLMResponseQueryLogListProps = {
    channel: ChannelResponse
}
const pageCount = 10;
export const LLMResponseQueryLogList: React.FC<LLMResponseQueryLogListProps> = (props) => {
    const {channel} = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const defaultPage: number = useMemo(() => {
        return parseInt(searchParams.get("page") ?? "1")
    }, [searchParams]);
    const [showQuestion, setShowQuestion] = useState<IncomingRequestEventType>(IncomingRequestEventType.QUERY);
    const {data: queryLogResponsePage} = useQueryLogs(defaultPage, channel._id, showQuestion, pageCount);
    const onClickPage = useCallback((page: number) => {
        searchParams.set("page", page.toString());
        setSearchParams(searchParams)
    }, [searchParams, setSearchParams]);

    const lastPage = useMemo(() => {
        if (!queryLogResponsePage) return 0;
        return Math.floor(queryLogResponsePage.count / pageCount + ((queryLogResponsePage.count % pageCount) && 1));
    }, [queryLogResponsePage])
    const onChangeButton = () => {
        if (showQuestion === IncomingRequestEventType.QUERY) setShowQuestion(IncomingRequestEventType.REPLY_QUESTION)
        else setShowQuestion(IncomingRequestEventType.QUERY)
    }
    if (!queryLogResponsePage?.queryLogs.length) return <div>테스트 로그가 없습니다.</div>
    return (
        <div className={"w-full h-full"}>
            <div className="h-auto m-5 py-5 rounded-md flex items-center">
                <Switch checked={showQuestion === IncomingRequestEventType.QUERY}
                        onChange={onChangeButton}
                        className={classNames(
                            showQuestion === IncomingRequestEventType.QUERY ? "bg-blue-400" : "bg-gray-200",
                            "relative inline-flex h-[24px] w-[49px] shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none"
                        )}>
                    <span className="sr-only"/>
                    <span aria-hidden={true}
                          className={classNames(
                              showQuestion === IncomingRequestEventType.QUERY ? "translate-x-[25px]" : "translate-x-0",
                              "pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white ring-0 transition duration-200 ease-in-out"
                          )}/>
                </Switch>
                <div className="flex flex-col column mx-4">
                    <label className="text-lg font-bold"
                           htmlFor="show-reference">후속 질의 제외 로그 보기</label>
                    <div className="text-sm text-gray-500 text-opacity-80">
                        후속질의를 제외한 챗봇 답변 로그만 표시합니다
                    </div>
                </div>
            </div>
            <div>
                <div className="border border-black rounded-t-xl pl-2 bg-gray-100">
                    로그 리스트
                </div>
                {queryLogResponsePage?.queryLogs.map((queryLog) => {
                    return (
                        <div key={queryLog._id} className="p-2 border-black border-b border-x">
                            <QueryLogRow queryLog={queryLog}/>
                        </div>

                    )
                })}
            </div>
            <div>
                <PageBar page={defaultPage} lastPage={lastPage} onClickPage={onClickPage}/>
            </div>
        </div>
    )
}