import { GPTPrompt } from "../../../model/llmSpecification";
import React, { ReactNode, useMemo } from "react";
import { useIntl } from "react-intl";
import { DetailSettingRowFragment, DetailSettingTable, renderText } from "../component/DetailSettingTable";
import DetailDisclosure from "../component/DetailDisclosure";

interface ContextBuildSectionProps {
    latency: number
    result: GPTPrompt[]
}

const ContextBuildSection: React.FC<ContextBuildSectionProps> = (props) => {
    const intl = useIntl();
    const {latency, result: context} = props;

    const summary: ReactNode = useMemo(() => (
        <DetailSettingTable>
            {DetailSettingRowFragment(intl.formatMessage({id: 'i11000'}), context.length)}
        </DetailSettingTable>
    ), [intl, context]);

    return (
        <DetailDisclosure title={intl.formatMessage({id: "i11046"})}
                          latency={latency}
                          summary={summary}>
            <>
                <div className="flex flex-col space-y-3 divide-y-2 p-2">
                    <div className={"flex flex-col space-y-3"}>
                        <div className=" leading-6 whitespace-normal  flex flex-col divide-y-4">
                            {context.map(({role, content}, index: number) => (
                                <div key={role}>
                                    <div className={"font-semibold text-xl mt-2 mb-2"}>{`${intl.formatMessage({id: "i11072"})} ${index+1}`}</div>
                                    <div className={"flex flex-col justify-start space-y-2 py-2"}>
                                        <div className={"text-lg"}>{"role"}</div>
                                        <div className={"ml-4 p-1 px-4 flex flex-col w-full border-2 rounded-xl"}>
                                            {role}
                                        </div>
                                    </div>
                                    <div className={"flex flex-col justify-start space-y-2 py-2"}>
                                        <div className={"text-lg"}>{"content"}</div>
                                        <div className={"ml-4 p-1 px-4 flex flex-col w-full border-2 rounded-xl"}>
                                            {renderText(content)}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </>
        </DetailDisclosure>
    );
}

export default ContextBuildSection;
