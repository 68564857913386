import axios from "axios";
import {API_HOST} from "../core/variables";


export const me = async () => {
    return axios.get(`${API_HOST}/v1/auth/me`)
}

export const login = async (username: string, password: string) => {
    let form = new FormData()
    form.append('username', username)
    form.append('password',password)
    return axios.post(`${API_HOST}/v1/auth/login`, form)
}

export const logout = async () => {
    return axios.post(`${API_HOST}/v1/auth/logout`)
}