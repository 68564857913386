import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {LLMSpecification} from "../../model/llmSpecification";
import llmSpecificationRepository from "../../respsitory/LLMSpecificationRepository";

const getData = async (key?: string) => {
    try {
        const { data } = await llmSpecificationRepository.find(key);
        return data.result;
    } catch (e) {
        throw e;
    }
}

const useLLMSpecifications = (key?: string) => {
    return useQuery<LLMSpecification[], AxiosError>({
        queryKey: ["llm-specifications", key],
        queryFn: async () => getData(key),
        retry: false,
        suspense: false,
    })
}

export default useLLMSpecifications;
