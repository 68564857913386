import {useMemo} from "react";
import {Tab} from "@headlessui/react";
import ChannelMainView from "./ChannelMainView";
import ChannelGroupView from "./ChannelGroupView";

const ChannelTab = () => {
    const tabList = useMemo(() => [
        {id: 1, title: "채널 리스트", disabled: false},
        {id: 2, title: "채널 그룹 관리", disabled: false},
        // {id: 3, title: "그룹 관리", disabled: false},
    ], [])

    const tabClassName = (selected: boolean) => {
        return selected
            ? "transition-colors duration-200 text-gray-500 pb-3 focus:outline-none text-gray-900 border-b border-black"
            : "transition-colors duration-200 text-gray-500 pb-3 focus:outline-none"
    }

    return (
        <div className="w-full h-full flex flex-col">
            <div className="flex justify-between p-7 bg-white h-auto">
                <div className="font-bold text-3xl">
                    채널 관리
                </div>
            </div>

            <Tab.Group defaultIndex={0}>
                <Tab.List className="bg-white h-auto px-7 flex space-x-7 border-b">
                    {tabList.map(tab => (
                        <Tab key={tab.id}
                             disabled={tab.disabled}
                             className={({selected}) => tabClassName(selected)}>
                            {tab.title}
                        </Tab>
                    ))}
                </Tab.List>
                <Tab.Panels className="flex-grow">
                    <Tab.Panel className="h-full pt-5">
                        <ChannelMainView/>
                    </Tab.Panel>
                    <Tab.Panel className="h-full p-5">
                        <ChannelGroupView/>
                    </Tab.Panel>
                    {/*<Tab.Panel className="h-full p-5">*/}
                    {/*    <GroupMainView/>*/}
                    {/*</Tab.Panel>*/}
                </Tab.Panels>
            </Tab.Group>
        </div>
    )
};

export default ChannelTab;