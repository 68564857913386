import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {LLMSpecification, LLMSpecificationCreateRequest} from "../../model/llmSpecification";
import llmSpecificationRepository from "../../respsitory/LLMSpecificationRepository";

const useCreateLLMSpecification = () => {
    const queryClient = useQueryClient();

    return useMutation<LLMSpecification, AxiosError, LLMSpecificationCreateRequest>({
        mutationKey: ["llm-specifications", "create"],
        mutationFn: async (body: LLMSpecificationCreateRequest) => {
            try {
                const { data } = await llmSpecificationRepository.create(body);
                return data.result;
            } catch (e) {
                throw e;
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["llm-specifications"],
                exact: false
            });
        }
    })
};

export default useCreateLLMSpecification;
