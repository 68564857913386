import React, {Dispatch} from "react";
import SettingDisclosure from "../../SettingDisclosure";
import {ADASetting, SearchEngineType, Qa42Setting} from "../../../model/searchSpecification";


type EngineSettingProps = {
    setEngineSetting: Dispatch<React.SetStateAction<ADASetting | Qa42Setting>>,
    engineSetting: ADASetting | Qa42Setting,
    selectedSearchEngine: SearchEngineType
}

const EngineSetting: React.FC<EngineSettingProps> = (props) => {
    const {engineSetting, setEngineSetting, selectedSearchEngine} = props;
    const onChangeEngineSetting = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setEngineSetting({
            ...engineSetting,
            [name]: value
        })
    }
    return (
        <div className="form-input-group flex">
            <label htmlFor="ses-model"
                   className="form-label min-w-[150px] text-lg">
                검색엔진 설정
            </label>
            <SettingDisclosure title={"검색엔진 설정"} description={"검색 결과에 영향을 주는 설정"} disabledColor={true}
                               defaultOpen={false}>
                <div className="w-full">
                    {Object.entries(engineSetting).map((setting) => {
                        const [name, value] = setting
                        return (
                            <div key={`${selectedSearchEngine}`}
                                 className="flex items-center space-x-2 mb-2">
                                <input className="form-input"
                                       value={name} readOnly={true}
                                />
                                <input className="form-input"
                                       name={name}
                                       defaultValue={value}
                                       type="number"
                                       onChange={onChangeEngineSetting}/>
                            </div>
                        )
                    })}
                </div>
                {selectedSearchEngine === SearchEngineType.QA42 &&
                    <div>
                        <div>
                            <label className="text-gray-500">
                                PassageSize : &nbsp;
                            </label>
                            검색 문서 개수 <br/>
                        </div>

                        <div>
                            <label className="text-gray-500">
                                MRCAnswerThreshold : &nbsp;
                            </label>
                            MRC 정답 사용 Threshold 값 <br/>
                        </div>

                        <div>
                            <label className="text-gray-500">
                                mrcThreshold : &nbsp;
                            </label>
                            MRC 문서 사용 Threshold 값 <br/>
                        </div>
                        <div>
                            <label className="text-gray-500">
                                faqThreshold : &nbsp;
                            </label>
                            FAQ 사용 Threshold 값 <br/>
                        </div>
                    </div>
                }
                {selectedSearchEngine === SearchEngineType.ADA &&
                    <div>
                        <div>
                            <label className="text-gray-500">
                                chunkSize : &nbsp;
                            </label>
                            문서 인덱싱 크기 <br/>
                        </div>

                        <div>
                            <label className="text-gray-500">
                                chunkCursorSize : &nbsp;
                            </label>
                            문서 검색 범위 <br/>
                        </div>

                        <div>
                            <label className="text-gray-500">
                                PassageSize : &nbsp;
                            </label>
                            검색 문서 개수 <br/>
                        </div>

                        <div>
                            <label className="text-gray-500">
                                threshold : &nbsp;
                            </label>
                            검색 문서 사용 Threshold 값 <br/>
                        </div>

                        <div>
                            <label className="text-gray-500">
                                faqThreshold : &nbsp;
                            </label>
                            FAQ 사용 Threshold 값 <br/>
                        </div>
                    </div>
                }
            </SettingDisclosure>
        </div>
    )
}

export default EngineSetting;