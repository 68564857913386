import axios from "axios";
import {API_HOST} from "../core/variables";
import {ChannelCommonSettingCreateRequest, ChannelCommonSettingUpdateRequest} from "../model/ChannelCommonSetting";

class ChannelCommonSettingRepository {

    async find(key? : string) {
        return axios.get(`${API_HOST}/v1/channel-common-setting`, {
            params: {
                key: key,
            }
        })
    };

    async findHistory(channel_common_setting_id?: string) {
        return axios.get(`${API_HOST}/v1/channel-common-setting-history`, {
            params: {
                id: channel_common_setting_id
            }
        })
    };

    async findSetting() {
        return axios.get(`${API_HOST}/v1/channel-common-setting/agent`)
    };

    async existed(key: string) {
        return axios.get(`${API_HOST}/v1/channel-common-setting/existed`, {
            params: {key}
        })
    }

    async create(body: ChannelCommonSettingCreateRequest) {
        return axios.post(`${API_HOST}/v1/channel-common-setting`, body)
    }

    async update(id: string, body: ChannelCommonSettingUpdateRequest) {
        return axios.put(`${API_HOST}/v1/channel-common-setting/${id}`, body)
    }

    async delete(id: string) {
        return axios.delete(`${API_HOST}/v1/channel-common-setting/${id}`);
    }

    async generateToken(channelCommonSettingId: string) {
        return axios.get(`${API_HOST}/v1/channel-common-setting/${channelCommonSettingId}/token`);
    }
}

const channelCommonSettingRepository = new ChannelCommonSettingRepository();

export default channelCommonSettingRepository;
