import React, {useRef, useState} from "react";
import { Transition } from "@headlessui/react";
import VariableGuide from "./VariableGuide";


type VariableGuideProps = {
    selectedPromptType: string
}

const GuideMainView:React.FC<VariableGuideProps> = (props) => {
    const {selectedPromptType} = props;
    const [open, setOpen] = useState<boolean>(false);

    const questionTestRef = useRef<HTMLDivElement>(null);

    const close = () => setOpen(false);

    return (
        <div ref={questionTestRef}
             className={"text-base font-normal whitespace-nowrap break-all"}>
            <button className={"p-3 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 " +
                "hover:bg-gray-100 hover:text-blue-700 hover:border-indigo-400 " +
                "focus:outline-none focus:z-10 focus:ring-4 focus:ring-gray-200"}
                    onClick={() => setOpen(v => !v)}>
                프롬프트 변수 목록 보기
            </button>
            <div className={"w-[calc(100vw-1100px)] min-w-[800px] h-[100vh] min-h-[400px] top-0 fixed right-[calc(100vw)] z-50"}>
                <Transition show={open}
                            enter="transition-transform duration-500 ease-in-out"
                            enterFrom="translate-x-0"
                            enterTo="translate-x-full"
                            leave="transition-transform duration-500 ease-in-out"
                            leaveFrom="translate-x-full"
                            leaveTo="-translate-x-0" >
                    <div className={"w-full h-full border-gray-900 border-5 rounded-xl shadow-2xl bg-white z-50"}>
                        <VariableGuide selectedPromptType={selectedPromptType} close={close}/>
                    </div>
                </Transition>
            </div>
        </div>
    )
}

export default GuideMainView;
