import {useNavigate} from "react-router-dom";
import {useCallback, useMemo} from "react";
import {NavigateOptions} from "react-router/dist/lib/context";

const useRouteHandler = () => {
    const navigate = useNavigate();
    const moveToLogin = useCallback((options?: NavigateOptions) => {
        navigate("/login", options);
    }, [navigate]);

    const moveToHome = useCallback((options?: NavigateOptions) => {
        navigate("/", options);
    }, [navigate]);

    const resetRoute = useCallback(() => {
        navigate('/login');
    }, [navigate])

    return useMemo(() => {
        return {
            moveToLogin,
            moveToHome,
            resetRoute,
        }
    }, [
        moveToLogin,
        moveToHome,
        resetRoute,
    ])
}

export default useRouteHandler;
