import promptRepository from "../../respsitory/PromptRepository";
import {useQuery} from "@tanstack/react-query";
import {Prompt} from "../../model/prompt/prompt";
import {AxiosError} from "axios";

const getData = async (id?: string) => {
    try {
        const {data} = await promptRepository.readById(id);
        return data.result;
    } catch (e) {
        throw e;
    }
}

const usePromptById = (id?: string) => {
    return useQuery<Prompt, AxiosError>({
        queryKey: ["prompt", id],
        queryFn: async () => getData(id),
        retry: false,
        suspense: false
    })
}

export default usePromptById;
