import {useMutation, useQueryClient} from "@tanstack/react-query";
import {ChannelCommonSetting} from "../../model/ChannelCommonSetting";
import {AxiosError} from "axios";
import channelCommonSettingRepository from "../../respsitory/ChannelCommonSettingRepository";

const useDeleteChannelCommonSetting = () => {
    const queryClient = useQueryClient();

    return useMutation<ChannelCommonSetting, AxiosError, string>({
        mutationKey: ["channelCommonSetting","delete"],
        mutationFn: async (id: string) =>{
            try {
                const {data} = await channelCommonSettingRepository.delete(id);
                return data.result;
            } catch (e) {
                throw e;
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["channelCommonSetting"],
                exact: false
            })
        }
    })
};

export default useDeleteChannelCommonSetting;