import React, {useCallback, useState} from "react";
import {User} from "../../model/User";
import {useDialog} from "../../hook/useDialog";
import userRepository from "../../respsitory/UserRepository";
import toast from "react-hot-toast";
import moment from "moment/moment";
import {RESTORATION_FEATURE_DEVELOPED_AT} from "../../core/variables";
import {useQueryClient} from "@tanstack/react-query";

type UserDeleteAndRestoreTabProps = {
    user: User;
};

const UserDeleteAndRestoreTab: React.FC<UserDeleteAndRestoreTabProps> = (props) => {
    const { user } = props;

    const dialog = useDialog();
    
    const [loading, setLoading] = useState(false);

    const queryClient = useQueryClient();

    const onMutate = useCallback(async () => {
        try {
            await queryClient.invalidateQueries({
                queryKey: ["user", user._id],
                exact: true
            })
        } catch (e) {
            toast.error("자동 새로고침에 실패했습니다. 페이지를 새로고침 해주세요.")
        }
    }, [queryClient, user._id])
    
    const onClickDelete = () => {
        if (user.deleted) {
            return;
        }

        dialog.open({
            variant: "danger",
            title: "계정 삭제",
            content: "계정을 삭제하시겠습니까? 계정에 속한 계정도 모두 삭제되며, 삭제된 계정은 30일 이후에 자동으로 영구 삭제됩니다.",
            onConfirm: async () => {
                setLoading(true)

                try {
                    await userRepository.deleteUser(user._id);
                    toast.success("계정을 삭제했습니다.")
                    await onMutate();
                } catch (e) {
                    toast.error("계정을 삭제하는 도중 에러가 발생했습니다.")
                } finally {
                    setLoading(false);
                }
            }
        })
    }
    
    const onClickRestore = () => {
        if (!user.deleted || user.hardDeleted) {
            return;
        }

        if (moment.utc(user.deletedAt).isBefore(moment.utc(RESTORATION_FEATURE_DEVELOPED_AT))) {
            toast.error("복구 기능이 개발되기 이전에 삭제된 계정입니다.")
            return;
        }

        dialog.open({
            variant: "danger",
            title: "삭제된 계정 복구",
            content: "계정을 복구하시겠습니까? 계정에 속한 계정도 모두 복구됩니다. 단, 계정이 삭제되기 전에 삭제되었던 계정은 복구되지 않습니다.",
            onConfirm: async () => {
                setLoading(true)

                try {
                    await userRepository.restoreUser(user._id);
                    toast.success("계정을 복구했습니다.")
                    await onMutate();
                } catch (e) {
                    toast.error("계정을 복구하는 도중 에러가 발생했습니다.")
                } finally {
                    setLoading(false)
                }
            }
        })
    }
    
    const onClickHardDelete = () => {
        if (!user.deleted || user.hardDeleted) {
            return;
        }

        if (moment.utc(user.deletedAt).isBefore(moment.utc(RESTORATION_FEATURE_DEVELOPED_AT))) {
            toast.error("영구 삭제 기능이 개발되기 이전에 삭제된 계정입니다.")
            return;
        }

        dialog.open({
            variant: "danger",
            title: "계정 영구 삭제",
            content: "삭제 시점으로부터 30일이 지나면 자동으로 영구 삭제됩니다. 그래도 영구 삭제하시겠습니까?",
            onConfirm: async () => {
                setLoading(true)

                try {
                    await userRepository.hardDeleteUser(user._id);
                    toast.success("계정을 영구 삭제했습니다.")
                    await onMutate();
                } catch (e) {
                    toast.error("계정을 영구 삭제하는 도중 에러가 발생했습니다.")
                } finally {
                    setLoading(false)
                }
            }
        })
    }
    
    return (
        <div className="space-y-3">
            <div className="w-[700px] h-[100px] rounded-xl border border-red-400 bg-red-100 p-5 space-y-3">
                <p className="text-red-500">Danger Zone</p>
                <p className="text-sm">고객 요청 혹은 특수 목적이 아니라면 이 탭을 사용하지 마세요. 서비스 중인 계정에 영향을 줄 수 있습니다.</p>
            </div>

            <p>계정 삭제하기</p>
            <div className="flex items-center space-x-5">
                <button className="btn btn-danger w-[120px]" disabled={user.deleted || loading} onClick={onClickDelete}>
                    Delete
                </button>
                {user.deleted && user.deletedAt &&
                    <p className="text-sm">
                        삭제된 날짜: {moment.utc(user.deletedAt).local().format("YYYY-MM-DD HH:mm:ss")}
                    </p>
                }
            </div>

            <p>삭제된 계정 복구하기 (삭제되지 않았거나 이미 영구 삭제된 계정은 복구할 수 없습니다.)</p>
            <button className="btn btn-danger w-[120px]" disabled={!user.deleted || user.hardDeleted || loading} onClick={onClickRestore}>
                Restore
            </button>

            <p>계정 영구 삭제하기 (삭제되지 않았거나, 이미 영구 삭제된 계정은 영구 삭제할 수 없습니다.)</p>
            <div className="flex items-center space-x-5">
                <button className="btn btn-danger w-[120px]" disabled={!user.deleted || user.hardDeleted || loading} onClick={onClickHardDelete}>
                    Hard Delete
                </button>
                {user.hardDeleted && user.hardDeletedAt &&
                    <p className="text-sm">
                        영구 삭제된 날짜: {moment.utc(user.hardDeletedAt).local().format("YYYY-MM-DD HH:mm:ss")}
                    </p>
                }
            </div>
        </div>
    );
};

export default UserDeleteAndRestoreTab;
