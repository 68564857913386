import axios from "axios";
import {API_HOST} from "../core/variables";
import {QueryLogCreateRequest} from "../model/QueryLog";

class QueryLogRepository {

    async find(page: number, channelId: string, answerType: string, logType?: string, size?: number) {
        return axios.get(`${API_HOST}/v1/query-log`, {
            params: {
                page: page,
                size: size,
                channel_id: channelId,
                answer_type: answerType,
                log_type: logType
            }
        })
    }
    async read(id: string) {
        return axios.get(`${API_HOST}/v1/query-log/${id}`)
    }

    async create(body: QueryLogCreateRequest) {
        return axios.post(`${API_HOST}/v1/query-log`, body)
    }

    async delete(id: string) {
        return axios.delete(`${API_HOST}/v1/query-log/${id}`)
    }
}

const queryLogRepository = new QueryLogRepository();

export default queryLogRepository;