import useUserPagination from "../../query/user/useUserPagination";
import React, {useCallback, useMemo} from "react";
import PageBar from "../../component/PageBar";
import UserRow from "./UserRow";
import CategorySearchBar from "../../component/CategorySearchBar";
import {useSearchParams} from "react-router-dom";
import {isUserSearchType, UserSearchType, UserSearchTypeEnum} from "../../model/User";

const pageCount = 10;
const UserView = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const defaultPage: number = useMemo(() => {
        return parseInt(searchParams.get("page") ?? "1")
    }, [searchParams]);

    const searchCategory = useMemo(() => {
        return [
            {
                category: UserSearchTypeEnum.USERNAME,
                expression: "고객 이름"
            },
            {
                category: UserSearchTypeEnum.EMAIL,
                expression: "이메일"
            },
        ]
    }, [])

    const defaultCategory: UserSearchType | undefined = useMemo(() => {
        return isUserSearchType(searchParams.get("category")) ? searchParams.get("category") as UserSearchType : undefined
    }, [searchParams]);

    const onSubmitKeyword = useCallback((category: string, keyword: string) => {
        setSearchParams({category, keyword})
    }, [setSearchParams])

    const defaultKeyword = useMemo(() => {
        return searchParams.get("keyword") ?? undefined
    }, [searchParams]);

    const onClickPage = useCallback((page: number) => {
        searchParams.set("page", page.toString());
        setSearchParams(searchParams)

    }, [searchParams, setSearchParams]);

    const {data: userPagination} = useUserPagination(defaultPage, pageCount, defaultCategory, defaultKeyword);

    const lastPage = useMemo(() => {
        if (!userPagination) return 0;
        return Math.floor(userPagination.count / pageCount + ((userPagination.count % pageCount) && 1));
    }, [userPagination])

    return (
        <div className="p-5">
            <div className="flex justify-between">
                <div className="text-3xl font-bold">
                    고객 관리
                </div>
            </div>
            <div className="w-full">
                <CategorySearchBar className={"w-1/3 float-right pb-5"}
                                   categories={searchCategory}
                                   defaultCategory={defaultCategory}
                                   defaultKeyword={defaultKeyword}
                                   onSubmit={onSubmitKeyword}/>
            </div>
            <div className="py-6">
                <div className="font-medium">
                    전체 고객 수 : {userPagination?.count}
                </div>
                <table className="w-full p-5 table-fixed break-keep sm:rounded-lg border-2">
                    <thead className="w-full text-sm font-bold text-left border-b bg-gray-200">
                    <tr className="text-[16px]">
                        <th scope="col" className="p-2.5 py-4 text-left w-[15%]">
                            고객 이름
                        </th>
                        <th scope="col" className="p-2.5 py-4 text-left w-[15%]">
                            고객 이메일
                        </th>
                        {/*<th scope="col" className="p-2.5 py-4 text-left w-[15%]">*/}
                        {/*    채널 수*/}
                        {/*</th>*/}
                        <th scope="col" className="p-2.5 py-4 text-left w-[15%]">
                            가입일
                        </th>
                        <th scope="col" className="p-2.5 py-4 text-left w-[15%]">
                            최근 접속일
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        {userPagination?.userResponse.map((userResponse) => {
                            return (
                                <UserRow user={userResponse.user}/>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div>
                <PageBar page={defaultPage} lastPage={lastPage} onClickPage={onClickPage}/>
            </div>
        </div>
    )
}

export default UserView;