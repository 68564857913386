import { GPTPrompt } from "../llmSpecification";
import {QueryLog} from "../QueryLog";


export interface ErrorLog {
    process: 'analyzed_query' | 'passage_search' | 'prompt_build' | 'llm_generated' | 'load_data' | 'query_embedding' | 'other',
    type: string
    code: number
    detail: string
    traceback?: string
}


export enum AnswerType {
    ANSWER = 'answer',
    QUESTION = 'question',
    REPLYQUESTION = 'reply_question'
}


export interface AnswerResponse {
    type: AnswerType
    response: string
    context: GPTPrompt[]
}


export interface ReplyQuestionResponse {
    type: AnswerType
    questionList: string[] | null
    replyFlag: boolean
    query?: string
}


export interface PanelChat {
    type: AnswerType
    state: 'default' | 'loading' | 'error' | 'done'
    question?: string
    queryLog?: QueryLog
}

export interface QuestionPanelChat extends PanelChat {
    type: AnswerType.QUESTION
    state: 'done'
    question: string
}

export const questionPanelChat: (query: string) => QuestionPanelChat = (query) => {
    return {
        type: AnswerType.QUESTION,
        state: 'done',
        question: query
    };
};



export interface AnswerPanelChat extends PanelChat {
    type: AnswerType.ANSWER
    state: 'loading' | 'error' | 'done'
}

export const loadingAnswerPanelChat: AnswerPanelChat = {
    type: AnswerType.ANSWER,
    state: 'loading'
}

export const errorAnswerPanelChat: AnswerPanelChat = {
    type: AnswerType.ANSWER,
    state: 'error'
}

export const answerPanelChat: (answer: QueryLog) => AnswerPanelChat = (answer) => {
    return {
        type: AnswerType.ANSWER,
        state: 'done',
        queryLog: answer,
    }
}


export interface ReplyQuestionPanelChat extends PanelChat {
    type: AnswerType.REPLYQUESTION
    state: 'default' | 'loading' | 'error' | 'done'
}

export const defaultReplyQuestionPanelChat: ReplyQuestionPanelChat = {
    type: AnswerType.REPLYQUESTION,
    state: 'default'
}

export const loadingReplyQuestionPanelChat: ReplyQuestionPanelChat = {
    type: AnswerType.REPLYQUESTION,
    state: 'loading'
}

export const errorReplyQuestionPanelChat: ReplyQuestionPanelChat = {
    type: AnswerType.REPLYQUESTION,
    state: 'error'
}

export const replyQuestionPanelChat: (queryLog: QueryLog) => ReplyQuestionPanelChat = (queryLog) => {
    return {
        type: AnswerType.REPLYQUESTION,
        state: 'done',
        queryLog: queryLog,
    }
}

export const replyQuestionsDecode = (rawText?: string) => {
    if (!rawText)
        return [];

    try {
        return JSON.parse(rawText)["questions"];
    } catch (e) {
        return [rawText];
    }
}