import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {UsageStatistics} from "../../model/analytics/analytics";
import analyticsRepository from "../../respsitory/AnalyticsRepository";

const getData = async () => {
    try {
        const { data } = await analyticsRepository.getUsageStatistics();
        return data.result;
    } catch (e) {
        throw e;
    }
}

const useUsageStatistics = () => {
    return useQuery<UsageStatistics, AxiosError>({
        queryKey: ["usage-statistics"],
        queryFn: async () => getData(),
        retry: false,
        suspense: false,
    })
}

export default useUsageStatistics;
