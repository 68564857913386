import React, {useEffect, useState} from "react";
import {ChannelResponse, ChannelSettingUpdate} from "../../../model/channel/channel";
import ChannelSettingPreset from "./ChannelSettingPreset";
import {Switch} from "@headlessui/react";
import classNames from "classnames";
type ChannelSettingViewProps = {
    channel: ChannelResponse
    settingRef: React.MutableRefObject<ChannelSettingUpdate>
}

const ChannelSettingView: React.FC<ChannelSettingViewProps> = (props) => {
    const {channel, settingRef} = props;
    const [showReferenceEnabled, setShowReferenceEnabled] = useState(channel.setting.showReference);
    const [showPassageSearchFailMessageEnabled, setShowPassageSearchFailMessageEnabled] = useState(channel.setting.showPassageSearchFailMessage);
    const [showQuestionReplyEnabled, setShowQuestionReplyEnabled] = useState(channel.setting.showQuestionReply);

    useEffect(() => {
        settingRef.current = ({
            ...settingRef.current,
            showReference: showReferenceEnabled,
            showQuestionReply: showQuestionReplyEnabled,
            showPassageSearchFailMessage: showPassageSearchFailMessageEnabled
        })
    }, [showReferenceEnabled, showQuestionReplyEnabled, showPassageSearchFailMessageEnabled, settingRef])
    return (
        <>
            <ChannelSettingPreset
                defaultSetting={channel.setting}
                settingRef={settingRef}
            />
            <div>
                {/* Show Passage Search Fail Message */}
                <div className="h-auto m-5 py-5 rounded-md flex items-center">
                    <Switch checked={showPassageSearchFailMessageEnabled}
                            onChange={(checked) => setShowPassageSearchFailMessageEnabled(checked)}
                            className={classNames(
                                showPassageSearchFailMessageEnabled ? "bg-blue-400" : "bg-gray-200",
                                "relative inline-flex h-[24px] w-[49px] shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none"
                            )}>
                        <span className="sr-only"/>
                        <span aria-hidden={true}
                              className={classNames(
                                  showPassageSearchFailMessageEnabled ? "translate-x-[25px]" : "translate-x-0",
                                  "pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white ring-0 transition duration-200 ease-in-out"
                              )}/>
                    </Switch>
                    <div className="flex flex-col column mx-4">
                        <label className="font-bold"
                               htmlFor="show-reference">검색 실패 메세지 표시</label>
                        <div className="text-sm text-gray-500 text-opacity-80">문서 검색 실패시 "죄송합니다. 질문에
                            대한 정확한 답변을 찾을 수 없습니다." 를 답변으로 출력합니다.
                        </div>
                    </div>
                </div>

                {/* Show Reference */}
                <div className="h-auto m-5 py-5 rounded-md flex items-center">
                    <Switch checked={showReferenceEnabled}
                            onChange={(checked) => setShowReferenceEnabled(checked)}
                            className={classNames(
                                showReferenceEnabled ? "bg-blue-400" : "bg-gray-200",
                                "relative inline-flex h-[24px] w-[49px] shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none"
                            )}>
                        <span className="sr-only"/>
                        <span aria-hidden={true}
                              className={classNames(
                                  showReferenceEnabled ? "translate-x-[25px]" : "translate-x-0",
                                  "pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white ring-0 transition duration-200 ease-in-out"
                              )}/>
                    </Switch>
                    <div className="flex flex-col column mx-4">
                        <label className="font-bold"
                               htmlFor="show-reference">출처 표시하기</label>
                        <div className="text-sm text-gray-500 text-opacity-80">출처 표시하기를 켜면 매 답변시마다 해당 답변의 출처가 되는 데이터를
                            '...(ex: ...)'로 표시합니다.
                        </div>
                    </div>
                </div>

                {/* Show Reply Questions */}
                <div className="flex h-auto m-5 py-5 rounded-md items-center">
                    <Switch checked={showQuestionReplyEnabled}
                            onChange={(checked) => setShowQuestionReplyEnabled(checked)}
                            className={classNames(
                                showQuestionReplyEnabled ? "bg-blue-400" : "bg-gray-200",
                                "relative inline-flex h-[24px] w-[49px] shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none"
                            )}>
                        <span className="sr-only"/>
                        <span aria-hidden={true}
                              className={classNames(
                                  showQuestionReplyEnabled ? "translate-x-[25px]" : "translate-x-0",
                                  "pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white ring-0 transition duration-200 ease-in-out"
                              )}/>
                    </Switch>
                    <div className="flex flex-col column mx-4">
                        <label className="font-bold"
                               htmlFor="show-reference">답변 재질의 버튼 표시하기</label>
                        <div className="text-sm text-gray-500 text-opacity-80">재질의 버튼 표시하기를 켜면 매 답변시마다 사용자가 추가로 질의할 수 있는
                            버튼을 표시합니다.
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChannelSettingView;
