import React, {useRef, useState} from "react";
import { AnswerPanelChat, ReplyQuestionPanelChat } from "../../model/llmSearchTestPanel/QueryAnswers";
import queryAnswerRepository from "../../respsitory/QueryAnswerRepository";
import toast from "react-hot-toast";
import useSendQuery from "../../query/questionTestPanel/useSendQuery";
import {useIntl} from "react-intl";
import PanelSettingHeader, {PanelSettingHeaderRef} from "./PanelSettingHeader";
import PanelChatList from "./PanelChatList";
import LLMResponseReport from "./LLMResponseReport";
import {Transition} from "@headlessui/react";
import {QueryLog} from "../../model/QueryLog";

const LLMResponseTestPanel: React.FC<QueryInspectPanelProps> = (props) => {
    const {close, channelId} = props;

    const intl = useIntl();

    const [currentDetailChat, setCurrentDetailChat] = useState<AnswerPanelChat | ReplyQuestionPanelChat>();

    const changeDetail = (detail: AnswerPanelChat | ReplyQuestionPanelChat) => {
        setCurrentDetailChat(detail);
    }

    const channelSettingRef = useRef<PanelSettingHeaderRef>(null);

    const {mutateAsync: sendQuery} = useSendQuery();
    const submitQuery = async (query: string) => {
        const testSetting = channelSettingRef.current?.getSetting();

        if (!testSetting) {
            toast.error(intl.formatMessage({id: "i11029"}));
            return;
        }

        try {
            const answer = await sendQuery({query: query, testSetting}) as QueryLog;
            return {
                answer: answer,
                showQuestionReply: channelSettingRef.current!.getShowQuestionReply()!,
                questionContext: answer?.llmGenerated?.result?.context && [...answer?.llmGenerated?.result?.context]
            }
        } catch (e) {
            return;
        }
    };

    const getReplyQuestion = async (query: string, channelId: string) => {
        const testSetting = channelSettingRef.current?.getSetting();

        if (!testSetting) {
            toast.error(intl.formatMessage({id: "i11029"}));
            return;
        }

        try {
            const { data } = await queryAnswerRepository.getReplyQuestion(query, testSetting);
            const queryLog: QueryLog = data.result;
            return queryLog;
        } catch (e) {
            return;
        }
    };

    return (
        <div className={"w-full h-full border-gray-900 border bg-white z-50"}>
            <div className={"w-full h-32"}>
                <PanelSettingHeader ref={channelSettingRef}
                                    close={close}
                                    channelId={channelId}/>
            </div>
            <div className={"w-full h-[calc(100vh-8rem)] grid grid-cols-4"}>
                <div className={"col-span-1 h-full"}>
                    <PanelChatList sendQuery={submitQuery} getReplyQuestion={getReplyQuestion} changeDetail={changeDetail} channelId={channelId} />
                </div>
                <div className={"col-span-3 w-full overflow-y-auto"}>
                    {currentDetailChat &&
                        <LLMResponseReport type={currentDetailChat.type}
                                           queryLog={currentDetailChat.queryLog} />
                    }
                </div>
            </div>
        </div>
    )
}

const QuestionTestButton: React.FC<QuestionTestProps> = (props) => {
    const intl = useIntl();
    const [open, setOpen] = useState<boolean>(false);

    const questionTestRef = useRef<HTMLDivElement>(null);

    const close = () => setOpen(false);

    return (
        <div ref={questionTestRef}
             className={"text-base font-normal whitespace-nowrap break-all"}>
            <button className={"px-5 py-3 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 " +
                "hover:bg-gray-100 hover:text-blue-700 hover:border-indigo-400 " +
                "focus:outline-none focus:z-10 focus:ring-4 focus:ring-gray-200"}
                    onClick={() => setOpen(v => !v)}>
                {intl.formatMessage({id: "i11080"})}
            </button>
            <div className={"w-[calc(100vw-300px)] h-[100vh] fixed top-0 -right-[calc(100vw-300px)] z-50"}>
                <Transition show={open}
                            enter="transition-transform duration-500 ease-in-out"
                            enterFrom="translate-x-0"
                            enterTo="-translate-x-full"
                            leave="transition-transform duration-500 ease-in-out"
                            leaveFrom="-translate-x-full"
                            leaveTo="translate-x-0" >
                        <LLMResponseTestPanel close={close} {...props} />
                </Transition>
            </div>
        </div>
    )
}

export interface QuestionTestProps {
    channelId?: string
}

interface QueryInspectPanelProps extends QuestionTestProps {
    close: () => void
}

export default QuestionTestButton;
