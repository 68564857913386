import {useMutation, useQueryClient} from "@tanstack/react-query";
import {Prompt} from "../../model/prompt/prompt";
import {AxiosError} from "axios";
import promptRepository from "../../respsitory/PromptRepository";


const useDeletePrompt = () => {
    const queryClient = useQueryClient();
    return useMutation<Prompt,AxiosError,string>({
        mutationKey: ["prompt", "delete"],
        mutationFn: async (id: string) => {
            try {
                const {data} = await promptRepository.delete(id);
                return data.result;
            } catch (e) {
                throw e;
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["prompt"],
                exact: false,
            });
        }
    })
};

export default useDeletePrompt;