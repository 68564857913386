import {ChannelResponse} from "../../../model/channel/channel";
import React from "react";
import moment from "moment/moment";


type ChannelMemberViewProps = {
    channel: ChannelResponse
}

const ChannelMemberView: React.FC<ChannelMemberViewProps> = (props) => {
    const {channel} = props;
    // const {data: memberSummaryResponse} = useMember(channel._id);
    return (
        <div className="p-5 space-y-3 text-[16px]">
            <div className="font-bold">고객 정보</div>
            <div className="px-2 space-y-3">
                <div>고객 이름 : {channel.user?.fullName}</div>
                <div>고객 이메일 : {channel.user?.email}</div>
                <div>가입일 : {moment.utc(channel.user?.createdAt).local().format("YYYY-MM-DD HH:mm:ss")}</div>
                <div>최근 접속일 : {moment.utc(channel.user?.accessTime).local().format("YYYY-MM-DD HH:mm:ss")}</div>
                {channel.user?.deleted && channel.user?.deletedAt &&
                    <div>계정 탈퇴일 : {moment.utc(channel.user.deletedAt).local().format("YYYY-MM-DD HH:mm:ss")}</div>
                }
            </div>
        </div>
        // <div className="p-5">
        //     {memberSummaryResponse?.memberSummary.map((member) => {
        //         return (
        //                 <div className="py-3">
        //                     <div className="text-2xl">
        //                         {member.role.name}
        //                     </div>
        //                     이름 : {member.user.fullName} <br/>
        //                     이메일 : {member.user.email}
        //                 </div>
        //         )
        //     })}
        // </div>
    )
}

export default ChannelMemberView;