import axios from "axios";
import {API_HOST} from "../core/variables";

class AnalyticsRepository {
    async getOverallAnalytics() {
        return axios.get(`${API_HOST}/v1/analytics/overall`)
    };

    async getTokenUsage() {
        return axios.get(`${API_HOST}/v1/analytics/token-usage`)
    }

    async getTokenUsageByChannel(channelId: string) {
        return axios.get(`${API_HOST}/v1/analytics/token-usage/${channelId}`)
    }

    async getTotalChannels() {
        return axios.get(`${API_HOST}/v1/analytics/total-channels`)
    }

    async getTotalUsers() {
        return axios.get(`${API_HOST}/v1/analytics/total-users`)
    }

    async getTotalDataSources() {
        return axios.get(`${API_HOST}/v1/analytics/total-data-sources`)
    }

    async getOnboardingBounceRate() {
        return axios.get(`${API_HOST}/v1/analytics/onboarding-bounce-rate`)
    }

    async getOnboardingHistories() {
        return axios.get(`${API_HOST}/v1/analytics/onboarding-histories`)
    }

    async getTotalMessagingUsers() {
        return axios.get(`${API_HOST}/v1/analytics/total-messaging-users`)
    }

    async getTotalMessages() {
        return axios.get(`${API_HOST}/v1/analytics/total-messages`)
    }

    async getIncomingRequestStatusCount() {
        return axios.get(`${API_HOST}/v1/analytics/incoming-request-status-count`)
    }

    async getBasicStatistics(startDate?: string, endDate?: string) {
        return axios.get(`${API_HOST}/v2/analytics/basic-statistics`, {
            params: {
                startDate,
                endDate
            }
        })
    }

    async getUsageStatistics() {
        return axios.get(`${API_HOST}/v2/analytics/usage-statistics`);
    }

    async refreshUsageStatistics() {
        return axios.get(`${API_HOST}/v2/analytics/usage-statistics/refresh`, {
            timeout: 300000
        });
    }
}
const analyticsRepository = new AnalyticsRepository();
export default analyticsRepository;
