import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

interface TooltipProps {
    className?: string
    targetRef: React.MutableRefObject<HTMLElement | null>
    content: React.ReactNode
    disabled?: boolean
}

const Tooltip: React.FC<TooltipProps> = (props) => {
    const {targetRef, content, className, disabled = false} = props;

    const [show, setShow] = useState<boolean>(false);
    const [targetHover, setTargetHover] = useState<boolean>(false);
    const [tooltipHover, setTooltipHover] = useState<boolean>(false);

    const _className = useMemo(() => {
        const general = "tooltip z-50 absolute"
        const position = "top-14 left-0"
        const text = "whitespace-normal text-sm font-medium text-white"
        const style = "px-3 py-2 bg-gray-900 rounded-lg shadow-sm";
        const animation = "transition-opacity duration-300 "

        return classNames(
            general,
            position,
            text,
            style,
            animation, {
                [className ?? '']: className,
                "opacity-100" : show && !disabled,
                "transition-z-0 pointer-events-none opacity-0": !show || disabled
            }
        )
    }, [show, className, disabled]);

    // show tooltip when target or tooltip is in hover
    useEffect(() => {
        if (targetHover || tooltipHover) {
            setShow(true);
        } else {
            setShow(false);
        }
    }, [targetHover, tooltipHover])

    // set target is in hover or not
    useEffect(() => {
        const open = () => {
            setTargetHover(true);
        }
        const close = () => {
            setTargetHover(false);
        }

        const target = targetRef.current;

        if (target) {
            target.addEventListener('mouseover', open);
            target.addEventListener('mouseout', close);
        }

        return () => {
            if (target) {
                target.removeEventListener('mouseover', open);
                target.removeEventListener('mouseout', close);
            }
        }
    }, [targetRef]);

    // set tooltip is in hover or not
    const onMouseOver = () => {
        if (show) {
            setTooltipHover(true);
        }
    }

    const onMouseOut = () => {
        if (show) {
            setTooltipHover(false);
        }
    }

    return (
        <TooltipStyle>
            <div className={_className} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
                {content}
            </div>
        </TooltipStyle>
    );
}

const TooltipStyle = styled.div`
  // z index animation
  .transition-z-0 {
    animation: zIndexDelay .3s step-end forwards;
  }

  @keyframes zIndexDelay {
    to { z-index: 0; }
  }
  
  .tooltip:before {
    content: '';
    position: absolute;
    border-style: solid;
    border-color: black transparent;
    border-width: 0 7px 12px 7px;
    top: -12px;
    left: Calc(50% - 7px);
    -webkit-filter: drop-shadow(0 4px 3px rgba(0,0,0,.15));
    filter: drop-shadow(0 4px 3px rgba(0,0,0,.15));
  }
`

export default Tooltip;
