import axios from "axios";
import {API_HOST} from "../core/variables";
import {UserSearchType} from "../model/User";

class UserRepository {
    async find(pageNum: number, size?: number, searchType?: UserSearchType, searchKeyword?: string) {
        return axios.get(`${API_HOST}/v1/user`, {
            params: {
                page: pageNum,
                size: size,
                search_type: searchType,
                search_keyword: searchKeyword,
            }
        })
    }
    async findChannelsByUserId(userId: string, pageNum: number, size?: number) {
        return axios.get(`${API_HOST}/v1/user/channels/${userId}`, {
            params: {
                page: pageNum,
                size: size
            }
        })
    }

    async read(id: string) {
        return axios.get(`${API_HOST}/v1/user/${id}`)
    }

    async deleteUser(userId: string) {
        return axios.delete(`${API_HOST}/v1/user/${userId}`)
    }

    async hardDeleteUser(userId: string) {
        return axios.delete(`${API_HOST}/v1/user/${userId}/hard-delete`)
    }

    async restoreUser(userId: string) {
        return axios.put(`${API_HOST}/v1/user/${userId}/restore`)
    }
}

const userRepository = new UserRepository();

export default userRepository;