import {useMutation, useQueryClient} from "@tanstack/react-query";
import {QueryLog} from "../../model/QueryLog";
import {AxiosError} from "axios";
import queryLogRepository from "../../respsitory/QueryLogRepository";

const useDeleteQueryLog = () => {
    const queryClient = useQueryClient();
    return useMutation<QueryLog, AxiosError, string>({
        mutationKey: ["queryLog", "delete"],
        mutationFn: async (id: string) => {
            try {
                const {data} = await queryLogRepository.delete(id)
                return data.result
            } catch (e) {
                throw e;
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["queryLog"],
                exact: false
            })
        }
    })
}

export default useDeleteQueryLog;
