import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosError} from "axios";
import { ChannelSetting, ChannelSettingUpdate } from "../../model/channel/channel";
import channelRepository from "../../respsitory/ChannelRepository";

const useUpdateChannelSetting = (channelId: string) => {
    const queryClient = useQueryClient();

    return useMutation<ChannelSetting, AxiosError, ChannelSettingUpdate>({
        mutationKey: ["channel", channelId, "setting", "update"],
        mutationFn: async (body: ChannelSettingUpdate) => {
            try {
                const { data } = await channelRepository.updateSetting(channelId, body);
                return data.result;
            } catch (e) {
                throw e;
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(['channel', channelId], {exact: true});
        }
    })
};

export default useUpdateChannelSetting;