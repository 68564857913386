import ChannelCommonSettingRepository from "../../respsitory/ChannelCommonSettingRepository";
import {useQuery} from "@tanstack/react-query";
import {ChannelCommonSettingHistory} from "../../model/ChannelCommonSettingHistory";

const getData = async (channel_common_setting_id?: string) => {
    try {
        const {data} = await ChannelCommonSettingRepository.findHistory(channel_common_setting_id);
        return data.result
    } catch (e) {
        throw e;
    }
}

const useChannelCommonSettingHistory = (channel_common_setting_id?: string) => {
    return useQuery<ChannelCommonSettingHistory[], Error>({
        queryKey: ['channelCommonSettingHistory', channel_common_setting_id],
        queryFn: async () => getData(channel_common_setting_id),
        retry: false,
        suspense: false,
    })
}

export default useChannelCommonSettingHistory;
