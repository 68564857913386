import {useMutation, useQueryClient} from "@tanstack/react-query";
import {
    GlobalVariableGroup,
} from "../../model/globalVariable/GlobalVariableGroup";
import {AxiosError} from "axios";
import GlobalVariableGroupRepository from "../../respsitory/GlobalVariableGroupRepository";

const useDeleteGlobalVariableGroup = () => {
    const queryClient = useQueryClient();
    return useMutation<GlobalVariableGroup, AxiosError, string>({
        mutationKey: ["globalVariableGroup", "delete"],
        mutationFn: async (id: string) => {
            try {
                const {data} = await GlobalVariableGroupRepository.delete(id);
                return data.result
            } catch (e) {
                throw e;
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["globalVariableGroup"],
                exact: false
            })
        }
    })
}

export default useDeleteGlobalVariableGroup;
