import {SearchType} from "../respsitory/ChannelRepository";
import {ChannelResponse} from "./channel/channel";
import {HardDeletionMarkable} from "./index";

export enum UserSearchTypeEnum {
    EMAIL = 'email',
    USERNAME = 'user_name'
}

export type UserSearchType = UserSearchTypeEnum.EMAIL | UserSearchTypeEnum.USERNAME

export const isUserSearchType = (text?: unknown): text is SearchType => {
    return (text === UserSearchTypeEnum.EMAIL || text === UserSearchTypeEnum.USERNAME)
}

interface UserBase {
    username: string;
    email: string;
    fullName: string;
}

export interface User extends UserBase, HardDeletionMarkable {
    _id: string;
    createdAt: string;
    updatedAt: string;
    deleted: boolean;
    deletedAt?: string
    accessTime?: string;
}

export interface UserResponse {
    user: User;
    channelCount: number;
}

export interface UserResponsePage {
    userResponse: UserResponse[];
    count: number;
}

export interface ChannelResponsePage {
    channels: ChannelResponse[]
    count: number
}
