import React, {useCallback, useEffect, useState} from "react";
import KeySelectionMenu from "../../../component/channel/KeySelectionMenu";
import { useIntl } from "react-intl";
import useChannelCommonSetting from "../../../query/channelCommonSetting/useChannelCommonSetting";
import {ChannelCommonSetting} from "../../../model/ChannelCommonSetting";
import {ChannelSetting, ChannelSettingUpdate} from "../../../model/channel/channel";

export type ChannelSettingPresetProps = {
    defaultSetting: ChannelSetting
    settingRef: React.MutableRefObject<ChannelSettingUpdate>
}

const ChannelSettingPreset:React.FC<ChannelSettingPresetProps> = (props) => {
    const intl = useIntl();
    const {defaultSetting, settingRef} = props;


    const { data: channelCommonSettings } = useChannelCommonSetting();
    const selectedChannelCommonSetting = channelCommonSettings?.filter(setting => setting._id === defaultSetting.channelCommonSettingId)[0]
    const [selectedSpecificationKey, setSelectedSpecificationKey] = useState<string>(selectedChannelCommonSetting?.key ?? "");


    const onClickSpecification = (id: string, key: string) => {
        setSelectedSpecificationKey(key);
        settingRef.current = ({
            ...settingRef.current,
            channelCommonSettingId: id
        })
    }
    useEffect(() => {
        settingRef.current = ({
            ...settingRef.current,
            channelCommonSettingId: defaultSetting.channelCommonSettingId
        })
    },[defaultSetting, settingRef])
    useEffect(() => {
        if (selectedChannelCommonSetting?.key) {
            setSelectedSpecificationKey((ov) => ov || selectedChannelCommonSetting.key)
        }
    }, [selectedChannelCommonSetting])

    const LLMMenuItem = useCallback((item: ChannelCommonSetting) => {
        return (
            <div className="flex flex-col space-y-2 justify-center ">
                <div className={"flex justify-between items-center space-x-2"}>
                    <div className={"font-bold"}>{item.key}</div>
                    <div className={"flex space-x-2"}>
                        {item.default &&
                            <div className="px-3.5 text-sm font-semibold shadow-sm rounded-md border bg-white text-yellow-500 border-yellow-500 h-8 text-center flex items-center">
                                {intl.formatMessage({id: "i10019"})}
                            </div>
                        }
                        {item._id === defaultSetting.channelCommonSettingId &&
                            <div className="px-3.5 text-sm font-semibold shadow-sm rounded-md border bg-white text-blue-400 border-blue-400 h-8 text-center flex items-center">
                                {intl.formatMessage({id: "i10021"})}
                            </div>
                        }
                    </div>
                </div>
                <div className={"flex space-x-4"}>
                    {/*<div>{`${intl.formatMessage({id: "i10030"})}: ${item.llmSpecificationId}`}</div>*/}
                    <div>{`${intl.formatMessage({id: "i10029"})}: ${item.searchSpecificationId}`}</div>
                </div>
                <div className="flex break-keep">
                    <div>{`${intl.formatMessage({id: "i10015"})}: `}</div>
                    <div className="w-full text-left ml-2">
                        {item.description ?? intl.formatMessage({id: "i10016"})}
                    </div>
                </div>
            </div>
        )
    }, [intl, defaultSetting.channelCommonSettingId]);

    return (
        <div className="m-5 flex space-x-5">
            <div className="w-full flex flex-col space-y-2">

                <div className="flex items-center justify-between">
                    <p className="text-xl pl-2">{intl.formatMessage({id: "i10017"})}</p>
                </div>

                <KeySelectionMenu
                    channelCommonSettings={channelCommonSettings?.filter(setting => setting.searchEngineType === selectedChannelCommonSetting?.searchEngineType) ?? []}
                    selectedKey={selectedSpecificationKey}
                    onClickItem={onClickSpecification}
                    itemNode={LLMMenuItem}
                />
            </div>

        </div>
    )
}


export default ChannelSettingPreset;
