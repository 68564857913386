import React from "react";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import classNames from "classnames";
import {User} from "../../model/User";

type UserRowProps = {
    user: User
}

const UserRow: React.FC<UserRowProps> = (props) => {
    const {user} = props;
    const navigate = useNavigate();

    const onClickButton = () => {
        navigate(user._id);
    }

    if (!user) return <></>;
    return (
        <tr key={user._id}
            className={classNames(
                "border-b-2 h-16 text-[14px]",
                user.deleted ? "bg-gray-200" : "hover:bg-gray-100"
            )}
        >
            <td className="p-2.5 font-medium">
                <div className="flex items-center space-x-3">
                    <button className="hover:underline"
                            onClick={onClickButton}>
                        {user.fullName}
                    </button>
                    <p className="text-red-500">{user.deleted && "(삭제됨)"}</p>
                </div>
            </td>
            <td className="p-2.5">
                {user.email}
            </td>
            <td className="p-2.5">
                <div>
                    가입 일자 : {moment.utc(user.createdAt).local().format("YYYY-MM-DD HH:mm:ss")}
                </div>
                {user.deleted && user.deletedAt &&
                    <div>
                        탈퇴 일자 : {moment.utc(user.deletedAt).local().format("YYYY-MM-DD HH:mm:ss")}
                    </div>
                }
            </td>
            <td className="p-2.5">
                {user.accessTime ?
                    moment.utc(user.accessTime).local().format("YYYY-MM-DD HH:mm:ss")
                    :
                    "-"
                }
            </td>
        </tr>
    )
}

export default UserRow;