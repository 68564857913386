import {useNavigate, useParams} from "react-router-dom";
import React, {useCallback, useMemo, useRef} from "react";
import useUpdateChannelSetting from "../../../query/channel/useUpdateChannelSetting";
import {ChannelSettingUpdate} from "../../../model/channel/channel";
import toast from "react-hot-toast";
import {useIntl} from "react-intl";
import QuestionTestButton from "../../../component/LLMResponseTestPanel/LLMResponseTestPanel";
import ChannelSettingView from "./ChannelSettingView";
import {Tab} from "@headlessui/react";
import ChannelSettingVariableView from "./ChannelSettingVariableView";
import {DictType} from "../../../model";
import ChannelInfo from "./ChannelInfo";
import {LLMResponseQueryLogList} from "../../../component/queryLog/LLMResponseQueryLogList";
import ChannelMemberView from "./ChannelMemberView";
import ChannelTokenUsageView from "./ChannelTokenUsageView";
import useChannel from "../../../query/channel/useChannel";
import {ChevronLeftIcon} from "@heroicons/react/24/outline";
import ChannelDeleteAndRestoreTab from "./ChannelDeleteAndRestoreTab";


const ChannelDetailTab: React.FC = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const tabList = useMemo(() => [
        {id: 1, title: "채널", disabled: false},
        // {id: 2, title: "구독 기록", disabled: false},
        {id: 3, title: "사용량/결제", disabled: false},
        {id: 4, title: "고객", disabled: false},
        {id: 5, title: "설정", disabled: false},
        {id: 6, title: "변수", disabled: false},
        {id: 7, title: "테스트 로그", disabled: false},
        {id: 8, title: "삭제 및 복구", disabled: false},
    ], [])
    const tabClassName = (selected: boolean) => {
        return selected
            ? "transition-colors duration-200 text-gray-500 pb-3 focus:outline-none text-gray-900 border-b border-black font-bold"
            : "transition-colors duration-200 text-gray-500 pb-3 focus:outline-none"
    }
    const {channelId} = useParams() as { channelId: string };
    const {data: channelResponse} = useChannel(channelId)
    const channel = useMemo(() => channelResponse?.channel, [channelResponse?.channel]);
    const {mutateAsync: updateSetting} = useUpdateChannelSetting(channelId);
    const channelParamRefs = useRef<{ key: HTMLInputElement | null, value: HTMLInputElement | null }[]>([]);
    const settingRef = useRef<ChannelSettingUpdate>({});

    const onClickSave = useCallback(
        async () => {
            try {
                const validModelParams: { key: string, value: string }[] = [];
                channelParamRefs.current.forEach((ref) => {
                    if (ref.key?.value && ref.value?.value) {
                        validModelParams.push({key: ref.key.value, value: ref.value.value});
                    }
                })

                const variables = validModelParams.reduce((result, param) => {
                    result[param.key] = param.value;
                    return result
                }, {} as DictType<any>)

                const channelSetting = {variables: variables, ...settingRef.current}
                await updateSetting(channelSetting);
                toast.success(intl.formatMessage({id: "i10011"}))
            } catch (e: any) {
                const error = e.response.data.detail.code
                if (error === "searchSpecification") {
                    toast.error("검색 설정의 엔진타입은 변경할 수 없습니다.")
                } else {
                    toast.error(intl.formatMessage({id: "i10012"}))
                }
            }
        }, [intl, updateSetting]);

    const onClickBack = (e: React.MouseEvent) => {
        e.preventDefault();
        navigate(-1);
    };

    return (
        <div className={"flex flex-col h-full"}>
            {channel && (
                <div>
                    <div className="m-5 pb-5 border-b-2 flex justify-between">
                        <div className={"flex items-center text-xl font-semibold space-x-5"}>
                            <button  onClick={onClickBack}>
                                <ChevronLeftIcon className="w-6 h-6"/>
                            </button>
                            <div>{`${intl.formatMessage({id: "i10013"})}: ${channel.name}`}</div>
                            {channel.deleted &&
                                <div className="text-red-400 text-sm">
                                    {channel.hardDeleted ? "영구 삭제된 채널" : "삭제된 채널"}
                                </div>
                            }
                        </div>
                    </div>

                    <div className="flex flex-col h-full w-full items-center ">
                        <div className={"w-1/2 flex flex-col gap-5"}>
                            <div className={"flex flex-col border-2 border-gray-300 rounded-2xl"}>
                                <div className="m-5 flex space-x-5">
                                    <div className="w-full flex flex-col space-y-2">
                                        <div className="flex items-center justify-between">
                                            <p className="text-xl font-bold pl-2">{intl.formatMessage({id: "i10013"})}</p>
                                            <div className="flex space-x-3">
                                                <QuestionTestButton channelId={channel._id} />
                                                <button className="flex items-center border rounded-md px-5 hover:bg-gray-100
                                                hover:text-blue-700 hover:border-indigo-400 focus:outline-none focus:z-10 focus:ring-4 focus:ring-gray-200" onClick={onClickSave}>
                                                    {intl.formatMessage({id: "i10014"})}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Tab.Group defaultIndex={0}>
                                    <Tab.List className="bg-white px-10 h-auto flex border-b justify-between">
                                        {tabList.map(tab => (
                                            <Tab key={tab.id}
                                                 disabled={tab.disabled}
                                                 className={({selected}) => tabClassName(selected)}>
                                                {tab.title}
                                            </Tab>
                                        ))}
                                    </Tab.List>
                                    <Tab.Panels className="flex-grow">
                                        <Tab.Panel>
                                            <ChannelInfo channel={channel}/>
                                        </Tab.Panel>
                                        {/*<Tab.Panel className="h-full p-5">*/}
                                        {/*    <ChannelSubscriptionHistory channel={channel}/>*/}
                                        {/*</Tab.Panel>*/}
                                        <Tab.Panel className="h-full p-15">
                                            <ChannelTokenUsageView channel={channel}/>
                                        </Tab.Panel>
                                        <Tab.Panel className="h-full p-5">
                                            <ChannelMemberView channel={channel}/>
                                        </Tab.Panel>
                                        <Tab.Panel className="h-full p-5">
                                            <ChannelSettingView channel={channel} settingRef={settingRef}/>
                                        </Tab.Panel>
                                        <Tab.Panel className="h-full p-5">
                                            <ChannelSettingVariableView channel={channel} settingRef={settingRef}
                                                                        channelParamRefs={channelParamRefs}/>
                                        </Tab.Panel>
                                        <Tab.Panel className="h-full p-5">
                                            <LLMResponseQueryLogList channel={channel}/>
                                        </Tab.Panel>

                                        <Tab.Panel className="h-full p-5">
                                            <ChannelDeleteAndRestoreTab channel={channel} />
                                        </Tab.Panel>
                                    </Tab.Panels>
                                </Tab.Group>
                            </div>
                        </div>

                    </div>
                </div>
            )}

        </div>
    );
};

export default ChannelDetailTab;
