import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {ChannelGroup} from "../../model/channelGroup/channelGroup";
import ChannelGroupRepository from "../../respsitory/ChannelGroupRepository";

const useDeleteChannelGroup = () => {
    const queryClient = useQueryClient();
    return useMutation<ChannelGroup, AxiosError, string>({
        mutationKey: ["channelGroup", "delete"],
        mutationFn: async (id: string) => {
            try {
                const {data} = await ChannelGroupRepository.delete(id)
                return data.result
            } catch (e) {
                throw e;
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["channelGroup"],
                exact: false
            })
        }
    })
}

export default useDeleteChannelGroup;
