import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./ErrorFallback";
import { Outlet } from "react-router-dom";

const ErrorBoundaryLayout: React.FC = () => {
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Outlet />
        </ErrorBoundary>
    );
};

export default ErrorBoundaryLayout;
