import {useQuery} from "@tanstack/react-query";
import * as authRepository from "../../respsitory/AuthRepository"
import {Profile} from "../../model/Profile";
import {SiteBunnyAdminError, toSiteBunnyAdminError} from "../../error";

const getData = async () => {
    try {
        const { data } = await authRepository.me();
        return data.result;
    } catch (e) {
        throw toSiteBunnyAdminError(e);
    }
}

const DEFAULT_QUERY_OPTIONS = {retry: false, suspense: false, refetchOnMount: false, refetchOnWindowFocus: false}

const useProfile = (props: {useErrorBoundary?: boolean} = {}) => {
    const options = {...DEFAULT_QUERY_OPTIONS, ...props}
    return useQuery<Profile, SiteBunnyAdminError>({
        queryKey: ['profile'],
        queryFn: async () => getData(),
        ...options
    })
}

export default useProfile;