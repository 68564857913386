import {useMutation, useQueryClient} from "@tanstack/react-query";
import {ChannelCommonSetting, ChannelCommonSettingCreateRequest} from "../../model/ChannelCommonSetting";
import {AxiosError} from "axios";
import channelCommonSettingRepository from "../../respsitory/ChannelCommonSettingRepository";

const useCreateChannelCommonSetting = () => {
    const queryClient = useQueryClient();

    return useMutation<ChannelCommonSetting, AxiosError, ChannelCommonSettingCreateRequest>({
        mutationKey: ["channelCommonSetting","create"],
        mutationFn: async (body: ChannelCommonSettingCreateRequest) => {
            try {
                const {data} = await channelCommonSettingRepository.create(body);
                return data.result
            } catch (e) {
                throw e;
            }
        },
        onSuccess: async () =>{
            await queryClient.invalidateQueries({
                queryKey: ["channelCommonSetting"],
                exact: false
            })
        }
    })
};

export default useCreateChannelCommonSetting;