import {useInfiniteQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {SubscriptionTransaction} from "../../model/transaction/subscriptionTransaction";
import subscriptionTransactionRepository from "../../respsitory/SubscriptionTransactionRepository";
import {Page} from "../index";

const getData = async (
    pageable: {
        skip: number,
        limit: number,
    },
    channelName?: string,
    planName?: string,
    startDate?: string,
    endDate?: string,
) => {
    try {
        const { data } = await subscriptionTransactionRepository.find(
            channelName,
            planName,
            startDate,
            endDate,
            pageable.skip,
            pageable.limit
        );

        return {
            result: data.result,
            pageable: {
                ...pageable,
                isLast: data.result.length < pageable.limit
            }
        }
    } catch (e) {
        throw e;
    }
}

const useSubscriptionTransactions = (
    channelName?: string,
    planName?: string,
    startDate?: string,
    endDate?: string,
) => {
    return useInfiniteQuery<Page<SubscriptionTransaction>, AxiosError>({
        queryKey: ["subscription-transactions", channelName, planName, startDate, endDate],
        queryFn: async ({ pageParam = { skip: 0, limit: 20, isLast: false }}) => getData(
            pageParam,
            channelName,
            planName,
            startDate,
            endDate,
        ),
        retry: false,
        suspense: false,
        getNextPageParam: ({ pageable, result }) => {
            if (pageable.isLast) {
                return undefined
            }

            return {
                skip: pageable.skip + result.length,
                limit: pageable.limit,
                isLast: false
            }
        }
    })
}

export default useSubscriptionTransactions;
