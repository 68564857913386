import ChannelCommonSettingRepository from "../../respsitory/ChannelCommonSettingRepository";
import {SettingResponse} from "../../model/ChannelCommonSetting";
import {useQuery} from "@tanstack/react-query";

const getData = async () => {
    try {
        const {data} = await ChannelCommonSettingRepository.findSetting();
        return data.result
    } catch (e) {
        throw e;
    }
}

const useSettings = () => {
    return useQuery<SettingResponse, Error>({
        queryKey: ['settings'],
        queryFn: async () => getData(),
        retry: false,
        suspense: false,
    })
}

export default useSettings;
