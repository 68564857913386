import React, {useCallback, useMemo, useRef, useState} from "react";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import _ from "lodash";
import classNames from "classnames";
import {useIntl} from "react-intl";
import PromptSettingForm from "../../component/prompt/promptSettingForm";
import usePrompt from "../../query/prompt/usePrompt";
import {Prompt, PromptBase, PromptType, PromptTypeList} from "../../model/prompt/prompt";
// import PromptGuide from "../../component/prompt/PromptGuide";

const PromptSettingView: React.FC = () => {
    const intl = useIntl();
    const [searchKey, setSearchKey] = useState<string>();

    const [searchType, setSearchType] = useState<PromptType | undefined>();

    const onChangeSearchType = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value === "all") {
            setSearchType(undefined);
        } else {
            setSearchType(e.target.value as PromptType);
        }
    }

    const {data: prompts} = usePrompt(searchKey, searchType);

    const [showForm, setShowForm] = useState(false);
    const [selectedPromptId, setSelectedPromptId] = useState<string>();
    const [copyPrompt, setCopyPrompt] = useState<PromptBase>();

    const searchTypeRef = useRef<HTMLSelectElement>(null);

    const prompt = useMemo(() => {
        return prompts?.find(prompt => prompt._id === selectedPromptId);
    }, [prompts, selectedPromptId]);

    const hideForm = useCallback(() => {
        setSelectedPromptId(undefined);
        setShowForm(false);
    }, [setSelectedPromptId, setShowForm]);

    const onChangeSearch = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchKey(e.target.value);
        hideForm();
    }, 300);

    const onClickPrompt = (prompt: Prompt) => {
        setSelectedPromptId(prompt._id)
        setShowForm(true);
    }

    const onClickAddPrompt = () => {
        setSelectedPromptId(undefined);
        setCopyPrompt(undefined);
        setShowForm(true);
    }

    const onClickCopyPrompt = (prompt: PromptBase) => {
        setCopyPrompt(prompt);
        setSelectedPromptId(undefined);
        setShowForm(true);
    }

    const onClickCancelPrompt = () => {
        hideForm();
    }

    const onClickDeletePrompt = () => {
        hideForm();
    }

    const onClickSubmitPrompt = (specificationId: string) => {
        setSelectedPromptId(specificationId);
    }

    const getPromptTypeName = useCallback((promptType: string) => {
        return PromptTypeList.find(item => item.type === promptType)?.name;
    }, [])

    return (
        <div className="flex flex-col h-full">
            {/* Title */}
            <div className="m-5 text-2xl px-2 pb-5 border-b-2 font-semibold">
                프롬프트 관리
            </div>
            <div className="m-5 flex flex-col h-full">
                {/* Content */}
                <div className="flex space-x-5">
                    <div className="w-[30%] flex flex-col space-y-2">
                        <div className="flex items-center justify-between">
                            <p className="text-xl pl-2">프롬프트 목록</p>
                            <button className="btn btn-info btn-sm" onClick={onClickAddPrompt}>
                                {intl.formatMessage({id: "i0204"})}
                            </button>
                        </div>

                        {/* Search bar */}
                        <div className="relative flex">
                            <select className="mr-2"
                                    ref={searchTypeRef}
                                    onChange={onChangeSearchType}>
                                <option value="all">모든 프롬프트</option>
                                {PromptTypeList.map((item) => {
                                    const {name, type} = item;
                                    return <option key={type} value={type}>{name}</option>
                                })}
                            </select>
                            <div className="w-full">
                                <div className="absolute inset-y-0 flex items-center pl-3 pointer-events-none">
                                    <MagnifyingGlassIcon className="w-5 h-5"/>
                                </div>
                                <input type="search"
                                       id="search"
                                       className="form-input pl-10"
                                       placeholder="Search by key"
                                       onChange={onChangeSearch}
                                />
                            </div>
                        </div>

                        {/* List */}
                        <div className="flex-grow space-y-2 overflow-y-auto max-h-[1800px]">
                            {prompts?.map((prompt) => {
                                const description = prompt.description === "" ? "설명 없음" : (prompt?.description ?? "설명 없음")
                                return (
                                    <div key={prompt.key}
                                         className={classNames(
                                             "border border-gray-600 rounded-lg px-5 py-3 cursor-pointer transition-colors duration-200 hover:bg-gray-200",
                                             selectedPromptId === prompt._id && "bg-gray-300"
                                         )}
                                         onClick={() => onClickPrompt(prompt)}>

                                        <div className="flex items-center justify-between space-x-2">
                                            <p className="text-lg truncate">{prompt.key}</p>
                                            {prompt.dependency &&
                                                <div
                                                    className="px-3 py-1 rounded-md bg-blue-400 text-white text-sm whitespace-nowrap">
                                                    사용중
                                                </div>
                                            }
                                        </div>
                                        <p className="text text-gray-600">{getPromptTypeName(prompt.type)}</p>
                                        <p className="text-sm text-gray-500">{description}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="flex-grow space-y-2">
                        {showForm &&
                            <PromptSettingForm
                                key={`specification-setting-form-${prompt?.key}`}
                                prompt={prompt}
                                copyPrompt={copyPrompt}
                                onCancel={onClickCancelPrompt}
                                onDelete={onClickDeletePrompt}
                                onSubmit={onClickSubmitPrompt}
                                onClickCopyPrompt={onClickCopyPrompt}
                            />
                        }
                        {/*<PromptGuide/>*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PromptSettingView;
