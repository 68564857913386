import {API_HOST} from "../core/variables";
import axios from "axios";

class SubscriptionTransactionRepository {
    async read(id: string) {
        return axios.get(`${API_HOST}/v1/subscription-transaction/${id}`)
    }

    async find(
        channelName?: string,
        planName?: string,
        startDate?: string,
        endDate?: string,
        skip?: number,
        limit?: number
    ) {
        return axios.get(`${API_HOST}/v1/subscription-transaction`, {
            params: {
                channelName,
                planName,
                startDate,
                endDate,
                skip,
                limit
            }
        })
    }

    async downloadCSV(
        channelName?: string,
        planName?: string,
        startDate?: string,
        endDate?: string,
    ) {
        return axios.get(`${API_HOST}/v1/subscription-transaction/export`, {
            params: {
                channelName,
                planName,
                startDate,
                endDate,
            }
        })
    }
}

const subscriptionTransactionRepository = new SubscriptionTransactionRepository();

export default subscriptionTransactionRepository;
