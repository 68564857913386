import {Tab} from "@headlessui/react";
import React, {Dispatch, useMemo} from "react";
import {Agent, LLMTypeList} from "../../model/prompt/prompt";
import {SearchEngineType} from "../../model/searchSpecification";
import classNames from "classnames";
import useSettings from "../../query/channelCommonSetting/useSettings";
import AgentContent from "../channelCommonSetting/AgentContent";

type PromptTabProps = {
    selectedSearchEngine: SearchEngineType;
    agentValidateCheck: boolean;
    allAgents: {
        welcomeAgent: Agent;
        analyzeKoreanAgent: Agent;
        analyzeEnglishAgent: Agent;
        contextQuestionAgent: Agent;
        welcomeQuestionAgent: Agent;
        mrcAnswerAgent: Agent;
        passageAnswerAgent: Agent;
        contextAnswerAgent: Agent;
        channelCuratingAgent: Agent;
    };
    allSetAgents: {
        setWelcomeAgent: React.Dispatch<React.SetStateAction<Agent>>,
        setAnalyzeKoreanAgent: React.Dispatch<React.SetStateAction<Agent>>,
        setAnalyzeEnglishAgent: React.Dispatch<React.SetStateAction<Agent>>,
        setContextQuestionAgent: React.Dispatch<React.SetStateAction<Agent>>,
        setWelcomeQuestionAgent: React.Dispatch<React.SetStateAction<Agent>>,
        setMrcAnswerAgent: React.Dispatch<React.SetStateAction<Agent>>,
        setPassageAnswerAgent: React.Dispatch<React.SetStateAction<Agent>>,
        setContextAnswerAgent: React.Dispatch<React.SetStateAction<Agent>>,
        setChannelCuratingAgent: React.Dispatch<React.SetStateAction<Agent>>
    }
}

const SettingsTab: React.FC<PromptTabProps> = (props) => {
    const {
        selectedSearchEngine,
        agentValidateCheck,
        allAgents,
        allSetAgents
    } = props;
    const tabList = useMemo(() => [
        {id: 1, title: "요약", disabled: false},
        {id: 2, title: "질의 분석", disabled: false},
        {id: 3, title: "질의 생성", disabled: false},
        {id: 4, title: "답변 생성", disabled: false},
        {id: 5, title: "큐레이팅", disabled: false},
    ], [])
    const tabClassName = (selected: boolean) => {
        return selected
            ? "transition-colors duration-200 text-gray-500 pb-3 focus:outline-none text-gray-900 border-b border-black"
            : "transition-colors duration-200 text-gray-500 pb-3 focus:outline-none"
    }

    // LLM, Prompt 데이터 가져오기
    const {data: settings} = useSettings();
    const llms = useMemo(() => {
        return settings?.llms;
    }, [settings])
    const prompts = useMemo(() => {
        return settings?.prompts;
    }, [settings])

    const onChangeAgent = (e: React.ChangeEvent<HTMLSelectElement>,
                           agent: Agent,
                           setAgent: Dispatch<React.SetStateAction<Agent>>) => {
        const {id, value} = e.target;
        if (id === "llmId") {
            const llmModel = llms?.find(item => item._id === value)?.model;
            const selectedLLMType = llmModel && LLMTypeList.find(item => item.model.includes(llmModel))?.type;
            if (selectedLLMType && selectedLLMType!== agent.llmType) {
                // LLMType이 변경될경우 설정된 Prompt를 초기화하고, 보여주는 Prompt 리스트를 변경[LLMType 변경]
                agent.promptId = "";
                agent.llmType = selectedLLMType;
            }
        }
        const newAgent = {...agent, [id]: value};
        setAgent(newAgent);
    }

    return (
        <div className={classNames("border border-gray-400 rounded-md shadow-lg p-5 pb-7", {"border-red-400 border-4": agentValidateCheck})}>
            <div className="text-xl font-semibold mb-3 border-b-2 pb-3">
                <div className="flex items-center justify-between">
                    <div className={"flex justify-between items-center"}>
                        <p>에이전트 설정</p>
                    </div>
                </div>
            </div>
            <Tab.Group defaultIndex={0}>
                <Tab.List className="bg-white h-auto flex space-x-7 border-b">
                    {tabList.map(tab => (
                        <Tab key={tab.id}
                             disabled={tab.disabled}
                             className={({selected}) => tabClassName(selected)}>
                            {tab.title}
                        </Tab>
                    ))}
                </Tab.List>
                <Tab.Panels>
                    <Tab.Panel>
                        <div>
                            <AgentContent title={"월컴 메세지 생성"} llms={llms} prompts={prompts?.welcome}
                                          agent={allAgents.welcomeAgent} setAgent={allSetAgents.setWelcomeAgent}
                                          onChangeAgent={onChangeAgent}/>
                        </div>
                    </Tab.Panel>
                    <Tab.Panel>
                        <div>
                            <AgentContent title={"한글 질의 분석"} llms={llms} prompts={prompts?.analyzeKoreanQuery}
                                          agent={allAgents.analyzeKoreanAgent} setAgent={allSetAgents.setAnalyzeKoreanAgent}
                                          onChangeAgent={onChangeAgent}/>
                            <AgentContent title={"영어 질의 분석"} llms={llms} prompts={prompts?.analyzeEnglishQuery}
                                          agent={allAgents.analyzeEnglishAgent} setAgent={allSetAgents.setAnalyzeEnglishAgent}
                                          onChangeAgent={onChangeAgent}/>
                        </div>
                    </Tab.Panel>
                    <Tab.Panel>
                        <div>
                            <AgentContent title={"월컴 추천 질의 생성"} llms={llms} prompts={prompts?.welcomeQuestion}
                                          agent={allAgents.welcomeQuestionAgent} setAgent={allSetAgents.setWelcomeQuestionAgent}
                                          onChangeAgent={onChangeAgent}/>
                            <AgentContent title={"후속 질의 생성"} llms={llms} prompts={prompts?.contextQuestion}
                                          agent={allAgents.contextQuestionAgent} setAgent={allSetAgents.setContextQuestionAgent}
                                          onChangeAgent={onChangeAgent}/>
                        </div>
                    </Tab.Panel>
                    <Tab.Panel>
                        <div>
                            <AgentContent title={"대화이력 기반 답변 생성"} llms={llms} prompts={prompts?.contextAnswer}
                                          agent={allAgents.contextAnswerAgent} setAgent={allSetAgents.setContextAnswerAgent}
                                          onChangeAgent={onChangeAgent}/>
                            <AgentContent title={"Passage 답변 생성"} llms={llms} prompts={prompts?.passageAnswer}
                                          agent={allAgents.passageAnswerAgent} setAgent={allSetAgents.setPassageAnswerAgent}
                                          onChangeAgent={onChangeAgent}/>
                            {
                                selectedSearchEngine === SearchEngineType.QA42 &&
                                <AgentContent title={"MRC 답변 생성"} llms={llms} prompts={prompts?.mrcAnswer}
                                              agent={allAgents.mrcAnswerAgent} setAgent={allSetAgents.setMrcAnswerAgent}
                                              onChangeAgent={onChangeAgent}/>
                            }
                        </div>
                    </Tab.Panel>
                    <Tab.Panel>
                        <div>
                            <AgentContent title={"채널 큐레이팅"} llms={llms} prompts={prompts?.channelCurating}
                                          agent={allAgents.channelCuratingAgent} setAgent={allSetAgents.setChannelCuratingAgent}
                                          onChangeAgent={onChangeAgent}/>
                        </div>
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    )
}

export default SettingsTab;
