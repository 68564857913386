import {ChannelResponse} from "../../../model/channel/channel";
import React from "react";
import useChannelSubscriptionHistories from "../../../query/channel/useChannelSubscriptionHistories";
import useChannelCard from "../../../query/channel/useChannelCard";
import {useIntl} from "react-intl";
import moment from "moment/moment";

type ChannelTokenUsageViewProps = {
    channel: ChannelResponse
}

const ChannelTokenUsageView: React.FC<ChannelTokenUsageViewProps> = (props) => {
    const {channel} = props;
    const intl = useIntl();
    const {data: channelSubscriptionHistories} = useChannelSubscriptionHistories(channel._id);
    const {data: channelCard} = useChannelCard(channel._id);
    return (
        <div className="p-10 space-y-6 text-[16px]">
            <div>
                <div className="border-b-4 border-gray-100 space-y-2 pb-4">
                    <label className="font-bold">사용량</label>
                    {
                        channel.channelSubscription &&
                        <div className="mx-4">
                            <div>요금제 : {channel.channelSubscription.plan.name}</div>
                            <div>메시지
                                사용량: {channel.channelSubscription.currentUsage.message}/{intl.formatNumber((channel.channelSubscription.plan.usageLimit.message) + (channel.channelSubscription.forwardedLimit.message))}</div>
                        </div>
                    }
                </div>
            </div>
            <div>
                <div className="border-b-4 border-gray-100 space-y-2 pb-4">
                    <label className="font-bold">카드 정보</label>
                    <div className="mx-4">
                        <div className="flex">
                            <div className="mr-1">
                                등록된 카드 :
                            </div>
                            {channelCard ?
                                <div>
                                    {channelCard.cardInfo?.cardName} ({channelCard.cardNumber})
                                </div>
                                :
                                <div>
                                    없음
                                </div>
                            }
                        </div>
                        <div className="flex">
                            <div className="mr-1">
                                청구서 수령인 이메일 :
                            </div>
                            <div>
                                {channel.card?.email ?
                                    <div>
                                        {channel.card?.email}
                                    </div>
                                    :
                                    <div>
                                        없음
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="rounded-[8px] shadow-dashboard-box bg-white space-y-[13px]">
                    <div className="font-bold">결제 내역</div>
                    <table className="w-full mx-4">
                        <thead className="w-full font-semibold border-b-2 border-gray-300">
                        <tr>
                            <th className="text-left w-[15%]">일시</th>
                            <th className="text-left w-[15%]">요금제</th>
                            <th className="text-left w-[15%]">잔여량</th>
                            <th className="text-left w-[25%]">결제수단</th>
                            <th className="text-left w-[20%]">금액(원)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {channelSubscriptionHistories?.map((history) => {
                            return (
                                <tr key={history._id} className="text-[#939393] border-b-2 text-[14px]">
                                    <td className="py-2">{moment.utc(history.createdAt).local().format("YYYY-MM-DD")}</td>
                                    <td className="py-2">{history.subscription.plan.name}</td>
                                    <td className="py-2">{intl.formatNumber(history.subscription.forwardedLimit.message)}</td>
                                    <td className="py-2">{history.transaction?.card.cardNumber && `•••• •••• •••• `}{history.transaction?.card.cardNumber ?? '-'}</td>
                                    <td className="py-2">{history.transaction?.billing.amount ? intl.formatNumber(history.transaction?.billing.amount) : '무료'}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ChannelTokenUsageView;