import React, {useRef, useState} from "react";
import _ from "lodash";
import toast from "react-hot-toast";
import {useDialog} from "../../hook/useDialog";
import moment from "moment";
import {useIntl} from "react-intl";

import useCreateGlobalVariableGroup from "../../query/globalVariableGroup/useCreateGlobalVariableGroup";
import useUpdateGlobalVariableGroup from "../../query/globalVariableGroup/useUpdateGlobalVariableGroup";
import useDeleteGlobalVariableGroup from "../../query/globalVariableGroup/useDeleteGlobalVariableGroup";
import {
    GlobalVariableGroup,
    GlobalVariableGroupCreateRequest,
    GlobalVariableGroupUpdateRequest
} from "../../model/globalVariable/GlobalVariableGroup";
import globalVariableGroupRepository from "../../respsitory/GlobalVariableGroupRepository";

type GlobalVariableGroupSettingFormProps = {
    globalVariableGroup?: GlobalVariableGroup;
    onCancel: () => void;
    onDelete: () => void;
    onSubmit: (globalVariableGroupKey: string) => void;
}

const INIT_INVALID = {
    key: false
}

const GlobalVariableGroupSettingForm: React.FC<GlobalVariableGroupSettingFormProps> = (props) => {
    const {globalVariableGroup, onCancel, onDelete, onSubmit} = props;
    const intl = useIntl();
    const dialog = useDialog();

    const {mutateAsync: createGlobalVariableGroup} = useCreateGlobalVariableGroup();
    const {mutateAsync: updateGlobalVariableGroup} = useUpdateGlobalVariableGroup();
    const {mutateAsync: deleteGlobalVariableGroup} = useDeleteGlobalVariableGroup();

    const keyRef = useRef<HTMLInputElement>(null);
    const descriptionRef = useRef<HTMLInputElement>(null);

    const [invalid, setInvalid] = useState<{ key: boolean}>(INIT_INVALID);

    const onChangeKey = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        const key = e.target.value;
        if (globalVariableGroup?.key === key) return;
        globalVariableGroupRepository.existed(key).then(({data}) => {
            setInvalid(prev => ({
                ...prev,
                key: key === "default" || data.result
            }))
        }).catch(() => {
            toast.error(intl.formatMessage({id: "i0167"}))
        })
    }, 200);

    const onClickDelete = () => {
        if (!globalVariableGroup) {
            return;
        }
        dialog.open({
            variant: "danger",
            title: "글로벌 변수 그룹 삭제",
            content: "글로벌 변수 그룹을 삭제하시겠습니까?",
            onConfirm: async () => {
                try {
                    await deleteGlobalVariableGroup(globalVariableGroup._id);
                    toast.success("글로벌 변수 그룹을 삭제했습니다.");
                    onDelete();
                } catch (e: any) {
                    const code = e.response.data.detail.code
                    if (code === "globalVariableGroupError") {
                        toast.error("현재 글로벌 변수에서 사용중인 그룹입니다");
                    } else {
                        toast.error("글로벌 변수 그룹을 삭제하는 도중 에러가 발생했습니다.");
                    }
                }
            }
        });
    }
    const onClickCancel = () => {
        onCancel();
    }

    const onClickSubmit = async () => {
        const key = keyRef.current?.value;
        const description = descriptionRef.current?.value;

        const invalid = {
            key: !key,
        }
        setInvalid(invalid);
        if (Object.values(invalid).some(v => v)) {
            toast.error(intl.formatMessage({id: "i0176"}))
            return;
        }

        if (globalVariableGroup) {
            const body: GlobalVariableGroupUpdateRequest = {
                key: key!,
                description,
            }
            dialog.open({
                variant: "danger",
                title: `글로벌 변수 그룹 수정`,
                content: `글로벌 변수 그룹을 수정하려고 합니다. 계속 하시겠습니까?`,
                onConfirm: async () => {
                    try {
                        await updateGlobalVariableGroup({_id: globalVariableGroup._id, ...body});
                        toast.success("글로벌 변수 정보를 수정했습니다.")
                        onSubmit(globalVariableGroup.key);
                    } catch (e) {
                        toast.error("글로벌 변수 정보를 수정하는 도중 에러가 발생했습니다.")
                    }
                }
            });
        } else {
            const body: GlobalVariableGroupCreateRequest = {
                key: key!,
                description,
            }
            try {
                await createGlobalVariableGroup(body);
                toast.success("글로벌 변수 그룹을 생성했습니다.")
                onSubmit(body.key);
            } catch (e) {
                toast.error("글로벌 변수 그룹을 생성하는 도중 에러가 발생했습니다.")
            }
        }
    }

    return (
        <div className="border border-gray-400 rounded-md shadow-lg p-5 flex flex-col overflow-y-auto">
            <div className="text-xl font-semibold mb-3 border-b-2 pb-3">
                {globalVariableGroup ?
                    <div className={"flex justify-between items-center"}>
                        <p>글로벌 변수 그룹 수정하기</p>
                    </div>
                    :
                    <p>새로운 글로벌 변수 그룹 추가하기</p>
                }
            </div>

            <div className="form-input-group flex">
                <label htmlFor="global-variable-group-key"
                       className="form-label min-w-[150px] text-lg">
                    Key
                </label>
                <div className="w-full">
                    <input id="global-variable-group-key"
                           defaultValue={globalVariableGroup?.key}
                           ref={keyRef}
                           onChange={onChangeKey}
                           className="form-input w-full focus:outline-none"
                    />
                    {invalid.key && <small className="text-red-500">이미 존재하는 Key는 사용할수없습니다.</small>}
                </div>
            </div>

            <div className="form-input-group flex">
                <label htmlFor="persona-description"
                       className="form-label min-w-[150px] text-lg">
                    {intl.formatMessage({id: "i0185"})}
                </label>
                <input id="persona-description"
                       defaultValue={globalVariableGroup?.description}
                       ref={descriptionRef}
                       className="form-input w-full focus:outline-none"
                />
            </div>

            {globalVariableGroup &&
                <>
                    <div className="form-input-group flex items-center">
                        <label className="form-label min-w-[150px] text-lg">
                            {intl.formatMessage({id: "i0196"})}
                        </label>
                        <p>{moment.utc(globalVariableGroup.createdAt).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                    </div>

                    <div className="form-input-group flex items-center">
                        <label className="form-label min-w-[150px] text-lg">
                            {intl.formatMessage({id: "i0197"})}
                        </label>
                        <p>{moment.utc(globalVariableGroup.updatedAt).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                    </div>
                </>
            }

            <div className="flex items-center justify-between border-t-2 pt-3">
                <button className="btn btn-secondary-outline transition-colors duration-200" onClick={onClickCancel}>
                    {intl.formatMessage({id: "i0198"})}
                </button>

                <div className="flex items-center space-x-2">
                    {globalVariableGroup &&
                        <button className="btn btn-danger transition-colors duration-200" onClick={onClickDelete}>
                            {intl.formatMessage({id: "i0199"})}
                        </button>
                    }
                    <button className="btn btn-primary transition-colors duration-200" onClick={onClickSubmit}>
                        {globalVariableGroup ? intl.formatMessage({id: "i0200"}) : intl.formatMessage({id: "i0201"})}
                    </button>
                </div>
            </div>
        </div>
    )
};

export default GlobalVariableGroupSettingForm;
