import React from "react";

type PromptInputFormProps = {
    value: string,
    name?: string,
    prompt?: string,
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
    invalid?: {key: boolean, llmType: boolean, promptCheck: boolean},
}

const PromptInputForm: React.FC<PromptInputFormProps> = (props) => {
    const {value, prompt, name, onChange, invalid} = props;
    return (
        <div className="w-full">
            <div className="pb-3">
                <div className="flex mb-1">
                    <input className="form-input w-auto"
                           value={value}
                           readOnly={true}
                    />
                </div>
                <textarea className="text-lg form-input h-[450px]"
                          placeholder="프롬프트 내용을 입력해주세요"
                          name={name}
                          defaultValue={prompt}
                          onChange={onChange}
                />
                {invalid?.promptCheck && <small className="text-red-500">프롬프트 내용은 필수값입니다.</small>}
            </div>
        </div>
    )
}

export default PromptInputForm;
