import {useMutation, useQueryClient} from "@tanstack/react-query";
import {SearchSpecification, SearchSpecificationUpdateRequest} from "../../model/searchSpecification";
import {AxiosError} from "axios";
import searchSpecificationRepository from "../../respsitory/SearchSpecificationRepository";


const useUpdateSearchSpecification = () => {
    const queryClient = useQueryClient();

    return useMutation<SearchSpecification, AxiosError, SearchSpecificationUpdateRequest & {_id: string}>({
        mutationKey: ["searchSpecification-specification", "update"],
        mutationFn: async (body: SearchSpecificationUpdateRequest & {_id: string}) => {
            try {
                const {_id, ...extra} = body;
                const {data} = await searchSpecificationRepository.update(_id, extra);
                return data.result
            } catch (e) {
                throw e;
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["searchSpecification-specification"],
                exact: false,
            });
        }
    })
};

export default useUpdateSearchSpecification;
