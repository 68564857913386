import searchSpecificationRepository from "../../respsitory/SearchSpecificationRepository";
import {useQuery} from "@tanstack/react-query";
import {SearchSpecificationHistory} from "../../model/searchSpecification";
import {AxiosError} from "axios";


const getData = async (id: string) => {
    try {
        const {data} = await searchSpecificationRepository.findHistory(id);
        return data.result
    } catch (e) {
        throw e;
    }
}

const useSearchSpecificationHistory = (id: string) => {
    return useQuery<SearchSpecificationHistory[], AxiosError>({
        queryKey: ["searchSpecificationHistory", id],
        queryFn: async () => getData(id),
        retry: false,
        suspense: false,
    })
}

export default useSearchSpecificationHistory;
