import {
    AZURE_OPENAI_SUPPORTED_MODELS,
    LLM42_SUPPORTED_MODELS,
    LLMSpecification,
    LLMSpecificationBase,
    LLMSpecificationCreateRequest,
    LLMSpecificationUpdateRequest,
    LLMVendorType, NAVER_SUPPORTED_MODELS,
    OPENAI_SUPPORTED_MODELS
} from "../../model/llmSpecification";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import useCreateLLMSpecification from "../../query/llm/useCreateLLMSpecification";
import useUpdateLLMSpecification from "../../query/llm/useUpdateLLMSpecification";
import useDeleteLLMSpecification from "../../query/llm/useDeleteLLMSpecification";
import {useList} from "@react-hookz/web";
import _ from "lodash";
import llmSpecificationRepository from "../../respsitory/LLMSpecificationRepository";
import toast from "react-hot-toast";
import * as uuid from "uuid";
import {DictType} from "../../model";
import {TrashIcon} from "@heroicons/react/24/outline";
import {useDialog} from "../../hook/useDialog";
import moment from "moment";
import LLMSpecificationHistoryModal, {LLM_HISTORY_MODAL_ID} from "./LLMSpecificationHistoryModal";
import useModal from "../../hook/useModal";
import {useQueryClient} from "@tanstack/react-query";

type LLMSpecificationSettingFormProps = {
    specification?: LLMSpecification;
    copySpecification?: LLMSpecificationBase;
    onCancel: () => void;
    onDelete: () => void;
    onSubmit: (specificationKey: string) => void;
    onClickCopySpecification: (specification: LLMSpecificationBase) => void;
}

export const SUPPORTED_VENDORS_WITH_MODELS = [
    {
        vendor: LLMVendorType.OPENAI,
        label: "OpenAI",
        models: OPENAI_SUPPORTED_MODELS,
    },
    {
        vendor: LLMVendorType.LLM42,
        label: "LLM42",
        models: LLM42_SUPPORTED_MODELS,
    },
    {
        vendor: LLMVendorType.AZURE_OPENAI,
        label: "Azure OpenAI",
        models: AZURE_OPENAI_SUPPORTED_MODELS,
    },
    {
        vendor: LLMVendorType.NAVER,
        label: "NAVER",
        models: NAVER_SUPPORTED_MODELS,
    }
]

const REQUIRED_MODEL_PARAMETER_KEYS = [
    "maxTokens",
    "modelMaxTokens",
]

const INIT_INVALID = {
    key: false,
    vendor: false,
    model: false,
}

const LLMSpecificationSettingForm: React.FC<LLMSpecificationSettingFormProps> = (props) => {
    const {specification, copySpecification, onCancel, onDelete, onSubmit, onClickCopySpecification} = props;

    const dialog = useDialog();
    const modal = useModal();
    const queryClient = useQueryClient();

    const {mutateAsync: createSpecification} = useCreateLLMSpecification();
    const {mutateAsync: updateSpecification} = useUpdateLLMSpecification();
    const {mutateAsync: deleteSpecification} = useDeleteLLMSpecification();

    const keyRef = useRef<HTMLInputElement>(null);
    const descriptionRef = useRef<HTMLInputElement>(null);
    const modelRef = useRef<HTMLSelectElement>(null);

    const [invalid, setInvalid] = useState<{key: boolean, vendor: boolean, model: boolean}>(INIT_INVALID);

    const [selectedVendor, setSelectedVendor] = useState<LLMVendorType>();
    const [selectedModel, setSelectedModel] = useState<string>();

    const modelParamRefs = useRef<{key: HTMLInputElement | null, value: HTMLInputElement | null}[]>([]);
    const [modelParams, {
        set: setModelParams,
        push: pushModelParams,
        removeAt: removeAtModelParams,
    }] = useList<{ id: string, key?: string, value?: any }>([]);

    const supportedModels = useMemo(() => {
        return SUPPORTED_VENDORS_WITH_MODELS.find(item => item.vendor === selectedVendor)?.models ?? [];
    }, [selectedVendor]);

    const onChangeKey = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        const key = e.target.value;
        if (key === specification?.key) return;
        llmSpecificationRepository.existed(key).then(({data}) => {
            setInvalid(prev => ({
                ...prev,
                key: data.result
            }))
        }).catch(() => {
            toast.error("중복을 체크하는 도중 에러가 발생했습니다.")
        })
    }, 200);

    const onChangeVendor = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedVendor(e.target.value as LLMVendorType);
    }

    const onChangeModel = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedModel(e.target.value);
    }

    const onClickAddParam = () => {
        pushModelParams({id: uuid.v4()})
    }

    const deleteParamByIndex = useCallback((index: number) => {
        modelParamRefs.current = [];
        removeAtModelParams(index);
    }, [removeAtModelParams]);

    const onClickDeleteParam = (index: number) => {
        const key = modelParams?.[index]?.key;
        if (key && REQUIRED_MODEL_PARAMETER_KEYS.includes(key)) {
            dialog.open({
                title: "중요 파라미터 삭제",
                content: `${key} 파라미터는 LLM 모델에 꼭 필요한 파라미터일 수 있습니다. 그래도 삭제하시겠습니까?`,
                variant: "danger",
                onConfirm: () => {
                    deleteParamByIndex(index);
                },
                confirmText: "삭제",
            })
        } else {
            deleteParamByIndex(index);
        }
    }

    const onClickDelete = () => {
        if (!specification) {
            return;
        }

        dialog.open({
            variant: "danger",
            title: "LLM 정보 삭제",
            content: "LLM 정보를 삭제하시겠습니까?",
            onConfirm: async () => {
                try {
                    await deleteSpecification(specification._id);
                    toast.success("LLM 정보를 삭제했습니다.");
                    onDelete();
                } catch (e: any) {
                    const code = e.response.data.detail.code
                    if (code === "channelCommonSetting") {
                        toast.error("현재 채널 설정 프리셋에서 사용중인 데이터입니다");
                    } else {
                        toast.error("LLM 정보를 삭제하는 도중 에러가 발생했습니다.");
                    }
                }
            }
        });

    }

    const onClickCancel = () => {
        onCancel();
    }

    const onClickSubmit = async () => {
        const key = keyRef.current?.value;
        const description = descriptionRef.current?.value;
        const model = modelRef.current?.value;

        const invalid = {
            key: !key,
            vendor: !selectedVendor,
            model: !model,
        }
        setInvalid(invalid);
        if (Object.values(invalid).some(v=>v)) {
            toast.error("빈칸을 채워주세요.")
            return;
        }

        const validModelParams: {key: string, value: string}[] = [];
        modelParamRefs.current.forEach((ref) => {
            if (ref.key?.value && ref.value?.value) {
                validModelParams.push({key: ref.key.value, value: ref.value.value});
            }
        })

        const parameters = validModelParams.reduce((result, param)=> {
            result[param.key] = param.value;
            return result
        }, {} as DictType<any>)

        if (specification) {
            const body: LLMSpecificationUpdateRequest = {
                key: key!,
                description,
                vendor: selectedVendor!,
                model: model!,
                parameters
            }

            dialog.open({
                variant: "danger",
                title: `LLM 수정`,
                content: `LLM을 수정하려고 합니다. 계속 하시겠습니까?`,
                onConfirm: async () => {
                    try {
                        await updateSpecification({_id: specification._id, ...body});
                        toast.success("LLM 정보를 수정했습니다.")
                        await queryClient.invalidateQueries(["llmSpecificationHistory", specification._id], {exact: true});
                        onSubmit(specification.key);
                    } catch (e) {
                        toast.error("LLM 정보를 수정하는 도중 에러가 발생했습니다.")
                    }
                }
            });
        } else {
            const body: LLMSpecificationCreateRequest = {
                key: key!,
                description,
                vendor: selectedVendor!,
                model: model!,
                parameters
            }
            try {
                await createSpecification(body);
                toast.success("LLM을 생성했습니다.")
                onSubmit(body.key);
            } catch (e) {
                toast.error("LLM을 생성하는 도중 에러가 발생했습니다.")
            }
        }
    }

    const onClickShowHistoryModal = () => {
        modal.open(LLM_HISTORY_MODAL_ID);
    }

    const onClickCopy = async () => {
        if (!specification) return;
        const key = specification.key
        const currentDate = moment().format('YYYY-MM-DD HH:mm');
        const copiedKey = `${key}_copied(${currentDate})`
        const copySpecification = {...specification, key: copiedKey}
        onClickCopySpecification(copySpecification)
    }

    useEffect(() => {
        setSelectedVendor(specification?.vendor ?? LLMVendorType.OPENAI);
        setSelectedModel(specification?.model ?? "gpt-3.5-turbo");
        if (specification) {
            setModelParams(Object.entries(specification.parameters).map(([key, value]) => ({id: uuid.v4(), key, value})))
        }
        else if(copySpecification) {
            setModelParams(Object.entries(copySpecification.parameters).map(([key, value]) => ({id: uuid.v4(), key, value})))
            setSelectedVendor(copySpecification.vendor);
            setSelectedModel(copySpecification.model);
        }
        else {
            setModelParams([
                           {id: uuid.v4(), key: "maxTokens", "value": 1000},
            ])
        }
    }, [specification, setModelParams, copySpecification])

    return (
        <div>
            <div className="flex items-center justify-end pt-3 gap-x-2 mb-2">
                {specification &&
                    <div className="flex items-center justify-between space-x-2">
                        <button className="btn bg-purple-800 text-white transition-colors duration-200"
                                onClick={onClickShowHistoryModal}>
                            변경이력 보기
                        </button>
                        <LLMSpecificationHistoryModal LLMId={specification._id}/>
                        <button className="btn bg-green-500 text-white transition-colors duration-200"
                                onClick={onClickCopy}>
                            사본생성
                        </button>
                    </div>
                }
                <button className="btn btn-primary transition-colors duration-200" onClick={onClickSubmit}>
                    {specification ? "수정하기" : "저장하기"}
                </button>
                {specification &&
                    <button className="btn btn-danger transition-colors duration-200" onClick={onClickDelete}>
                        삭제하기
                    </button>
                }
                <button className="btn btn-secondary-outline transition-colors duration-200" onClick={onClickCancel}>
                    취소하기
                </button>
            </div>
            <div className="border border-gray-400 rounded-md shadow-lg p-5 flex flex-col overflow-y-auto">
                <div className="text-xl font-semibold mb-3 border-b-2 pb-3">
                    <div className="flex justify-between">
                        <div className="flex items-center">
                            <div className={"flex"}>
                                <p>LLM 정보</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-input-group flex">
                    <label htmlFor="llm-key"
                           className="form-label min-w-[150px] text-lg">
                        LLM Key
                    </label>
                    <div className="w-full">
                        <input id="llm-key"
                               defaultValue={specification?.key ?? copySpecification?.key}
                               ref={keyRef}
                               onChange={onChangeKey}
                               className="form-input w-full focus:outline-none"
                        />
                        {invalid.key && <small className="text-red-500">이미 있는 key는 사용할 수 없습니다.</small>}
                    </div>
                </div>

                <div className="form-input-group flex">
                    <label htmlFor="llm-description"
                           className="form-label min-w-[150px] text-lg">
                        Description
                    </label>
                    <input id="llm-description"
                           defaultValue={specification?.description ?? copySpecification?.description}
                           ref={descriptionRef}
                           className="form-input w-full focus:outline-none"
                    />
                </div>

                <div className="form-input-group flex">
                    <label htmlFor="llm-vendor"
                           className="form-label min-w-[150px] text-lg">
                        Vendor
                    </label>
                    <select id="llm-vendor"
                            className="form-select w-full px-2 focus:outline-none"
                            value={selectedVendor}
                            onChange={onChangeVendor}
                            disabled={!!specification}
                    >
                        {SUPPORTED_VENDORS_WITH_MODELS.map((item) => (
                            <option key={item.vendor} value={item.vendor}>{item.label}</option>
                        ))}
                    </select>
                </div>

                <div className="form-input-group flex">
                    <label htmlFor="llm-model"
                           className="form-label min-w-[150px] text-lg">
                        Model
                    </label>
                    <select id="llm-model"
                            className="form-select w-full px-2 focus:outline-none"
                            ref={modelRef}
                            value={selectedModel}
                            onChange={onChangeModel}
                            disabled={!!specification}
                    >
                        {supportedModels.map((item) => (
                            <option key={item} value={item}>{item}</option>
                        ))}
                    </select>
                </div>

                <div className="form-input-group flex items-start">
                    <label className="form-label min-w-[150px] text-lg whitespace-pre-wrap">
                        {"Parameters\n(Key-Value Pair)"}
                    </label>

                    <div className="w-full">
                        {modelParams.map((modelParam, index) => {
                            return (
                                <div key={modelParam.id}
                                     className="flex items-center space-x-2">
                                    <input className="form-input"
                                           defaultValue={modelParam.key}
                                           placeholder={"파라미터 이름"}
                                           ref={(el: HTMLInputElement) => {
                                               if (el) {
                                                   if (!modelParamRefs.current[index]) {
                                                       modelParamRefs.current[index] = { key: null, value: null};
                                                   }
                                                   modelParamRefs.current[index].key = el;
                                               }
                                           }}/>
                                    <input className="form-input"
                                           defaultValue={modelParam.value}
                                           placeholder={"파라미터 값"}
                                           ref={(el: HTMLInputElement) => {
                                               if (el) {
                                                   if (!modelParamRefs.current[index]) {
                                                       modelParamRefs.current[index] = { key: null, value: null};
                                                   }
                                                   modelParamRefs.current[index].value = el;
                                               }
                                           }}/>
                                    <TrashIcon className="w-12 h-12 cursor-pointer"
                                               onClick={() => onClickDeleteParam(index)}/>
                                </div>
                            )
                        })}

                        <button className="btn btn-secondary-outline transition-colors duration-200 btn-sm w-full"
                                onClick={onClickAddParam}>
                            + 파라미터 추가하기
                        </button>
                    </div>
                </div>

                {specification &&
                    <>
                        <div className="form-input-group flex items-center">
                            <label className="form-label min-w-[150px] text-lg">
                                생성된 날짜
                            </label>
                            <p>{moment.utc(specification.createdAt).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                        </div>

                        <div className="form-input-group flex items-center">
                            <label className="form-label min-w-[150px] text-lg">
                                수정된 날짜
                            </label>
                            <p>{moment.utc(specification.updatedAt).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                        </div>
                    </>
                }
            </div>
        </div>
    )
};

export default LLMSpecificationSettingForm;
