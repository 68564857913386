import {Modal} from "../../hook/useModal";
import React, {useState} from "react";
import moment from "moment/moment";
import {useIntl} from "react-intl";
import useLLMSpecificationHistory from "../../query/llm/useLLMSpecificationHistory";
import {LLMSpecification, LLMSpecificationHistory} from "../../model/llmSpecification";
import classNames from "classnames";

export const LLM_HISTORY_MODAL_ID = "llm-history-modal-id";

type LLMHistoryModalProps = {
    LLMId: string
}

const LLMSpecificationHistoryModal:React.FC<LLMHistoryModalProps> = (props) => {
    const {LLMId} = props;
    const intl = useIntl();
    const {data: llmSpecificationHistories} = useLLMSpecificationHistory(LLMId);

    const [showHistoryDetail, setShowHistoryDetail] = useState(false);
    const [selectedLLMSpecification, setSelectedLLMSpecification] = useState<LLMSpecification>();
    const [selectedId, setSelectedId] = useState<string>();

    const onClickHistoryDetail = (llmSpecificationHistory: LLMSpecificationHistory) => {
        setShowHistoryDetail(true);
        setSelectedLLMSpecification(llmSpecificationHistory.llmSpecification)
        setSelectedId(llmSpecificationHistory._id)
    }

    return (
        <Modal id={LLM_HISTORY_MODAL_ID}
               size="2xl"
               title="LLM 변경이력"
        >
            <div className="border-b-2 pb-5"/>
            <div className="flex w-full min-h-[800px]">
                <div className="flex-[0_0_20%] min-w-[300px] bg-gradient-to-b from-gray-900 to-gray-800 border-r border-r-2 flex flex-col text-white">
                    {
                        llmSpecificationHistories?.length === 0 &&
                        <div className="flex pt-3 text-xl justify-center items-center font-semibold">
                            변경 이력 없음
                        </div>
                    }
                    {
                        llmSpecificationHistories && llmSpecificationHistories?.length > 0 &&
                        <div className="w-full">
                            <label className="flex pt-3 text-xl justify-center items-center font-semibold">변경 이력</label>
                            <div className="py-5 space-y-2">
                                {llmSpecificationHistories.map((llmSpecificationHistory) => {
                                    const selectedHistory = selectedId === llmSpecificationHistory._id;
                                    return (
                                        <div key={llmSpecificationHistory.version} className={classNames("flex justify-center items-center py-2 mx-2",
                                            selectedHistory && "bg-gray-700 text-blue-500")}>
                                            <button onClick={() => {onClickHistoryDetail(llmSpecificationHistory)}}>
                                                Version {llmSpecificationHistory.version}
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </div>
                {
                    showHistoryDetail &&
                    <div className="w-[1100px]">
                        <div className="border border-gray-400 rounded-md shadow-lg p-5 flex flex-col overflow-y-auto">
                            <div className="text-2xl font-semibold mb-3 border-b-2 pb-3">
                                <div className="flex items-center justify-between">
                                    <div className={"flex justify-between items-center"}>
                                        <p>LLM 설정</p>
                                    </div>
                                </div>
                            </div>
                            <div className="form-input-group flex">
                                <label className="form-label min-w-[200px] text-lg">
                                    LLM 키
                                </label>
                                <div className="form-input w-full focus:outline-none">
                                    {selectedLLMSpecification?.key}
                                </div>
                            </div>

                            <div className="form-input-group flex">
                                <label className="form-label min-w-[200px] text-lg">
                                    설명
                                </label>
                                <div className="form-input w-full focus:outline-none">
                                    {selectedLLMSpecification?.description}
                                </div>
                            </div>

                            <div className="form-input-group flex">
                                <label className="form-label min-w-[200px] text-lg">
                                    벤더 사
                                </label>
                                <div className="form-input w-full focus:outline-none">
                                    {selectedLLMSpecification?.vendor}
                                </div>
                            </div>

                            <div className="form-input-group flex">
                                <label className="form-label min-w-[200px] text-lg">
                                    모델
                                </label>
                                <div className="form-input w-full focus:outline-none">
                                    {selectedLLMSpecification?.model}
                                </div>
                            </div>
                            {
                                selectedLLMSpecification &&
                                <div className="form-input-group flex items-start">
                                    <label className="form-label min-w-[200px] text-lg whitespace-pre-wrap">
                                        {"Model Parameters\n(Key-Value Pair)"}
                                    </label>
                                    <div className="w-full space-y-2">
                                        {Object.entries(selectedLLMSpecification.parameters).map((parameter) => {
                                            const [name, value] = parameter;
                                            return (
                                                <div className="flex space-x-2">
                                                    <input className="form-input w-auto"
                                                           value={name}
                                                           readOnly={true}
                                                    />
                                                    <input className="form-input w-auto"
                                                           value={value}
                                                           readOnly={true}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            }

                            <div className="form-input-group flex items-center">
                                <label className="form-label min-w-[150px] text-lg">
                                    {intl.formatMessage({id: "i0196"})}
                                </label>
                                <p>{moment.utc(selectedLLMSpecification?.createdAt).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                            </div>

                            <div className="form-input-group flex items-center">
                                <label className="form-label min-w-[150px] text-lg">
                                    {intl.formatMessage({id: "i0197"})}
                                </label>
                                <p>{moment.utc(selectedLLMSpecification?.updatedAt).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </Modal>
    )
}

export default LLMSpecificationHistoryModal;

