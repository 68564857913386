import { ErrorLog } from "../../../model/llmSearchTestPanel/QueryAnswers";
import React from "react";
import { useIntl } from "react-intl";
import { DetailSettingRowFragment, DetailSettingTable, renderText } from "../component/DetailSettingTable";
import DetailDisclosure from "../component/DetailDisclosure";

interface LLMErrorLogProps {
    className?: string
    error: ErrorLog
}

const LLMErrorLog: React.FC<LLMErrorLogProps> = (props) => {
    const intl = useIntl();
    const {process, type, code, detail, traceback} = props.error;

    const processToIntl: {[index: string]: string} = {
        'analyzed_query': intl.formatMessage({id: "i11049"}),
        'passage_search': intl.formatMessage({id: "i11051"}),
        'context_build': intl.formatMessage({id: "i11046"}),
        'llm_response': intl.formatMessage({id: "i11062"}),
        'load_data': intl.formatMessage({id: "i11098"}),
        'query_embedding': intl.formatMessage({id: "i11099"}),
        'other': intl.formatMessage({id: "i11091"})
    }

    return (
        <DetailDisclosure title={`${processToIntl[process]}: ${intl.formatMessage({id: "i11087"})}`}
                          summary={(
                              <DetailSettingTable>
                                  {DetailSettingRowFragment(intl.formatMessage({id: 'i11095'}), processToIntl[process])}
                                  {DetailSettingRowFragment(intl.formatMessage({id: 'i11100'}), code)}
                                  {DetailSettingRowFragment(intl.formatMessage({id: 'i11088'}), type)}
                              </DetailSettingTable>
                          )}>
            <div className="flex flex-col space-y-3 p-2  leading-6">
                <div className={"font-semibold text-xl"}>{intl.formatMessage({id: "i11089"})}</div>
                <div className={"ml-4 p-1 px-4 flex flex-col w-full border-2 rounded-xl"}>
                    {renderText(detail)}
                </div>

                {traceback && (
                    <>
                        <div className={"font-semibold text-xl"}>{intl.formatMessage({id: "i11090"})}</div>
                        <div className={"ml-4 p-1 px-4 flex flex-col w-full border-2 rounded-xl"}>
                            {renderText(traceback)}
                        </div>
                    </>
                )}
            </div>
        </DetailDisclosure>
    )
}

export default LLMErrorLog;
