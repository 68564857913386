import ChannelGroupRepository from "../../respsitory/ChannelGroupRepository";
import {useQuery} from "@tanstack/react-query";
import {ChannelGroup} from "../../model/channelGroup/channelGroup";
import {AxiosError} from "axios";

const getData = async (key?: string) => {
    try {
        const {data} = await ChannelGroupRepository.find(key);
        return data.result;
    } catch (e) {
        throw e;
    }
}

const useChannelGroups = (key?: string) => {
    return useQuery<ChannelGroup[], AxiosError>({
        queryKey: ["channelGroup", key],
        queryFn: async () => getData(key),
        retry: false,
        suspense: false,
    })
}

export default useChannelGroups;
