import axios from "axios";
import {API_HOST} from "../core/variables";
import {
    GlobalVariableGroupCreateRequest,
    GlobalVariableGroupUpdateRequest
} from "../model/globalVariable/GlobalVariableGroup";

class GlobalVariableGroupRepository {
    async create(body: GlobalVariableGroupCreateRequest) {
        return axios.post(`${API_HOST}/v1/global-variable-group`, body)
    }

    async find(searchKey?: string, searchType?: string) {
        return axios.get(`${API_HOST}/v1/global-variable-group`, {
            params: {
                search_key: searchKey,
                search_type: searchType
            }
        })
    }

    async read(id: string) {
        return axios.get(`${API_HOST}/v1/global-variable-group/${id}`)
    }

    async existed(key: string) {
        return axios.get(`${API_HOST}/v1/global-variable-group/existed`, {
            params: {
                key: key
            }
        })
    }

    async update(id: string, body: GlobalVariableGroupUpdateRequest) {
        return axios.put(`${API_HOST}/v1/global-variable-group/${id}`, body)
    }

    async delete(id: string) {
        return axios.delete(`${API_HOST}/v1/global-variable-group/${id}`)
    }

}

const globalVariableGroupRepository = new GlobalVariableGroupRepository();
export default globalVariableGroupRepository;