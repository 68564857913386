import {Modal} from "../../hook/useModal";
import {Prompt, PromptHistory, PromptTypeList} from "../../model/prompt/prompt";
import usePromptHistory from "../../query/prompt/usePromptHistory";
import React, {useState} from "react";
import moment from "moment/moment";
import {useIntl} from "react-intl";
import classNames from "classnames";

export const PROMPT_HISTORY_MODAL_ID = "prompt-history-modal-id";

type PromptHistoryModalProps = {
    promptId: string
}

const PromptHistoryModal:React.FC<PromptHistoryModalProps> = (props) => {
    const {promptId} = props;
    const intl = useIntl();
    const {data: promptHistories} = usePromptHistory(promptId);

    const [showHistoryDetail, setShowHistoryDetail] = useState(false);
    const [selectedPrompt, setSelectedPrompt] = useState<Prompt>();
    const [selectedId, setSelectedId] = useState<string>();

    const onClickHistoryDetail = (promptHistory: PromptHistory) => {
        setShowHistoryDetail(true);
        setSelectedPrompt(promptHistory.prompt)
        setSelectedId(promptHistory._id)
    }

    return (
        <Modal id={PROMPT_HISTORY_MODAL_ID}
               size="2xl"
               title="프롬프트 변경이력"
        >
            <div className="border-b-2 pb-5"/>
            <div className="flex w-full min-h-[800px]">
                <div className="flex-[0_0_20%] min-w-[300px] bg-gradient-to-b from-gray-900 to-gray-800 border-r border-r-2 flex flex-col text-white">
                    {
                        promptHistories?.length === 0 &&
                        <div className="flex pt-3 text-xl justify-center items-center font-semibold">
                            변경 이력 없음
                        </div>
                    }
                    {
                        promptHistories && promptHistories?.length > 0 &&
                        <div className="w-full">
                            <label className="flex pt-3 text-xl justify-center items-center font-semibold">변경 이력</label>
                            <div className="py-5 space-y-2">
                                {promptHistories.map((promptHistory) => {
                                    const selectedHistory = selectedId === promptHistory._id;
                                    return (
                                        <div key={promptHistory.version} className={classNames("flex justify-center items-center py-2 mx-2",
                                            selectedHistory && "bg-gray-700 text-blue-500")}>
                                            <button onClick={() => {onClickHistoryDetail(promptHistory)}}>
                                                Version {promptHistory.version}
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </div>
                <div className="w-full py-5 px-5 flex justify-between">
                    {
                        showHistoryDetail &&
                        <div className="w-[1000px]">
                            <div className="border border-gray-400 rounded-md shadow-lg p-5 flex flex-col overflow-y-auto">
                                <div className="text-2xl font-semibold mb-3 border-b-2 pb-3">
                                    <div className="flex items-center justify-between">
                                        <div className={"flex justify-between items-center"}>
                                            <p>프롬프트 설정</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-input-group flex">
                                    <label className="form-label min-w-[200px] text-lg">
                                        프롬프트 키
                                    </label>
                                    <div className="form-input w-full focus:outline-none">
                                        {selectedPrompt?.key}
                                    </div>
                                </div>

                                <div className="form-input-group flex">
                                    <label className="form-label min-w-[200px] text-lg">
                                        설명
                                    </label>
                                    <div className="form-input w-full focus:outline-none">
                                        {selectedPrompt?.description}
                                    </div>
                                </div>

                                <div className="form-input-group flex">
                                    <label className="form-label min-w-[200px] text-lg">
                                        프롬프트 유형
                                    </label>
                                    {PromptTypeList.map((item) => {
                                            const {name, type} = item
                                            if (type === selectedPrompt?.type) {
                                                return (
                                                    <div className="form-input w-full focus:outline-none" key={type}>
                                                        {name}
                                                    </div>
                                                )
                                            } else {
                                                return null;
                                            }
                                        }
                                    )}
                                </div>
                                {
                                    selectedPrompt &&
                                    <div className="form-input-group flex items-start">
                                        <label className="form-label min-w-[200px] text-lg whitespace-pre-wrap">
                                            {"Model Parameters\n(Key-Value Pair)"}
                                        </label>
                                        <div className="w-full space-y-2">
                                            {Object.entries(selectedPrompt.llmParameters).map((parameter) => {
                                                const [name, value] = parameter;
                                                return (
                                                    <div className="flex space-x-2">
                                                        <input className="form-input w-auto"
                                                               value={name}
                                                               readOnly={true}
                                                        />
                                                        <input className="form-input w-auto"
                                                               value={value}
                                                               readOnly={true}
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                }

                                <div className="form-input-group flex items-center">
                                    <label className="form-label min-w-[150px] text-lg">
                                        {intl.formatMessage({id: "i0196"})}
                                    </label>
                                    <p>{moment.utc(selectedPrompt?.createdAt).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                                </div>

                                <div className="form-input-group flex items-center">
                                    <label className="form-label min-w-[150px] text-lg">
                                        {intl.formatMessage({id: "i0197"})}
                                    </label>
                                    <p>{moment.utc(selectedPrompt?.updatedAt).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                                </div>
                            </div>
                            <div className="border border-gray-400 rounded-md shadow-lg p-5 pb-7 mt-5">
                                <div className="text-2xl font-semibold mb-3 border-b-2 pb-3">
                                    <div className="flex items-center justify-between">
                                        <div className={"flex justify-between items-center"}>
                                            <p>프롬프트 내용</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="form-input-group flex">
                                        <label className="form-label min-w-[200px] text-lg">
                                            LLM 타입
                                        </label>
                                        <div className="form-select w-full px-2 focus:outline-none">
                                            {selectedPrompt?.llmType}
                                        </div>
                                    </div>
                                    <div className="form-input-group flex items-start w-full">
                                        <div className="w-full flex-grow">
                                            {
                                                selectedPrompt?.content.map((content) => {
                                                    return (
                                                        <div key={content.role} className="w-full">
                                                            <div className="pb-3">
                                                                <div className="flex mb-1">
                                                                    <input className="form-input w-auto"
                                                                           value={content.role}
                                                                           readOnly={true}
                                                                    />
                                                                </div>
                                                                <textarea className="text-lg form-input h-[250px]"
                                                                          readOnly={true}
                                                                          defaultValue={content.content}
                                                                />
                                                            </div>
                                                        </div>
                                                    )})
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Modal>
    )
}

export default PromptHistoryModal;

