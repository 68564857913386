import React, {useCallback, useMemo, useState} from "react";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import _ from "lodash";
import classNames from "classnames";
import {useIntl} from "react-intl";
import {GlobalVariables, GlobalVariablesBase} from "../../model/GlobalVariables";
import GlobalVariablesSettingForm from "../../component/globalVariable/GlobalVariablesSettingForm";
// import GlobalVariablesGuide from "../../component/globalVariable/GlobalVariablesGuide";
import useGlobalVariables from "../../query/globalVariables/useGlobalVariables";
import useGlobalVariableGroup from "../../query/globalVariableGroup/useGlobalVariableGroup";

const GlobalVariablesSettingView: React.FC = () => {
    const intl = useIntl();
    const [searchKey, setSearchKey] = useState<string>();
    const [searchType, setSearchType] = useState<string | undefined>();
    const [showForm, setShowForm] = useState(false);
    const [selectedGlobalVariablesId, setSelectedGlobalVariablesId] = useState<string>();
    const [copyGlobalVariable, setCopyGlobalVariable] = useState<GlobalVariablesBase>();


    const {data: globalVariables} = useGlobalVariables(searchKey, searchType);
    const {data: globalVariableGroups} = useGlobalVariableGroup();


    const selectedGlobalVariables = useMemo(() => {
        return globalVariables?.find(globalVariable => globalVariable.key === selectedGlobalVariablesId);
    }, [globalVariables, selectedGlobalVariablesId]);

    const hideForm = useCallback(() => {
        setSelectedGlobalVariablesId(undefined);
        setShowForm(false);
    }, [setSelectedGlobalVariablesId, setShowForm]);

    const onChangeSearch = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchKey(e.target.value);
        hideForm();
    }, 300);

    const onClickGlobalVariables = (globalVariables: GlobalVariables) => {
        setSelectedGlobalVariablesId(globalVariables.key)
        setShowForm(true);
    }

    const onClickAddGlobalVariables = () => {
        setSelectedGlobalVariablesId(undefined);
        setCopyGlobalVariable(undefined);
        setShowForm(true);
    }

    const onClickCopyGlobalVariables = (globalVariables: GlobalVariablesBase) => {
        setCopyGlobalVariable(globalVariables);
        setSelectedGlobalVariablesId(undefined);
        setShowForm(true);
    }


    const onClickCancelGlobalVariables = () => {
        hideForm();
    }

    const onClickDeleteGlobalVariables = () => {
        hideForm();
    }

    const onClickSubmitGlobalVariables = (specificationKey: string) => {
        setSelectedGlobalVariablesId(specificationKey);
    }

    const onChangeSearchType = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value === "all") {
            setSearchType(undefined);
        } else {
            setSearchType(e.target.value);
        }
    }

    return (
        <div className="flex flex-col h-full">
            {/* Content */}
            <div className="flex space-x-5">
                <div className="w-[30%] flex flex-col space-y-2">

                    <div className="flex items-center justify-between">
                        <p className="text-xl pl-2">글로벌 변수 목록</p>

                        <button className="btn btn-info btn-sm" onClick={onClickAddGlobalVariables}>
                            {intl.formatMessage({id: "i0204"})}
                        </button>
                    </div>

                    {/* Search bar */}
                    <div className="relative flex">
                        <select className="mr-2"
                                onChange={onChangeSearchType}>
                            <option value="all">모든 그룹</option>
                            {globalVariableGroups?.map((globalVariableGroup) => {
                                const {_id, key} = globalVariableGroup;
                                return <option key={_id} value={_id}>{key}</option>
                            })}
                        </select>
                        <div className="w-full">
                            <div className="absolute inset-y-0 flex items-center pl-3 pointer-events-none">
                                <MagnifyingGlassIcon className="w-5 h-5"/>
                            </div>
                            <input type="search"
                                   id="search"
                                   className="form-input pl-10"
                                   placeholder="Search by key"
                                   onChange={onChangeSearch}
                            />
                        </div>
                    </div>

                    {/* List */}
                    <div className="flex-grow space-y-2 overflow-y-auto max-h-[800px]">
                        {globalVariables?.map((globalVariable) => {
                            const description = globalVariable.description === "" ? "설명 없음" : (globalVariable?.description ?? "설명 없음")
                            return (
                                <div key={globalVariable.key}
                                     className={classNames(
                                         "border border-gray-600 rounded-lg px-5 py-3 cursor-pointer transition-colors duration-200 hover:bg-gray-200",
                                         selectedGlobalVariablesId === globalVariable.key && "bg-gray-300"
                                     )}
                                     onClick={() => onClickGlobalVariables(globalVariable)}>
                                    <p className="text-lg truncate">{globalVariable.key}</p>
                                    <p className="text-sm">{description}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="flex-grow space-y-2">
                    {showForm &&
                        <GlobalVariablesSettingForm key={`specification-setting-form-${selectedGlobalVariables?.key}`}
                                                    globalVariable={selectedGlobalVariables}
                                                    copyGlobalVariable={copyGlobalVariable}
                                                    onCancel={onClickCancelGlobalVariables}
                                                    onDelete={onClickDeleteGlobalVariables}
                                                    onSubmit={onClickSubmitGlobalVariables}
                                                    onClickCopyGlobalVariables={onClickCopyGlobalVariables}
                        />
                    }

                    {/*<GlobalVariablesGuide/>*/}
                </div>
            </div>
        </div>
    );
};

export default GlobalVariablesSettingView;
