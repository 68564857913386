import {useMutation, useQueryClient} from "@tanstack/react-query";
import {Prompt, PromptUpdateRequest} from "../../model/prompt/prompt";
import {AxiosError} from "axios";
import promptRepository from "../../respsitory/PromptRepository";


const useUpdatePrompt = () => {
    const queryClient = useQueryClient();
    return useMutation<Prompt, AxiosError, PromptUpdateRequest & { _id: string }>({
        mutationKey: ["prompt", "update"],
        mutationFn: async (body: PromptUpdateRequest & { _id: string }) => {
            try {
                const {_id, ...extra} = body;
                const {data} = await promptRepository.update(_id, extra)
                return data.result;
            } catch (e) {
                throw e;
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["prompt"],
                exact: false
            });
        }
    })
};

export default useUpdatePrompt;