import promptRepository from "../../respsitory/PromptRepository";
import {useQuery} from "@tanstack/react-query";
import {PromptHistory} from "../../model/prompt/prompt";
import {AxiosError} from "axios";

const getData = async (id: string) => {
    try {
        const {data} = await promptRepository.findHistory(id);
        return data.result;
    } catch (e) {
        throw e;
    }
}

const usePromptHistory = (id: string) => {
    return useQuery<PromptHistory[], AxiosError>({
        queryKey: ["promptHistory", id],
        queryFn: async () => getData(id),
        retry: false,
        suspense: false
    })
}

export default usePromptHistory;