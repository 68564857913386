import {useNavigate, useParams} from "react-router-dom";
import React, {useCallback, useMemo, useState} from "react";
import PageBar from "../../component/PageBar";
import ChannelRow from "../channel/ChannelRow";
import {useIntl} from "react-intl";
import {Channel} from "../../model/channel/channel";
import useChannelsByUserId from "../../query/user/useChannelsByUserId";
import {ChevronLeftIcon} from "@heroicons/react/24/outline";
import {Tab} from "@headlessui/react";
import UserDeleteAndRestoreTab from "./UserDeleteAndRestoreTab";
import useUser from "../../query/user/useUser";

const pageCount = 10;
const UserDetail = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    const { userId } = useParams<{userId: string}>();
    if (!userId) {
        throw Error("userId is not given.")
    }

    const { data: user } = useUser(userId);
    const [page, setPage] = useState<number>(1);
    const defaultPage = useMemo(() => {
        return page || 1
    }, [page])

    const {data: channelPagination} = useChannelsByUserId(userId, defaultPage, 10)
    const onClickPage = useCallback((page: number) => {
        setPage(page)
    }, [setPage])

    const lastPage = useMemo(() => {
        if (!channelPagination) return 0;
        return Math.floor(channelPagination.count / pageCount + ((channelPagination.count % pageCount) && 1));
    }, [channelPagination])

    const redirect = (channel: Channel) => {
        navigate(`/channels/${channel._id}`,{state: {channel: channel}});
    }
    const onClickBack = (e: React.MouseEvent) => {
        e.preventDefault();
        navigate(-1);
    };

    const tabClassName = (selected: boolean) => {
        return selected
            ? "transition-colors duration-200 text-gray-500 pb-3 focus:outline-none text-gray-900 border-b border-black font-bold"
            : "transition-colors duration-200 text-gray-500 pb-3 focus:outline-none"
    }

    if (!user) {
        return <></>
    }

    return (
        <div>
            <div className="m-5 px-2 pb-5 border-b-2 flex justify-between">
                <div className={"flex items-center text-2xl font-semibold"}>
                    <button  onClick={onClickBack}>
                        <ChevronLeftIcon className="w-6 h-6 mr-5"/>
                    </button>
                    계정: {user.fullName} ({user.email})
                </div>
            </div>
            <div className="p-5">
                <Tab.Group defaultIndex={0}>
                    <Tab.List className="flex border-b space-x-5 mb-5">
                        <Tab className={({selected}) => tabClassName(selected)}>
                            채널 목록
                        </Tab>
                        <Tab className={({selected}) => tabClassName(selected)}>
                            삭제 및 복구
                        </Tab>
                    </Tab.List>
                    <Tab.Panels>
                        <Tab.Panel>
                            <table className="w-full p-5 table-fixed break-keep sm:rounded-lg border-2">
                                <thead className="w-full text-sm font-bold text-left border-b bg-gray-200">
                                <tr className="text-[16px]">
                                    <th scope="col" className="p-5 py-4 text-left w-[10%]">
                                        {intl.formatMessage({id: "i1001"})}
                                    </th>
                                    <th scope="col" className="p-2.5 py-4 text-left w-[10%]">
                                        {intl.formatMessage({id: "i1002"})}
                                    </th>
                                    <th scope="col" className="p-2.5 py-4 text-left w-[10%]">
                                        채널 설정 프리셋
                                    </th>
                                    <th scope="col" className="p-2.5 py-4 text-left w-[8%]">
                                        요금제
                                    </th>
                                    <th scope="col" className="p-2.5 py-4 text-left w-[15%]">
                                        메시지 사용량
                                    </th>
                                    <th scope="col" className="p-2.5 py-4 text-left w-[8%]">
                                        채널 관리자 이름
                                    </th>
                                    {/*<th scope="col" className="p-2.5 py-4 text-left w-[12%]">*/}
                                    {/*    권한*/}
                                    {/*</th>*/}
                                    <th scope="col" className="p-2.5 py-4 text-left w-[12%]">
                                        채널 생성일
                                    </th>
                                    <th scope="col" className="p-2.5 py-4 text-left w-[8%]">
                                        챗봇 테스트
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {channelPagination?.channels.map((channel) => {
                                    return (
                                        <ChannelRow
                                            key={channel._id}
                                            channel={channel}
                                            role={channel.role?.name}
                                            redirect={()=>{redirect(channel)}}
                                        />
                                    )
                                })}
                                </tbody>
                            </table>
                            <div>
                                <PageBar page={page} lastPage={lastPage} onClickPage={onClickPage}/>
                            </div>
                        </Tab.Panel>
                        <Tab.Panel>
                            <UserDeleteAndRestoreTab user={user}/>
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </div>
    )
}

export default UserDetail;
