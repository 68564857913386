import {Modal} from "../../hook/useModal";
import React, {useState} from "react";
import moment from "moment/moment";
import {useIntl} from "react-intl";
import useChannelCommonSettingHistory from "../../query/channelCommonSetting/useChannelCommonSettingHistory";
import {ChannelCommonSettingAggregation, ChannelCommonSettingHistory} from "../../model/ChannelCommonSettingHistory";
import ChannelCommonSettingHistoryAgentTab from "./history/ChannelCommonSettingHistoryAgentTab";
import classNames from "classnames";

export const CHANNEL_COMMON_SETTING_HISTORY_MODAL_ID = "channel-common-setting-history-modal-id";

type ChannelCommonSettingHistoryModalProps = {
    channelCommonSettingId: string
}

const ChannelCommonSettingHistoryModal:React.FC<ChannelCommonSettingHistoryModalProps> = (props) => {
    const {channelCommonSettingId} = props;
    const intl = useIntl();
    const {data: channelCommonSettingHistories} = useChannelCommonSettingHistory(channelCommonSettingId);

    const [showHistoryDetail, setShowHistoryDetail] = useState(false);
    const [selectedChannelCommonSetting, setSelectedChannelCommonSetting] = useState<ChannelCommonSettingAggregation>();
    const [selectedId, setSelectedId] = useState<string>();

    const onClickHistoryDetail = (channelCommonSettingHistory: ChannelCommonSettingHistory) => {
        setShowHistoryDetail(true);
        setSelectedChannelCommonSetting(channelCommonSettingHistory.channelCommonSetting)
        setSelectedId(channelCommonSettingHistory._id)
    }

    return (
        <Modal id={CHANNEL_COMMON_SETTING_HISTORY_MODAL_ID}
               size="2xl"
               title="채널 설정 프리셋 변경이력"
        >
            <div className="border-b-2 pb-5"/>
            <div className="flex w-full min-h-[800px]">
                <div className="flex-[0_0_20%] min-w-[300px] bg-gradient-to-b from-gray-900 to-gray-800 border-r border-r-2 flex flex-col text-white">
                    {
                        channelCommonSettingHistories?.length === 0 &&
                        <div className="flex pt-3 text-xl justify-center items-center font-semibold">
                            변경 이력 없음
                        </div>
                    }
                    {
                        channelCommonSettingHistories && channelCommonSettingHistories?.length > 0 &&
                        <div className="w-full">
                            <label className="flex pt-3 text-xl justify-center items-center font-semibold">변경 이력</label>
                            <div className="py-5 space-y-2">
                                {channelCommonSettingHistories.map((channelCommonSettingHistory) => {
                                    const selectedHistory = selectedId === channelCommonSettingHistory._id;
                                    return (
                                        <div key={channelCommonSettingHistory.version}
                                             className={classNames("flex justify-center items-center py-2 mx-2",
                                                 selectedHistory && "bg-gray-700 text-blue-500")}>
                                            <button onClick={() => {onClickHistoryDetail(channelCommonSettingHistory)}}
                                                    className="w-full text-lg">
                                                Version {channelCommonSettingHistory.version}
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </div>
                <div className="w-full py-5 px-5 flex justify-between">
                    {
                        showHistoryDetail &&
                        <div className="w-[1000px] space-y-5">
                            <div className="border border-gray-400 rounded-md shadow-lg p-5 flex flex-col overflow-y-auto">
                                <div className="text-2xl font-semibold mb-3 border-b-2 pb-3">
                                    <div className="flex items-center justify-between">
                                        <div className={"flex justify-between items-center"}>
                                            <p>채널 설정 프리셋</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-input-group flex">
                                    <label className="form-label min-w-[200px] text-lg">
                                        프롬프트 키
                                    </label>
                                    <div className="form-input w-full focus:outline-none">
                                        {selectedChannelCommonSetting?.key}
                                    </div>
                                </div>

                                <div className="form-input-group flex">
                                    <label className="form-label min-w-[200px] text-lg">
                                        설명
                                    </label>
                                    <div className="form-input w-full focus:outline-none">
                                        {selectedChannelCommonSetting?.description}
                                    </div>
                                </div>

                                <div className="form-input-group flex items-center">
                                    <label className="form-label min-w-[200px] text-lg">
                                        디폴트
                                    </label>
                                    <input type="checkbox"
                                           checked={selectedChannelCommonSetting?.default}
                                           readOnly={true}
                                           className="form-checkbox focus:outline-none"
                                    />
                                </div>

                                <div className="form-input-group flex">
                                    <label className="form-label min-w-[200px] text-lg">
                                        검색엔진
                                    </label>
                                    <div className="form-input w-full focus:outline-none">
                                        {selectedChannelCommonSetting?.searchSpecification.passageSearchSetting.type}
                                    </div>
                                </div>

                                <div className="form-input-group flex">
                                    <label className="form-label min-w-[200px] text-lg">
                                        검색엔진 설정키
                                    </label>
                                    <div className="form-input w-full focus:outline-none">
                                        {selectedChannelCommonSetting?.searchSpecification.key}
                                    </div>
                                </div>

                                <div className="form-input-group flex items-center">
                                    <label className="form-label min-w-[150px] text-lg">
                                        {intl.formatMessage({id: "i0196"})}
                                    </label>
                                    <p>{moment.utc(selectedChannelCommonSetting?.createdAt).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                                </div>

                                <div className="form-input-group flex items-center">
                                    <label className="form-label min-w-[150px] text-lg">
                                        {intl.formatMessage({id: "i0197"})}
                                    </label>
                                    <p>{moment.utc(selectedChannelCommonSetting?.updatedAt).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                                </div>
                            </div>
                            {   selectedChannelCommonSetting &&
                                <ChannelCommonSettingHistoryAgentTab selectedChannelCommonSetting={selectedChannelCommonSetting}/>
                            }
                        </div>
                    }
                </div>
            </div>
        </Modal>
    )
}

export default ChannelCommonSettingHistoryModal;

