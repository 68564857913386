import axios from "axios";
import {API_HOST} from "../core/variables";

export interface TestSetting {
    channelId: string
    channelCommonSettingId: string
}

class QueryAnswerRepository {
    async sendTestQuery(query: string, testSetting: TestSetting) {
        return axios.post(`${API_HOST}/v1/question-inspect/query`, {
            query,
            testSetting
        }, {timeout: 120000});
    };

    async getReplyQuestion(query: string, testSetting: TestSetting) {
        return axios.post(`${API_HOST}/v1/question-inspect/reply-question`, {
            query,
            testSetting
        });
    };
}

const queryAnswerRepository = new QueryAnswerRepository();

export default queryAnswerRepository;
