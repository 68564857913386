import { useQuery } from "@tanstack/react-query";
import channelRepository, { SearchType } from "../../respsitory/ChannelRepository";
import { ChannelPage, Language } from "../../model/channel/channel";
const getData = async (pageNum: number, size?: number, searchType?: SearchType, searchKeyword?: string, deleted?: boolean,
                       language?: Language, star?: boolean, groupId?: string, userId?: string) => {
    try {
        const { data } = await channelRepository.find(pageNum, size, searchType, searchKeyword, deleted, language, star, groupId, userId);
        return data.result;
    } catch (e) {
        throw e;
    }
}

const useChannels = (pageNum: number, size?: number, searchType?: SearchType, searchKeyword?: string, deleted?: boolean,
                     language?: Language, star?: boolean ,groupId?: string, userId?: string) => {
    return useQuery<ChannelPage, Error>({
        queryKey: ['channels', pageNum, size, searchType, searchKeyword, deleted, language, star, groupId, userId],
        queryFn: async () => getData(pageNum, size, searchType, searchKeyword, deleted, language, star, groupId, userId),
        retry: false,
        suspense: false,
    })
}

export default useChannels;
