import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {AnalyticsResponseV2} from "../../model/analytics/analytics";
import analyticsRepository from "../../respsitory/AnalyticsRepository";

const getData = async (startDate?: string, endDate?: string) => {
    try {
        const { data } = await analyticsRepository.getBasicStatistics(startDate, endDate);
        return data.result;
    } catch (e) {
        throw e;
    }
}

const useBasicStatistics = (startDate?: string, endDate?: string) => {
    return useQuery<AnalyticsResponseV2, AxiosError>({
        queryKey: ["basic-statistics", startDate, endDate],
        queryFn: async () => getData(startDate, endDate),
        retry: false,
        suspense: false,
        useErrorBoundary: false,
        keepPreviousData: true
    })
}

export default useBasicStatistics;
