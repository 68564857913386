import {useMutation, useQueryClient} from "@tanstack/react-query";
import {Prompt, PromptCreateRequest} from "../../model/prompt/prompt";
import {AxiosError} from "axios";
import promptRepository from "../../respsitory/PromptRepository";

const useCreatePrompt = () => {
    const queryClient = useQueryClient();

    return useMutation<Prompt, AxiosError, PromptCreateRequest>({
        mutationKey: ["prompt", "create"],
        mutationFn: async (body: PromptCreateRequest) => {
            try {
                const {data} = await promptRepository.create(body);
                return data.result;
            } catch (e) {
                throw e;
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["prompt"],
                exact: false
            });
        }
    })
};

export default useCreatePrompt;