import React, {Dispatch, useMemo} from "react";
import {PromptContentItem} from "../../../model/prompt/prompt";
import PromptInputForm from "../PromptInputForm";
import GuideMainView from "../guide/GuideMainView";

type LLM42FormProps = {
    invalid: { key: boolean, llmType: boolean, promptCheck: boolean },
    content: PromptContentItem[],
    setContent: Dispatch<React.SetStateAction<PromptContentItem[]>>,
    selectedPromptType: string,
}

const LLM42Form: React.FC<LLM42FormProps> = (props) => {
    const {invalid, content, setContent, selectedPromptType} = props;
    const llm42Prompt = useMemo(() => {
        if (content.length > 0) {
            if (content.some(item => item.role === "system")) {
                setContent([])
                return [{role: "user", content: ""}]
            } else {
                return content.filter(item => item.role === "user")
            }
        } else {
            return[{role: "user", content: ""}]
        }
    }, [content, setContent])
    const userPrompt = useMemo(() => {
        return llm42Prompt.filter(item => item.role === "user")
    }, [llm42Prompt])

    const onChangeUserPrompt = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        const userContent: PromptContentItem = {
            role: name,
            content: value
        }
        updateContent(userContent, "user")
    }

    const updateContent = (newContent: PromptContentItem, contentType: string) => {
        const contentIndex = llm42Prompt.findIndex(item => item.role === contentType);
        if (contentIndex !== -1) {
            llm42Prompt[contentIndex] = newContent;
        }
        setContent(llm42Prompt);
    }

    return (
        <div className="w-full flex-grow">
            {userPrompt.map((item) => {
                const {role, content} = item;
                return (
                    <PromptInputForm key={role} value="User" name={role} prompt={content}
                                     onChange={onChangeUserPrompt}
                                     invalid={invalid}/>
                )
            })}
            <GuideMainView selectedPromptType={selectedPromptType}/>
        </div>
    )
}

export default LLM42Form;
