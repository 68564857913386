import React, {useEffect, useMemo, useRef, useState} from "react";
import _ from "lodash";
import toast from "react-hot-toast";
import {useDialog} from "../../hook/useDialog";
import moment from "moment";
import {useIntl} from "react-intl";
import {
    GlobalVariables, GlobalVariablesBase,
    GlobalVariablesCreateRequest,
    GlobalVariablesUpdateRequest
} from "../../model/GlobalVariables";
import {useCreateGlobalVariables} from "../../query/globalVariables/useCreateGlobalVariables";
import {useUpdateGlobalVariables} from "../../query/globalVariables/useUpdateGlobalVariables";
import {useDeleteGlobalVariables} from "../../query/globalVariables/useDeleteGlobalVariables";
import globalVariablesRepository from "../../respsitory/GlobalVariablesRepository";
import useGlobalVariableGroup from "../../query/globalVariableGroup/useGlobalVariableGroup";
import useModal from "../../hook/useModal";
import GlobalVariableHistoryModal, {GLOBAL_VARIABLE_HISTORY_MODAL_ID} from "./GlobalVariableHistoryModal";
import {useQueryClient} from "@tanstack/react-query";

type GlobalVariablesSettingFormProps = {
    globalVariable?: GlobalVariables;
    copyGlobalVariable?: GlobalVariablesBase;
    onCancel: () => void;
    onDelete: () => void;
    onSubmit: (globalVariableKey: string) => void;
    onClickCopyGlobalVariables: (globalVariable: GlobalVariablesBase) => void
}

const INIT_INVALID = {
    key: false,
    prompt: false,
}

const GlobalVariablesSettingForm: React.FC<GlobalVariablesSettingFormProps> = (props) => {
    const {globalVariable, copyGlobalVariable, onCancel, onDelete, onSubmit, onClickCopyGlobalVariables} = props;
    const intl = useIntl();
    const dialog = useDialog();
    const modal = useModal();
    const queryClient = useQueryClient();

    const {mutateAsync: createGlobalVariables} = useCreateGlobalVariables();
    const {mutateAsync: updateGlobalVariables} = useUpdateGlobalVariables();
    const {mutateAsync: deleteGlobalVariables} = useDeleteGlobalVariables();
    const {data: globalVariableGroups} = useGlobalVariableGroup();

    const keyRef = useRef<HTMLInputElement>(null);
    const descriptionRef = useRef<HTMLInputElement>(null);
    const promptRef = useRef<HTMLTextAreaElement>(null);

    const globalVariableKey = useMemo(() => {
        return globalVariable ? globalVariable.key : copyGlobalVariable?.key;
    }, [globalVariable, copyGlobalVariable])

    const globalVariableDescription = useMemo(() => {
        return globalVariable ? globalVariable.description : copyGlobalVariable?.description;
    }, [globalVariable, copyGlobalVariable])

    const globalVariablePrompt = useMemo(() => {
        return globalVariable? globalVariable.content : copyGlobalVariable?.content;
    }, [globalVariable, copyGlobalVariable])


    const [invalid, setInvalid] = useState<{ key: boolean, prompt: boolean }>(INIT_INVALID);
    const [variableGroup, setVariableGroup] = useState<string | undefined>();

    const onChangeKey = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        const key = e.target.value;
        if (globalVariable?.key === key) return;
        globalVariablesRepository.existed(key).then(({data}) => {
            setInvalid(prev => ({
                ...prev,
                key: data.result
            }))
        }).catch(() => {
            toast.error(intl.formatMessage({id: "i0167"}))
        })
    }, 200);

    const onClickDelete = () => {
        if (!globalVariable) return;
        dialog.open({
            variant: "danger",
            title: "글로벌 변수 삭제",
            content: "글로벌 변수를 삭제하시겠습니까?",
            onConfirm: async () => {
                try {
                    await deleteGlobalVariables(globalVariable._id);
                    toast.success("글로벌 변수 정보를 삭제했습니다.");
                    onDelete();
                } catch (e) {
                    toast.error("글로벌 변수 정보를 삭제하는 도중 에러가 발생했습니다.");
                }
            }
        });
    }

    const onClickCancel = () => {
        onCancel();
    }

    const onChangeVariableGroup = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const variableGroup = e.target.value;
        if (variableGroup === "-") {
            setVariableGroup(undefined);
        } else {
            setVariableGroup(e.target.value);
        }
    }

    const onClickSubmit = async () => {
        const key = keyRef.current?.value;
        const description = descriptionRef.current?.value;

        const prompt = promptRef.current?.value ?? "";

        const invalid = {
            key: !key,
            prompt: !prompt,
        }
        setInvalid(invalid);
        if (Object.values(invalid).some(v => v)) {
            toast.error(intl.formatMessage({id: "i0176"}))
            return;
        }

        if (globalVariable) {
            const body: GlobalVariablesUpdateRequest = {
                group: variableGroup!,
                description,
                content: prompt,
            }
            dialog.open({
                variant: "danger",
                title: `글로벌 변수 수정`,
                content: `글로벌 변수를 수정하려고 합니다. 계속 하시겠습니까?`,
                onConfirm: async () => {
                    try {
                        await updateGlobalVariables({_id: globalVariable._id, ...body});
                        toast.success("글로벌 변수 정보를 수정했습니다.")
                        await queryClient.invalidateQueries(["globalVariableHistory", globalVariable._id], {exact: true});
                        onSubmit(globalVariable.key);
                    } catch (e) {
                        toast.error("글로벌 변수 정보를 수정하는 도중 에러가 발생했습니다.")
                    }
                }
            });
        } else {
            const body: GlobalVariablesCreateRequest = {
                key: key!,
                group: variableGroup!,
                description,
                content: prompt
            }
            try {
                await createGlobalVariables(body);
                toast.success("글로벌 변수를 생성했습니다.")
                onSubmit(body.key);
            } catch (e) {
                toast.error("글로벌 변수를 생성하는 도중 에러가 발생했습니다.")
            }
        }
    }

    const onClickCopy = () => {
        if (!globalVariable) return;
        const key = globalVariable.key
        const currentDate = moment().format('YYYY-MM-DD HH:mm');
        const copiedKey = `${key}_copied(${currentDate})`
        const copyGlobalVariable = {...globalVariable, key: copiedKey}
        onClickCopyGlobalVariables(copyGlobalVariable)
    }

    const onClickShowHistoryModal = () => {
        modal.open(GLOBAL_VARIABLE_HISTORY_MODAL_ID)
    }

    useEffect(() => {
        const data = globalVariable ?? copyGlobalVariable;
        if (data) {
            setVariableGroup(data.group);
        }
    }, [globalVariable, copyGlobalVariable])

    return (
        <div>
            <div className="text-xl font-semibold mb-2">
                <div className="flex items-center justify-end pt-3 space-x-2">
                    {globalVariable &&
                        <div className="flex items-center justify-between space-x-2">
                            <button className="btn bg-purple-800 text-white transition-colors duration-200"
                                    onClick={onClickShowHistoryModal}>
                                변경이력 보기
                            </button>
                            <GlobalVariableHistoryModal variableId={globalVariable._id}/>
                            <button className="btn bg-green-500 text-white transition-colors duration-200"
                                    onClick={onClickCopy}>
                                사본생성
                            </button>
                        </div>
                    }
                    <button className="btn btn-primary transition-colors duration-200" onClick={onClickSubmit}>
                        {globalVariable ? intl.formatMessage({id: "i0200"}) : intl.formatMessage({id: "i0201"})}
                    </button>
                    {globalVariable &&
                        <button className="btn btn-danger transition-colors duration-200" onClick={onClickDelete}>
                            {intl.formatMessage({id: "i0199"})}
                        </button>
                    }
                    <button className="btn btn-secondary-outline transition-colors duration-200"
                            onClick={onClickCancel}>
                        {intl.formatMessage({id: "i0198"})}
                    </button>
                </div>
            </div>
            <div className="border border-gray-400 rounded-md shadow-lg p-5 flex flex-col overflow-y-auto">
                <div className="text-xl font-semibold mb-3 border-b-2 pb-3">
                    {globalVariable ?
                        <div className={"flex justify-between items-center"}>
                            <p>글로벌 변수 수정하기</p>
                        </div>
                        :
                        <p>새로운 글로벌 변수 추가하기</p>
                    }
                </div>

                <div className="form-input-group flex">
                    <label htmlFor="globalVariable-key"
                           className="form-label min-w-[150px] text-lg">
                        Key
                    </label>
                    <div className="w-full">
                        <input id="globalVariable-key"
                               defaultValue={globalVariableKey}
                               ref={keyRef}
                               onChange={onChangeKey}
                               disabled={!!globalVariable}
                               className="form-input w-full focus:outline-none"
                        />
                        {invalid.key && <small className="text-red-500">이미 존재하는 Key는 사용할수없습니다.</small>}
                    </div>
                </div>

                <div className="form-input-group flex">
                    <label htmlFor="globalVariable-description"
                           className="form-label min-w-[150px] text-lg">
                        {intl.formatMessage({id: "i0185"})}
                    </label>
                    <input id="globalVariable-description"
                           defaultValue={globalVariableDescription}
                           ref={descriptionRef}
                           className="form-input w-full focus:outline-none"
                    />
                </div>

                <div className="form-input-group flex">
                    <label htmlFor="globalVariable-description"
                           className="form-label min-w-[150px] text-lg">
                        Group
                    </label>
                    <select className="form-select w-full px-2 focus:outline-none bg-white"
                            value={variableGroup ?? ''}
                            onChange={onChangeVariableGroup}
                    >
                        <option value="-">-</option>
                        {globalVariableGroups?.map((globalVariableGroup) => {
                                const {_id, key} = globalVariableGroup;
                                return (
                                    <option key={_id} value={_id}>{key}</option>
                                )
                            }
                        )}
                    </select>
                </div>

                <div className="form-input-group flex">
                    <label htmlFor="globalVariable-prompt"
                           className="form-label min-w-[150px] text-lg">
                        Content
                    </label>
                    <div className="w-full">
                    <textarea id="message" rows={8}
                              className="block p-2.5 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                              placeholder="변수 프롬프트를 입력해주세요"
                              ref={promptRef}
                              defaultValue={globalVariablePrompt}
                    />
                        {invalid.prompt && <small className="text-red-500">Prompt 값은 필수입니다.</small>}
                    </div>
                </div>

                {globalVariable &&
                    <>
                        <div className="form-input-group flex items-center">
                            <label className="form-label min-w-[150px] text-lg">
                                {intl.formatMessage({id: "i0196"})}
                            </label>
                            <p>{moment.utc(globalVariable.createdAt).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                        </div>

                        <div className="form-input-group flex items-center">
                            <label className="form-label min-w-[150px] text-lg">
                                {intl.formatMessage({id: "i0197"})}
                            </label>
                            <p>{moment.utc(globalVariable.updatedAt).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                        </div>
                    </>
                }
            </div>
        </div>
    )
};

export default GlobalVariablesSettingForm;
