import channelRepository from "../../respsitory/ChannelRepository";
import {AxiosError} from "axios";
import {useQuery} from "@tanstack/react-query";
import {ChannelCard} from "../../model/transaction/transaction";

const getDate = async (channelId: string) => {
    try {
        const {data} = await channelRepository.readChannelCard(channelId);
        return data.result;
    } catch (e) {
        throw e;
    }
}

const useChannelCard = (channelId: string) => {
    return useQuery<ChannelCard, AxiosError>({
        queryKey: ["channelCard", channelId],
        queryFn: async () => getDate(channelId),
        retry: false,
        suspense: false
    })
}

export default useChannelCard;
