import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {Channel} from "../../model/channel/channel";
import channelRepository from "../../respsitory/ChannelRepository";
export interface ChannelUpdateStarRequest {
    channelId: string
    star: boolean
}

const useUpdateChannelStar = () => {
    const queryClient = useQueryClient();
    return useMutation<Channel, AxiosError, ChannelUpdateStarRequest>({
        mutationKey: ["channel", "update", "star"],
        mutationFn: async (body: ChannelUpdateStarRequest) => {
            try {
                const { data } = await channelRepository.updateStar(body);
                return data.result;
            } catch (e) {
                throw e;
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(["channels"], {exact: false});
        }
    })
};

export default useUpdateChannelStar;