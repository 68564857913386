import {Tab} from "@headlessui/react";
import React, {useMemo} from "react";
import {SearchEngineType} from "../../../model/searchSpecification";
import classNames from "classnames";

import AggregationAgentContent from "./AgentContent";
import {ChannelCommonSettingAggregation, QaAggregation42Setting} from "../../../model/ChannelCommonSettingHistory";

type ChannelCommonSettingHistoryAgentTabProps = {
    selectedChannelCommonSetting: ChannelCommonSettingAggregation
}

const ChannelCommonSettingHistoryAgentTab: React.FC<ChannelCommonSettingHistoryAgentTabProps> = (props) => {
    const {selectedChannelCommonSetting} = props;
    const config = selectedChannelCommonSetting.config
    const tabList = useMemo(() => [
        {id: 1, title: "요약", disabled: false},
        {id: 2, title: "질의 분석", disabled: false},
        {id: 3, title: "질의 생성", disabled: false},
        {id: 4, title: "답변 생성", disabled: false},
        {id: 5, title: "큐레이팅", disabled: false},
    ], [])
    const tabClassName = (selected: boolean) => {
        return selected
            ? "transition-colors duration-200 text-gray-500 pb-3 focus:outline-none text-gray-900 border-b border-black"
            : "transition-colors duration-200 text-gray-500 pb-3 focus:outline-none"
    }

    return (
        <div className={classNames("border border-gray-400 rounded-md shadow-lg p-5 pb-7")}>
            <div className="text-xl font-semibold mb-3 border-b-2 pb-3">
                <div className="flex items-center justify-between">
                    <div className={"flex justify-between items-center"}>
                        <p>에이전트 설정</p>
                    </div>
                </div>
            </div>
            <Tab.Group defaultIndex={0}>
                <Tab.List className="bg-white h-auto flex space-x-7 border-b">
                    {tabList.map(tab => (
                        <Tab key={tab.id}
                             disabled={tab.disabled}
                             className={({selected}) => tabClassName(selected)}>
                            {tab.title}
                        </Tab>
                    ))}
                </Tab.List>
                <Tab.Panels>
                    <Tab.Panel>
                        <div>
                            <AggregationAgentContent title={"월컴 메세지 생성"} agent={config.welcomeAgent}/>
                        </div>
                    </Tab.Panel>
                    <Tab.Panel>
                        <div>
                            <AggregationAgentContent title={"한글 질의 분석"} agent={config.analyzeKoreanAgent}/>
                            <AggregationAgentContent title={"영어 질의 분석"} agent={config.analyzeEnglishAgent}/>
                        </div>
                    </Tab.Panel>
                    <Tab.Panel>
                        <div>
                            <AggregationAgentContent title={"월컴 추천 질의 생성"} agent={config.welcomeQuestionAgent}/>
                            <AggregationAgentContent title={"후속 질의 생성"} agent={config.contextQuestionAgent}/>
                        </div>
                    </Tab.Panel>
                    <Tab.Panel>
                        <div>
                            <AggregationAgentContent title={"대화이려 기반 답변 생성"} agent={config.contextAnswerAgent}/>
                            <AggregationAgentContent title={"Passage 답변 생성"} agent={config.passageAnswerAgent}/>
                            {
                                selectedChannelCommonSetting.searchSpecification.passageSearchSetting.type === SearchEngineType.QA42 &&
                                <AggregationAgentContent title={"MRC 답변 생성"} agent={(config as QaAggregation42Setting).mrcAnswerAgent}/>
                            }
                        </div>
                    </Tab.Panel>
                    <Tab.Panel>
                        <div>
                            <AggregationAgentContent title={"채널 큐레이팅"} agent={config.channelCuratingAgent}/>
                        </div>
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    )
}

export default ChannelCommonSettingHistoryAgentTab;
