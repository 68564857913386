import {Modal} from "../../hook/useModal";
import React, {useMemo, useState} from "react";
import moment from "moment/moment";
import {useIntl} from "react-intl";
import useGlobalVariableHistory from "../../query/globalVariables/useGlobalVariableHistory";
import {GlobalVariables, GlobalVariablesHistory} from "../../model/GlobalVariables";
import useGlobalVariableGroup from "../../query/globalVariableGroup/useGlobalVariableGroup";
import classNames from "classnames";

export const GLOBAL_VARIABLE_HISTORY_MODAL_ID = "global-variable-history-modal-id";

type GlobalVariableHistoryModalProps = {
    variableId: string
}

const GlobalVariableHistoryModal:React.FC<GlobalVariableHistoryModalProps> = (props) => {
    const {variableId} = props;
    const intl = useIntl();
    const {data: globalVariableHistories} = useGlobalVariableHistory(variableId);
    const {data: globalVariableGroups} = useGlobalVariableGroup();

    const [showHistoryDetail, setShowHistoryDetail] = useState(false);
    const [selectedGlobalVariable, setSelectedGlobalVariable] = useState<GlobalVariables>();
    const [selectedId, setSelectedId] = useState<string>();

    const onClickHistoryDetail = (globalVariableHistory: GlobalVariablesHistory) => {
        setShowHistoryDetail(true);
        setSelectedGlobalVariable(globalVariableHistory.globalVariable)
        setSelectedId(globalVariableHistory._id)
    }

    const globalVariableGroup = useMemo(() => {
        return globalVariableGroups?.filter(globalVariableGroup => globalVariableGroup._id === selectedGlobalVariable?.group)[0]
    }, [selectedGlobalVariable, globalVariableGroups])

    return (
        <Modal id={GLOBAL_VARIABLE_HISTORY_MODAL_ID}
               size="2xl"
               title="글로벌 변수 변경이력"
        >
            <div className="border-b-2 pb-5"/>
            <div className="flex w-full min-h-[800px]">
                <div className="flex-[0_0_20%] min-w-[300px] bg-gradient-to-b from-gray-900 to-gray-800 border-r border-r-2 flex flex-col text-white">
                    {
                        globalVariableHistories?.length === 0 &&
                        <div className="flex pt-3 text-xl justify-center items-center font-semibold">
                            변경 이력 없음
                        </div>
                    }
                    {
                        globalVariableHistories && globalVariableHistories?.length > 0 &&
                        <div className="w-full">
                            <label className="flex pt-3 text-xl justify-center items-center font-semibold">변경 이력</label>
                            <div className="py-5 space-y-2">
                                {globalVariableHistories.map((globalVariableHistory) => {
                                    const selectedHistory = selectedId === globalVariableHistory._id;

                                    return (
                                        <div key={globalVariableHistory.version}
                                             className={classNames("flex justify-center items-center py-2 mx-2",
                                                selectedHistory && "bg-gray-700 text-blue-500")}>
                                            <button onClick={() => {onClickHistoryDetail(globalVariableHistory)}}
                                                    className="w-full text-lg">
                                                Version {globalVariableHistory.version}
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </div>
                <div className="w-full py-5 px-5 flex justify-between">
                    {
                        showHistoryDetail &&
                        <div className="w-[1000px] space-y-5">
                            <div className="border border-gray-400 rounded-md shadow-lg p-5 flex flex-col overflow-y-auto">
                                <div className="text-2xl font-semibold mb-3 border-b-2 pb-3">
                                    <div className="flex items-center justify-between">
                                        <div className={"flex justify-between items-center"}>
                                            <p>글로벌 변수 설정</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-input-group flex">
                                    <label className="form-label min-w-[200px] text-lg">
                                        글로벌 변수 키
                                    </label>
                                    <div className="form-input w-full focus:outline-none">
                                        {selectedGlobalVariable?.key}
                                    </div>
                                </div>

                                <div className="form-input-group flex">
                                    <label className="form-label min-w-[200px] text-lg">
                                        설명
                                    </label>
                                    <div className="form-input w-full focus:outline-none">
                                        {selectedGlobalVariable?.description}
                                    </div>
                                </div>

                                <div className="form-input-group flex">
                                    <label className="form-label min-w-[200px] text-lg">
                                        Group
                                    </label>
                                    <div className="form-input w-full focus:outline-none">
                                        {globalVariableGroup?.key }
                                    </div>
                                </div>
                                <div className="form-input-group flex">
                                    <label className="form-label min-w-[200px] text-lg">
                                        Content
                                    </label>
                                    <textarea className="form-input w-full focus:outline-none"
                                              readOnly={true}
                                              value={selectedGlobalVariable?.content}
                                    />
                                </div>

                                <div className="form-input-group flex items-center">
                                    <label className="form-label min-w-[150px] text-lg">
                                        {intl.formatMessage({id: "i0196"})}
                                    </label>
                                    <p>{moment.utc(selectedGlobalVariable?.createdAt).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                                </div>

                                <div className="form-input-group flex items-center">
                                    <label className="form-label min-w-[150px] text-lg">
                                        {intl.formatMessage({id: "i0197"})}
                                    </label>
                                    <p>{moment.utc(selectedGlobalVariable?.updatedAt).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Modal>
    )
}

export default GlobalVariableHistoryModal;

