import userRepository from "../../respsitory/UserRepository";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {ChannelResponsePage} from "../../model/User";

const getDate = async (userId: string, pageNum: number, size?: number) => {
    try {
        const {data} = await userRepository.findChannelsByUserId(userId, pageNum, size);
        return data.result;
    } catch (e) {
        throw e;
    }
}

const useChannelsByUserId = (userId: string, pageNum: number, size?: number) => {
    return useQuery<ChannelResponsePage,AxiosError>({
        queryKey: ['channelsByUserId', userId, pageNum, size],
        queryFn: async () => getDate(userId, pageNum, size),
        retry: false,
        suspense: false
    })
}

export default useChannelsByUserId;
