import React, {useMemo} from "react";
import {ChannelResponse, Language} from "../../../model/channel/channel";

import {
    INTEGRATION_CM_URL,
    INTEGRATION_ONBOARDING_URL,
    INTEGRATION_QA42_URL,
    INTEGRATION_WEBCHAT_URL
} from "../../../core/variables";
import moment from "moment/moment";
import useChannelCommonSetting from "../../../query/channelCommonSetting/useChannelCommonSetting";

type ChannelSettingViewProps = {
    channel: ChannelResponse
}

const ChannelInfo: React.FC<ChannelSettingViewProps> = (props) => {
    const {channel} = props;
    const {data: channelCommonSettings} = useChannelCommonSetting();
    const channelCommonSettingKey = useMemo(() => {
        const channelCommonSetting = channelCommonSettings?.filter(setting => setting._id === channel.setting.channelCommonSettingId)[0]
        if (channelCommonSetting) return channelCommonSetting.key
    }, [channelCommonSettings, channel.setting.channelCommonSettingId])
    return (
        <div className="m-5">
            <div>
                <div className="w-full flex flex-col space-y-2 mt-5 p-5 text-[16px]">
                    <div className="border-b-4 border-gray-200 space-y-3 pb-6">
                        <div className="flex items-center">
                            <label className="font-bold mr-6">채널 정보</label>
                            <div className="mr-1">채널 생성일:</div>
                            {moment.utc(channel.createdAt).local().format("YYYY-MM-DD HH:mm:ss")}
                        </div>
                        <div className="mx-2 space-y-3 text-[14px]">
                            <div>
                                채널명 : {channel.name}
                            </div>
                            <div>
                                채널 설명 : {channel.description}
                            </div>
                            <div>
                                채널 설정 프리셋 : {channelCommonSettingKey}
                            </div>
                        </div>
                    </div>
                    <div className="border-b-4 border-gray-200 space-y-3 py-6 text-[16px]">
                        <div className="flex items-center justify-between">
                            <p className="font-bold">채널 언어</p>
                        </div>
                        <div className="mx-2">
                            {channel.setting.language === Language.KOREAN ?
                                <div>
                                    <label className="text-gray-500">
                                        한국어
                                    </label>
                                </div>
                                :
                                <div>
                                    <label className="text-gray-500">
                                        영어
                                    </label>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="py-2 text-[16px]">
                        <div className="font-bold pb-3">
                            채널 주소
                        </div>
                        <div className="space-y-2 px-2">
                            <div>
                                <div className="flex items-center justify-between">
                                    <p>챗봇 시나리오 주소</p>
                                </div>
                                <div>
                                    <a href={`${INTEGRATION_CM_URL}/scenarios/${channel.scenarioId}/analytics`}
                                       target="_blank"
                                       rel="noopener noreferrer"
                                       className="text-blue-500 text-[14px]">
                                        {`${INTEGRATION_CM_URL}/scenarios/${channel.scenarioId}/analytics`}
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="flex items-center justify-between">
                                    <p>챗봇 웹챗 주소</p>
                                </div>
                                <div>
                                    <a href={`${INTEGRATION_WEBCHAT_URL}/view/${channel.scenarioId}`}
                                       target="_blank"
                                       rel="noopener noreferrer"
                                       className="text-blue-500 text-[14px]">
                                        {`${INTEGRATION_WEBCHAT_URL}/view/${channel.scenarioId}`}
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="flex items-center justify-between">
                                    <p>온보딩 사이트 (본인 채널만 접근가능)</p>
                                </div>
                                <div>
                                    <a href={`${INTEGRATION_ONBOARDING_URL}/channels/${channel._id}/console/dashboard`}
                                       target="_blank"
                                       rel="noopener noreferrer"
                                       className="text-blue-500  text-[14px]">
                                        {`${INTEGRATION_ONBOARDING_URL}/channels/${channel._id}/console/dashboard`}
                                    </a>
                                </div>
                            </div>

                            <div>
                                {channel.setting.qa42ProjectId ?
                                    <div>
                                        <p>데이터 소스 (QA42주소)</p>
                                        <a href={`${INTEGRATION_QA42_URL}/qa42/${channel.setting.qa42ProjectId}/dashboard`}
                                           target="_blank"
                                           rel="noopener noreferrer"
                                           className="text-blue-500 text-[14px]">
                                            {`${INTEGRATION_QA42_URL}/qa42/${channel.setting.qa42ProjectId}/dashboard`}
                                        </a>
                                    </div>
                                    :
                                    <div>
                                        <p>QA42 연동 정보</p>
                                        <div>
                                            프로젝트 키 : sitebunny-source-embed-{channel.indexId} <br/>
                                            프로젝트 이름 : [SiteBunny] {channel._id} {channel.name}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChannelInfo;
