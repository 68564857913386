import React from 'react';
import ReactDOM from 'react-dom/client';
import "./assets/styles/index.scss"
import {appRouterObject} from './App';
import {AXIOS_DEFAULTS_TIMEOUT} from "./core/variables";
import axios from "axios";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {RouterProvider} from "react-router-dom";
import {RecoilRoot} from "recoil";

axios.defaults.withCredentials = true;
axios.defaults.timeout = Number(AXIOS_DEFAULTS_TIMEOUT)

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            useErrorBoundary: true,
        }
    }
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <QueryClientProvider client={queryClient}>
          <RecoilRoot>
              <RouterProvider router={appRouterObject} />
          </RecoilRoot>
      </QueryClientProvider>
  </React.StrictMode>
);
