
export interface CardInfo {
    cardCompany: string
    cardName: string
    cardType: string
}

export interface ChannelCard {
    channelId: string
    cardNumber: string
    default: boolean
    email?: string
    cardInfo?: CardInfo
}

export enum CurrencyUnit {
    KRW = 'KRW',
    USD = 'USD'
}

export interface Billing {
    currencyUnit: CurrencyUnit
    amount: number
}

export enum TransactionType {
    CHANNEL_SUBSCRIPTION = 'channel_subscription',
    SAMPLE = "sample"
}

export interface TransactionBase {
    channelId: string
    card: ChannelCard
    billing: Billing
    type: TransactionType
}


// export interface ChannelSubscriptionTransaction extends TransactionBase {
//     type: TransactionType.CHANNEL_SUBSCRIPTION
// }

export interface Transaction extends TransactionBase {
    _id: string;
    createdAt: string;
    updatedAt: string;
}
