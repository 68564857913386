import React, {useRef, useState} from "react";
import _ from "lodash";
import toast from "react-hot-toast";
import {useDialog} from "../../hook/useDialog";
import moment from "moment";
import {useIntl} from "react-intl";
import channelCommonSettingRepository from "../../respsitory/ChannelCommonSettingRepository";
import useCreateChannelGroup from "../../query/channelGroup/useCreateChannelGroup";
import useUpdateChannelGroup from "../../query/channelGroup/useUpdateChannelGroup";
import useDeleteChannelGroup from "../../query/channelGroup/useDeleteChannelGroup";
import {
    ChannelGroup,
    ChannelGroupBase,
    ChannelGroupCreateRequest,
    ChannelGroupUpdateRequest
} from "../../model/channelGroup/channelGroup";

type ChannelGroupFormProps = {
    specification?: ChannelGroup;
    copySpecification?: ChannelGroupBase;
    onCancel: () => void;
    onDelete: () => void;
    onSubmit: (key: string) => void;
    onClickCopySpecification: (specification: ChannelGroupBase) => void;
}

const INIT_INVALID = {
    key: false,
}

const ChannelGroupForm: React.FC<ChannelGroupFormProps> = (props) => {
    const {specification, onCancel, onDelete, onSubmit, onClickCopySpecification, copySpecification} = props;
    const intl = useIntl();
    const dialog = useDialog();


    const {mutateAsync: createChannelGroup} = useCreateChannelGroup();
    const {mutateAsync: updateChannelGroup} = useUpdateChannelGroup();
    const {mutateAsync: deleteChannelGroup} = useDeleteChannelGroup();

    const keyRef = useRef<HTMLInputElement>(null);
    const descriptionRef = useRef<HTMLInputElement>(null);


    const [invalid, setInvalid] = useState<{ key: boolean}>(INIT_INVALID);



    const onChangeKey = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        const key = e.target.value;
        if (specification?.key === key) return;
        channelCommonSettingRepository.existed(key).then(({data}) => {
            setInvalid(prev => ({
                ...prev,
                key: data.result
            }))
        }).catch(() => {
            toast.error(intl.formatMessage({id: "i0167"}))
        })
    }, 200);

    const onClickDelete = () => {
        if (!specification) {
            return;
        }

        dialog.open({
            variant: "danger",
            title: "채널 그룹 삭제",
            content: "채널 그룹을 삭제하시겠습니까?",
            onConfirm: async () => {
                try {
                    await deleteChannelGroup(specification._id);
                    toast.success("채널 그룹을 삭제했습니다.");
                    onDelete();
                } catch (e: any) {
                    const code = e.response.data.detail.code
                    if (code === "channelGroup") {
                        toast.error("해당 채널 그룹을 사용하는 채널이 존재합니다")
                    } else {
                        toast.error("채널 그룹을 삭제하는 도중 에러가 발생했습니다.");
                    }
                }
            }
        });

    }

    const onClickCancel = () => {
        onCancel();
    }

    const onClickSubmit = async () => {
        const key = keyRef.current?.value;
        const description = descriptionRef.current?.value;

        const invalid = {
            key: !key,
        }
        setInvalid(invalid);
        if (Object.values(invalid).some(v => v)) {
            toast.error("설정을 선택해주세요")
            return;
        }

        if (specification) {
            const body: ChannelGroupUpdateRequest = {
                key: key!,
                description,
            }
            dialog.open({
                variant: "danger",
                title: `채널 그룹 수정`,
                content: `채널 그룹을 수정하려고 합니다. 계속 하시겠습니까?`,
                onConfirm: async () => {
                    try {
                        await updateChannelGroup({_id: specification._id, ...body});
                        toast.success("채널 그룹을 수정했습니다.")
                        onSubmit(specification.key);
                    } catch (e) {
                        toast.error("채널 그룹을 수정하는 도중 에러가 발생했습니다.")
                    }
                }
            });
        } else {
            const body: ChannelGroupCreateRequest = {
                key: key!,
                description,
            }
            try {
                await createChannelGroup(body);
                toast.success("채널 그룹을 생성했습니다.")
                onSubmit(body.key);
            } catch (e) {
                toast.error("채널 그룹을 생성하는 도중 에러가 발생했습니다.")
            }
        }
    }

    const onClickCopy = async () => {
        if (!specification) return;
        const key = specification.key
        const currentDate = moment().format('YYYY-MM-DD HH:mm');
        const copiedKey = `${key}_copied(${currentDate})`
        const copyChannelCommonSetting = {...specification, key: copiedKey}
        onClickCopySpecification(copyChannelCommonSetting)
    }

    return (
        <div>
            <div className="flex items-center justify-end mb-3">
                <div className="flex items-center space-x-2">
                    {specification &&
                        <button className="btn bg-green-500 text-white transition-colors duration-200"
                                onClick={onClickCopy}>
                            사본생성
                        </button>
                    }
                    <button className="btn btn-primary transition-colors duration-200" onClick={onClickSubmit}>
                        {specification ? intl.formatMessage({id: "i0200"}) : intl.formatMessage({id: "i0201"})}
                    </button>
                    {specification &&
                        <button className="btn btn-danger transition-colors duration-200" onClick={onClickDelete}>
                            {intl.formatMessage({id: "i0199"})}
                        </button>
                    }
                    <button className="btn btn-secondary-outline transition-colors duration-200" onClick={onClickCancel}>
                        {intl.formatMessage({id: "i0198"})}
                    </button>
                </div>
            </div>

            <div className="border border-gray-400 rounded-md shadow-lg p-5 flex flex-col overflow-y-auto">
                <div className="text-xl font-semibold mb-3 border-b-2 pb-3">
                    <div className="flex items-center justify-between">
                        <div className={"flex justify-between items-center"}>
                            <p>채널 그룹 정보</p>
                        </div>
                    </div>
                </div>


                <div className="form-input-group flex">
                    <label htmlFor="persona-key"
                           className="form-label min-w-[150px] text-lg">
                        Key
                    </label>
                    <div className="w-full">
                        <input id="persona-key"
                               defaultValue={specification?.key ?? copySpecification?.key}
                               ref={keyRef}
                               onChange={onChangeKey}
                               className="form-input w-full focus:outline-none"
                        />
                        {invalid.key && <small className="text-red-500">이미 존재하는 Key는 사용할수없습니다.</small>}
                    </div>
                </div>

                <div className="form-input-group flex">
                    <label htmlFor="persona-description"
                           className="form-label min-w-[150px] text-lg">
                        {intl.formatMessage({id: "i0185"})}
                    </label>
                    <input id="persona-description"
                           defaultValue={specification?.description ?? copySpecification?.description}
                           ref={descriptionRef}
                           className="form-input w-full focus:outline-none"
                    />
                </div>

                {specification &&
                    <>
                        <div className="form-input-group flex items-center">
                            <label className="form-label min-w-[150px] text-lg">
                                {intl.formatMessage({id: "i0196"})}
                            </label>
                            <p>{moment.utc(specification.createdAt).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                        </div>

                        <div className="form-input-group flex items-center">
                            <label className="form-label min-w-[150px] text-lg">
                                {intl.formatMessage({id: "i0197"})}
                            </label>
                            <p>{moment.utc(specification.updatedAt).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                        </div>
                    </>
                }
            </div>
        </div>

    )
};

export default ChannelGroupForm;
