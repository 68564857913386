import React, {useCallback, useMemo, useState} from "react";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import _ from "lodash";
import classNames from "classnames";
import {useIntl} from "react-intl";
import useGlobalVariableGroup from "../../query/globalVariableGroup/useGlobalVariableGroup";
import {GlobalVariableGroup} from "../../model/globalVariable/GlobalVariableGroup";
import GlobalVariableGroupSettingForm from "../../component/globalVariable/GlobalVariableGroupSettingForm";
// import GlobalVariableGroupGuide from "../../component/globalVariable/GlobalVariableGroupGuide";

const GlobalVariableGroupSettingView: React.FC = () => {
    const intl = useIntl();
    const [searchKey, setSearchKey] = useState<string>();

    const {data: globalVariableGroups} = useGlobalVariableGroup(searchKey);

    const [showForm, setShowForm] = useState(false);
    const [selectedGlobalVariableGroupKey, setselectedGlobalVariableGroupKey] = useState<string>();

    const selectedGlobalVariableGroup = useMemo(() => {
        return globalVariableGroups?.find(globalVariableGroup => globalVariableGroup.key === selectedGlobalVariableGroupKey);
    }, [globalVariableGroups, selectedGlobalVariableGroupKey]);

    const hideForm = useCallback(() => {
        setselectedGlobalVariableGroupKey(undefined);
        setShowForm(false);
    }, [setselectedGlobalVariableGroupKey, setShowForm]);

    const onChangeSearch = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchKey(e.target.value);
        hideForm();
    }, 300);

    const onClickGlobalVariableGroup = (globalVariableGroup: GlobalVariableGroup) => {
        setselectedGlobalVariableGroupKey(globalVariableGroup.key)
        setShowForm(true);
    }

    const onClickAddGlobalVariableGroup = () => {
        setselectedGlobalVariableGroupKey(undefined);
        setShowForm(true);
    }


    const onClickCancelGlobalVariableGroup = () => {
        hideForm();
    }

    const onClickDeleteGlobalVariableGroup = () => {
        hideForm();
    }

    const onClickSubmitGlobalVariableGroup = (specificationKey: string) => {
        setselectedGlobalVariableGroupKey(specificationKey);
    }

    return (
        <div className="flex flex-col h-full">
            {/* Content */}
            <div className="flex space-x-5">
                <div className="w-[30%] flex flex-col space-y-2">

                    <div className="flex items-center justify-between">
                        <p className="text-xl pl-2">글로벌 변수 목록</p>

                        <button className="btn btn-info btn-sm" onClick={onClickAddGlobalVariableGroup}>
                            {intl.formatMessage({id: "i0204"})}
                        </button>
                    </div>

                    {/* Search bar */}
                    <div className="relative flex">
                        <div className="w-full">
                            <div className="absolute inset-y-0 flex items-center pl-3 pointer-events-none">
                                <MagnifyingGlassIcon className="w-5 h-5"/>
                            </div>
                            <input type="search"
                                   id="search"
                                   className="form-input pl-10"
                                   placeholder="Search by key"
                                   onChange={onChangeSearch}
                            />
                        </div>
                    </div>

                    {/* List */}
                    <div className="flex-grow space-y-2 overflow-y-auto max-h-[800px]">
                        {globalVariableGroups?.map((globalVariableGroup) => {
                            const description = globalVariableGroup.description === "" ? "설명 없음" : (globalVariableGroup?.description ?? "설명 없음")
                            return (
                                <div key={globalVariableGroup.key}
                                     className={classNames(
                                         "border border-gray-600 rounded-lg px-5 py-3 cursor-pointer transition-colors duration-200 hover:bg-gray-200",
                                         selectedGlobalVariableGroupKey === globalVariableGroup.key && "bg-gray-300"
                                     )}
                                     onClick={() => onClickGlobalVariableGroup(globalVariableGroup)}>
                                    <p className="text-lg truncate">{globalVariableGroup.key}</p>
                                    <p className="text-sm">{description}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="flex-grow space-y-2">
                    {showForm &&
                        <GlobalVariableGroupSettingForm key={`specification-setting-form-${selectedGlobalVariableGroup?.key}`}
                                                    globalVariableGroup={selectedGlobalVariableGroup}
                                                    onCancel={onClickCancelGlobalVariableGroup}
                                                    onDelete={onClickDeleteGlobalVariableGroup}
                                                    onSubmit={onClickSubmitGlobalVariableGroup}
                        />
                    }

                    {/*<GlobalVariableGroupGuide/>*/}
                </div>
            </div>
        </div>
    );
};

export default GlobalVariableGroupSettingView;
