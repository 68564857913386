import userRepository from "../../respsitory/UserRepository";
import {useQuery} from "@tanstack/react-query";
import {UserResponsePage, UserSearchType} from "../../model/User";
import {AxiosError} from "axios";

const getData = async (pageNum: number, size?: number, searchType?: UserSearchType, searchKeyword?: string) => {
    try {
        const {data} = await userRepository.find(pageNum, size, searchType, searchKeyword)
        return data.result
    } catch (e) {
        throw e;
    }
}

const useUserPagination = (pageNum: number, size?: number, searchType?: UserSearchType, searchKeyword?: string) => {
    return useQuery<UserResponsePage, AxiosError>({
        queryKey: ["users", pageNum, size, searchType, searchKeyword],
        queryFn: async () => getData(pageNum, size, searchType, searchKeyword),
        retry: false,
        suspense: false
    })
}

export default useUserPagination;
