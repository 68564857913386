import queryLogRepository from "../../respsitory/QueryLogRepository";
import {useQuery} from "@tanstack/react-query";
import {QueryLogResponsePage} from "../../model/QueryLog";
import {AxiosError} from "axios";

const getData = async (page: number, channelId: string, answerType: string, size?: number, logType?: string) => {
    try {
        const {data} = await queryLogRepository.find(page, channelId, answerType, logType, size)
        return data.result
    } catch (e) {
        throw e;
    }
}

const useQueryLogs = (page: number, channelId: string, answerType: string, size?: number, logType?: string) => {
    return useQuery<QueryLogResponsePage, AxiosError>({
        queryKey: ["queryLogs", page, channelId, answerType, size, logType],
        queryFn: async () => getData(page, channelId, answerType, size, logType),
        retry: false,
        suspense: false
    })
}

export default useQueryLogs;