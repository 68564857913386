import React, {useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {useIsMutating} from "@tanstack/react-query";
import {ClipLoader} from "react-spinners";
import {
    answerPanelChat,
    AnswerPanelChat,
    AnswerType,
    errorAnswerPanelChat,
    ErrorLog,
    errorReplyQuestionPanelChat,
    loadingAnswerPanelChat,
    loadingReplyQuestionPanelChat,
    PanelChat,
    questionPanelChat,
    QuestionPanelChat,
    replyQuestionPanelChat,
    ReplyQuestionPanelChat, replyQuestionsDecode
} from "../../model/llmSearchTestPanel/QueryAnswers";
import classNames from "classnames";
import {useList} from "@react-hookz/web";
import toast from "react-hot-toast";
import ThinScrollBarStyle from "../ThinScrollBar";
import {QueryLog} from "../../model/QueryLog";

const PanelChatList: React.FC<PanelChatListProps> = (props) => {
    const {sendQuery, getReplyQuestion, changeDetail, channelId} = props

    const intl = useIntl();

    const [detailIdx, setDetailIdx] = useState<number>();
    const [chats, {push: pushChats, set: setChats}] = useList<PanelChat>([]);
    const chatsRef = useRef<PanelChat[]>([]);
    chatsRef.current = chats;
    const updateChatsLastItem = (newChat: PanelChat) => {
        setChats((chats) =>  [...chats.slice(0, -1), newChat]);
    }

    const queryRef = useRef<HTMLInputElement>(null);
    const chatEndRef = useRef<HTMLDivElement>(null);

    const isSendingQuery = useIsMutating({ mutationKey: ["test", "query"] })

    useEffect(() => {
        chatEndRef.current!.scrollIntoView();
    }, [chats]);

    const changeDetailChat = (i: number) => {
        if (!chatsRef.current || chatsRef.current.length <= i) return;

        setDetailIdx(i);
    }

    // use useEffect because if change proccessResult in changeDetailChat, it will submit answer chat in loading after send query
    useEffect(() => {
        if (chats == null || detailIdx == null) return;
        changeDetail(chats[detailIdx] as AnswerPanelChat | ReplyQuestionPanelChat);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailIdx, changeDetail])

    const onSubmitQuery = async (query: string) => {
        if (!sendQuery) return;
        if (!channelId) return;

        pushChats(questionPanelChat(query));
        pushChats(loadingAnswerPanelChat);

        const result = await sendQuery(query);
        if (!result) {
            toast.error(intl.formatMessage({id: "i11030"}));
            updateChatsLastItem(errorAnswerPanelChat);
            return;
        }

        const {answer, showQuestionReply} = result;

        const answerChat = answerPanelChat(answer);
        updateChatsLastItem(answerChat);

        changeDetailChat(chatsRef.current.length - 1);

        if (answer.answer && showQuestionReply) {
            pushChats(loadingReplyQuestionPanelChat);
            const replyQuestions = await getReplyQuestion(query, channelId);
            if (!replyQuestions) {
                updateChatsLastItem(errorReplyQuestionPanelChat);
                return;
            }
            updateChatsLastItem(replyQuestionPanelChat(replyQuestions));
        }
    };

    const onKeyUp = async (e: React.KeyboardEvent) => {
        if (!queryRef.current || e.key !== 'Enter') return;

        const query = queryRef.current.value;
        queryRef.current.value = "";
        await onSubmitQuery(query);
    }

    const onClickReplySubmitBtn = async (q: string) => {
        await onSubmitQuery(q);
    };

    const loadingChat = (i: number) => (
        <div key={`status-loading-${i}`} className="flex justify-start pt-2">
            <div className="ml-2 py-3 px-4 bg-gray-400 rounded-br-3xl rounded-tr-3xl rounded-tl-xl text-white">
                <ClipLoader size={16} color="white" />
            </div>
        </div>
    );
    const errorChat = (i: number) => (
        <div key={`status-error-${i}`} className="flex justify-start pt-2">
            <div className="ml-2 py-3 px-4 bg-red-500 rounded-br-3xl rounded-tr-3xl rounded-tl-xl text-white">
                {intl.formatMessage({id: "i11030"})}
            </div>
        </div>
    );

    const renderQuestion =(question: QuestionPanelChat, i: number) => {
        return (
            <div key={`question-${i}`} className="flex justify-end py-2">
                <div className="mr-2 py-3 px-4 bg-blue-400 rounded-bl-3xl rounded-tl-3xl rounded-tr-xl text-white">
                    {question.question}
                </div>
            </div>
        );
    };

    const renderAnswer =(message: PanelChat, i: number) => {
        if (message.state === "loading") {
            return loadingChat(i);
        } else if (message.state === "error") {
            return errorChat(i);
        }

        const processToIntl: {[index: string]: string} = {
            'analyzed_query': intl.formatMessage({id: "i11049"}),
            'passage_search': intl.formatMessage({id: "i11051"}),
            'prompt_build': intl.formatMessage({id: "i11046"}),
            'llm_generated': intl.formatMessage({id: "i11062"}),
            'load_data': intl.formatMessage({id: "i11098"}),
            'query_embedding': intl.formatMessage({id: "i11099"}),
            'other': intl.formatMessage({id: "i11091"})
        }

        const show = () => {
            if (message.queryLog?.answer) {
                return message.queryLog.answer.split("\n").map(row => row !== "" ? <div>{row}</div> : <br/>) || []
            } else {
                const errorLog = (message.queryLog?.analyzedQuery?.error ?? message.queryLog?.passageSearch?.error
                    ?? message.queryLog?.promptBuild?.error ?? message.queryLog?.llmGenerated?.error ?? message.queryLog?.etcError?.error) as ErrorLog;

                return [
                    <div>Process Error: {processToIntl[errorLog.process]}</div>,
                    <div>Type: {errorLog.type}</div>,
                    <div>Code: {errorLog.code}</div>,
                    <div>Detail: {errorLog.detail}</div>
                ]
            }
        }

        return (
            <div key={`status-answer-${i}`} className="flex justify-start pt-2">
                <div className={classNames(
                    "ml-2 py-3 px-4  rounded-br-3xl rounded-tr-3xl rounded-tl-xl text-white",
                    {
                        "bg-gray-400 hover:bg-gray-500 cursor-pointer": detailIdx !== i,
                        "bg-gray-700": detailIdx === i,
                    })}
                     onClick={() => changeDetailChat(i)}>
                    {show()}
                </div>
            </div>
        );
    };

    const renderReplyQuestion = (message: PanelChat, i: number) => {
        if (message.state === "loading") {
            return loadingChat(i);
        } else if (message.state === "error") {
            return errorChat(i);
        }

        const processToIntl: {[index: string]: string} = {
            'analyzed_query': intl.formatMessage({id: "i11049"}),
            'passage_search': intl.formatMessage({id: "i11051"}),
            'context_build': intl.formatMessage({id: "i11046"}),
            'llm_response': intl.formatMessage({id: "i11062"}),
            'load_data': intl.formatMessage({id: "i11098"}),
            'query_embedding': intl.formatMessage({id: "i11099"}),
            'other': intl.formatMessage({id: "i11091"})
        }

        const show = replyQuestionsDecode(message.queryLog?.answer);

        const processResult = message.queryLog;

        const errorLog = (processResult?.analyzedQuery?.error ?? processResult?.passageSearch?.error
            ?? processResult?.promptBuild?.error ?? processResult?.llmGenerated?.error ?? processResult?.etcError?.error) as ErrorLog;

        if (errorLog) {
            return (
                <div key={`status-reply-btn-${i}`} className="flex justify-start pt-2">
                    <div className={"flex flex-col w-full"}>
                        <button className={classNames("ml-2 w-fit btn-sm btn-light", {"bg-slate-500 text-white pointer-events-none": detailIdx === i})}
                                onClick={() => changeDetailChat(i)}>
                            {intl.formatMessage({id: "i11096"}) + processToIntl[errorLog.process]}
                        </button>
                    </div>
                </div>
            )
        }

        return (
            <div key={`status-reply-btn-${i}`} className="flex justify-start pt-2">
                <div className={"flex flex-col w-full"}>
                    <button className={classNames("ml-2 w-fit btn-sm btn-light", {"bg-slate-500 text-white pointer-events-none": detailIdx === i})}
                            onClick={() => changeDetailChat(i)}>
                        {intl.formatMessage({id: "i11036"})}
                    </button>

                    {show?.map((q: string) => (
                        <button className="ml-2 btn-sm btn-light"
                                onClick={() => onClickReplySubmitBtn(q)}>
                            {q}
                        </button>
                    ))}
                    {!show?.length &&
                        <button className="ml-2 px-3 py-2 bg-gray-100 text-xs cursor-default">
                            {intl.formatMessage({id: "i11037"})}
                        </button>
                    }
                </div>
            </div>
        );
    };

    return (
        <ThinScrollBarStyle>
            <div className={"bg-gray-200 w-full h-full border-r border-gray-900 flex flex-col whitespace-normal"}>
                <div className={"h-[calc(100vh-12rem)] p-3 overflow-auto flex flex-col gap-3"}>
                    {chats.map((chat, i) => {
                        if (chat.type === AnswerType.QUESTION) {
                            return renderQuestion(chat as QuestionPanelChat, i);
                        } else if (chat.type === AnswerType.ANSWER) {
                            return renderAnswer(chat, i);
                        } else if (chat.type === AnswerType.REPLYQUESTION) {
                            return renderReplyQuestion(chat, i);
                        } else {
                            return <div></div>
                        }
                    })}
                    <div ref={chatEndRef} />
                </div>

                <div className={"mb-3 w-full p-3"}>
                    <input type="text"
                           ref={queryRef}
                           className="w-full border bg-white py-2 px-2 rounded-xl disabled:!cursor-wait"
                           disabled={!!isSendingQuery}
                           onKeyUp={onKeyUp}
                    />
                </div>
            </div>
        </ThinScrollBarStyle>
    )
}

interface PanelChatListProps {
    sendQuery: (query: string) => Promise<{answer: QueryLog, showQuestionReply: boolean, questionContext?: any[]} | undefined>
    getReplyQuestion: (query: string, channelId: string) => Promise<QueryLog | undefined>
    changeDetail: (analysis: AnswerPanelChat | ReplyQuestionPanelChat) => void
    channelId?: string
}

export default PanelChatList;
