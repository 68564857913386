import React, {useEffect, useMemo, useRef, useState} from "react";
import _ from "lodash";
import toast from "react-hot-toast";
import {useDialog} from "../../hook/useDialog";
import moment from "moment";
import {useIntl} from "react-intl";
import {
    ChannelCommonSetting,
    ChannelCommonSettingBase,
    ChannelCommonSettingCreateRequest,
    ChannelCommonSettingUpdateRequest, Config,
} from "../../model/ChannelCommonSetting";
import useSearchSpecification from "../../query/searchSpecification/useSearchSpecification";
import {Agent, LLMType, Qa42Setting} from "../../model/prompt/prompt";
import useCreateChannelCommonSetting from "../../query/channelCommonSetting/useCreateChannelCommonSetting";
import useUpdateChannelCommonSetting from "../../query/channelCommonSetting/useUpdateChannelCommonSetting";
import useDeleteChannelCommonSetting from "../../query/channelCommonSetting/useDeleteChannelCommonSetting";
import channelCommonSettingRepository from "../../respsitory/ChannelCommonSettingRepository";
import {SearchEngineType} from "../../model/searchSpecification";
import useGlobalVariables from "../../query/globalVariables/useGlobalVariables";
import useGlobalVariableGroup from "../../query/globalVariableGroup/useGlobalVariableGroup";
import GlobalVariableList from "./GlobalVariableList";
import SettingsTab from "../searchSpecification/SettingsTab";
import ChannelCommonSettingHistoryModal, {
    CHANNEL_COMMON_SETTING_HISTORY_MODAL_ID
} from "./ChannelCommonSettingHistoryModal";
import useModal from "../../hook/useModal";
import {useQueryClient} from "@tanstack/react-query";

type ChannelCommonSettingFormProps = {
    channelCommonSetting?: ChannelCommonSetting;
    copyChannelCommonSetting?: ChannelCommonSettingBase;
    onCancel: () => void;
    onDelete: () => void;
    onSubmit: (key: string) => void;
    onClickCopyChannelCommonSetting: (channelCommonSetting: ChannelCommonSettingBase) => void;
}

const INIT_INVALID = {
    key: false,
    searchSpecificationId: false,
}

const ChannelCommonSettingForm: React.FC<ChannelCommonSettingFormProps> = (props) => {
    const {channelCommonSetting, onCancel, onDelete, onSubmit, onClickCopyChannelCommonSetting, copyChannelCommonSetting} = props;
    const intl = useIntl();
    const dialog = useDialog();
    const modal = useModal();
    const queryClient = useQueryClient();

    const searchEngineType = useMemo(() => {
        return copyChannelCommonSetting ? copyChannelCommonSetting.searchEngineType : channelCommonSetting?.searchEngineType
    }, [channelCommonSetting, copyChannelCommonSetting])
    const {data: searchSpecifications} = useSearchSpecification(undefined, searchEngineType);
    const {data: globalVariables} = useGlobalVariables();
    const {data: globalVariableGroups} = useGlobalVariableGroup();

    const {mutateAsync: createChannelCommonSetting} = useCreateChannelCommonSetting();
    const {mutateAsync: updateChannelCommonSetting} = useUpdateChannelCommonSetting();
    const {mutateAsync: deleteChannelCommonSetting} = useDeleteChannelCommonSetting();

    const keyRef = useRef<HTMLInputElement>(null);
    const descriptionRef = useRef<HTMLInputElement>(null);
    const defaultRef = useRef<HTMLInputElement>(null);

    const [invalid, setInvalid] = useState<{ key: boolean, searchSpecificationId: boolean}>(INIT_INVALID);

    const [searchSpecificationId, setSearchSpecificationId] = useState<string>();
    const [searchEngineCheck, setSearchEngineCheck] = useState<boolean>(false);
    const [selectedGlobalVariable, setSelectedGlobalVariable] = useState<string[]>([]);
    const [selectedSearchEngine, setSelectedSearchEngine] = useState<SearchEngineType>(SearchEngineType.ADA);

    // LLM UseCase Agent 설정들
    const blankAgent = {llmId: "", promptId: "", llmType: LLMType.GPT} as Agent

    const [welcomeAgent, setWelcomeAgent] = React.useState<Agent>(blankAgent);
    const [analyzeKoreanAgent, setAnalyzeKoreanAgent] = React.useState<Agent>(blankAgent);
    const [analyzeEnglishAgent, setAnalyzeEnglishAgent] = React.useState<Agent>(blankAgent);
    const [contextQuestionAgent, setContextQuestionAgent] = React.useState<Agent>(blankAgent);
    const [welcomeQuestionAgent, setWelcomeQuestionAgent] = React.useState<Agent>(blankAgent);
    const [mrcAnswerAgent, setMrcAnswerAgent] = React.useState<Agent>(blankAgent);
    const [passageAnswerAgent, setPassageAnswerAgent] = React.useState<Agent>(blankAgent);
    const [contextAnswerAgent, setContextAnswerAgent] = React.useState<Agent>(blankAgent);
    const [channelCuratingAgent, setChannelCuratingAgent] = React.useState<Agent>(blankAgent);
    const allAgents = {welcomeAgent, analyzeKoreanAgent, analyzeEnglishAgent, contextQuestionAgent, welcomeQuestionAgent, mrcAnswerAgent, passageAnswerAgent, contextAnswerAgent, channelCuratingAgent, type: SearchEngineType.QA42}
    const allSetAgents = {setWelcomeAgent, setAnalyzeKoreanAgent, setAnalyzeEnglishAgent, setContextQuestionAgent, setWelcomeQuestionAgent, setMrcAnswerAgent, setPassageAnswerAgent, setContextAnswerAgent, setChannelCuratingAgent}

    const [agentValidateCheck, setAgentValidateCheck] = useState<boolean>(false);

    const onChangeKey = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        const key = e.target.value;
        if (channelCommonSetting?.key === key) return;
        channelCommonSettingRepository.existed(key).then(({data}) => {
            setInvalid(prev => ({
                ...prev,
                key: data.result
            }))
        }).catch(() => {
            toast.error(intl.formatMessage({id: "i0167"}))
        })
    }, 200);

    const onClickDelete = () => {
        if (!channelCommonSetting) {
            return;
        }
        dialog.open({
            variant: "danger",
            title: "채널 설정 프리셋 삭제",
            content: "채널 설정 프리셋을 삭제하시겠습니까?",
            onConfirm: async () => {
                try {
                    await deleteChannelCommonSetting(channelCommonSetting._id);
                    toast.success("채널 설정 프리셋을 삭제했습니다.");
                    onDelete();
                } catch (e: any) {
                    const error = e.response.data.detail.code
                    if (error === "channelValidationError") {
                        toast.error("해당 채널 설정 프리셋으로 지정된 채널이 존재합니다")
                    } else {
                        toast.error(intl.formatMessage({id: "i10012"}))
                    }
                }
            }
        });
    }

    const onClickCancel = () => {
        onCancel();
    }

    const onClickSubmit = async () => {
        const key = keyRef.current?.value;
        const description = descriptionRef.current?.value;
        const defaultCheck = defaultRef.current?.checked ?? false;
        const globalVariables = selectedGlobalVariable;

        const settings = allAgents as Config;
        const searchEngineType = searchSpecifications?.find(spec => spec._id === searchSpecificationId)?.passageSearchSetting.type;

        const invalid = {
            key: !key,
            searchSpecificationId: !searchSpecificationId,
        }
        setInvalid(invalid);
        if (Object.values(invalid).some(v => v)) {
            toast.error("설정을 선택해주세요")
            return;
        }

        const isInvalidAgentConfiguration = (settings: Config, excludeField: string[] = []) => {
            return Object.entries(settings).some(([name, agent]) => {
                if (excludeField.includes(name) || name === "type") return false;
                return !agent.promptId || agent.promptId === "-" || !agent.llmId || agent.llmId === "-";
            });
        }

        if (searchEngineType) {
            settings.type = searchEngineType;
            const validate = (searchEngineType === SearchEngineType.QA42)
                ? isInvalidAgentConfiguration(settings)
                : isInvalidAgentConfiguration(settings, ["mrcAnswerAgent"]);
            if (validate) {
                setAgentValidateCheck(true);
                toast.error("에이전트를 설정해주세요");
                return;
            }
        }

        if (channelCommonSetting) {
            const previousSearchEngineType = searchSpecifications?.filter(searchSpecification => searchSpecification._id === channelCommonSetting?.searchSpecificationId)[0].passageSearchSetting.type
            const searchEngineType = searchSpecifications?.filter(searchSpecification => searchSpecification._id === searchSpecificationId)[0].passageSearchSetting.type
            if (previousSearchEngineType !== searchEngineType) {
                setSearchEngineCheck(true);
                toast.error("검색 설정의 검색 엔진은 변경할수 없습니다.");
                return;
            } else {
                setSearchEngineCheck(false);
            }

            const body: ChannelCommonSettingUpdateRequest = {
                key: key!,
                description,
                default: defaultCheck,
                searchSpecificationId: searchSpecificationId!,
                globalVariables: globalVariables,
                config: settings
            }
            dialog.open({
                variant: "danger",
                title: `채널 설정 프리셋 수정`,
                content: `채널 설정 프리셋을 수정하려고 합니다. 계속 하시겠습니까?`,
                onConfirm: async () => {
                    try {
                        await updateChannelCommonSetting({_id: channelCommonSetting._id, ...body});
                        toast.success("채널 설정 프리셋을 수정했습니다.")
                        await queryClient.invalidateQueries(["channelCommonSettingHistory", channelCommonSetting._id], {exact: true});
                        onSubmit(channelCommonSetting.key);
                    } catch (e) {
                        toast.error("채널 설정 프리셋 정보를 수정하는 도중 에러가 발생했습니다.")
                    }
                }
            });
        } else {
            const body: ChannelCommonSettingCreateRequest = {
                key: key!,
                description,
                searchSpecificationId: searchSpecificationId!,
                searchEngineType: searchEngineType!,
                globalVariables: globalVariables,
                config: settings
            }
            try {
                await createChannelCommonSetting(body);
                toast.success("채널 설정 프리셋을 생성했습니다.")
                onSubmit(body.key);
            } catch (e) {
                toast.error("채널 설정 프리셋을 생성하는 도중 에러가 발생했습니다.")
            }
        }
    }

    const onChangeSearchSpecificationId = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const searchSpecificationId = e.target.value;
        if (searchSpecificationId === "-") return;
        setSearchSpecificationId(searchSpecificationId);
        const searchSpecificationEngineType = searchSpecifications?.filter(search => search._id === searchSpecificationId)[0].passageSearchSetting.type
        setSelectedSearchEngine(searchSpecificationEngineType ?? SearchEngineType.QA42);
    }

    const onClickResetPrompt = () => {
        setSelectedGlobalVariable([]);
    }

    const onClickShowHistoryModal = () => {
        modal.open(CHANNEL_COMMON_SETTING_HISTORY_MODAL_ID)
    }

    const onClickCopy = async () => {
        if (!channelCommonSetting) return;
        const key = channelCommonSetting.key
        const currentDate = moment().format('YYYY-MM-DD HH:mm');
        const copiedKey = `${key}_copied(${currentDate})`
        const copyChannelCommonSetting = {...channelCommonSetting, key: copiedKey}
        onClickCopyChannelCommonSetting(copyChannelCommonSetting)
    }

    useEffect(() => {
        const loadData = copyChannelCommonSetting ?? channelCommonSetting
        if (loadData && searchSpecifications) {
            setSearchSpecificationId(loadData.searchSpecificationId ?? "");
            setSelectedGlobalVariable(loadData.globalVariables ?? []);
            setSelectedSearchEngine(searchEngineType ?? SearchEngineType.QA42);
        }
        if (loadData?.config) {
            setWelcomeAgent(loadData.config.welcomeAgent);
            setAnalyzeKoreanAgent(loadData.config.analyzeKoreanAgent);
            setAnalyzeEnglishAgent(loadData.config.analyzeEnglishAgent);
            setContextQuestionAgent(loadData.config.contextQuestionAgent);
            setWelcomeQuestionAgent(loadData.config.welcomeQuestionAgent);
            setMrcAnswerAgent((loadData.config as Qa42Setting).mrcAnswerAgent);
            setPassageAnswerAgent(loadData.config.passageAnswerAgent);
            setContextAnswerAgent(loadData.config.contextAnswerAgent);
            setChannelCuratingAgent(loadData.config.channelCuratingAgent);
        }
    }, [channelCommonSetting, copyChannelCommonSetting, globalVariables, searchSpecifications, searchEngineType])

    return (
        <>
            <div>
                <div className="flex items-center justify-end mb-3">
                    <div className="flex items-center space-x-2">
                        {channelCommonSetting &&
                            <div className="flex items-center justify-between space-x-2">
                                <button className="btn bg-purple-800 text-white transition-colors duration-200"
                                        onClick={onClickShowHistoryModal}>
                                    변경이력 보기
                                </button>
                                <ChannelCommonSettingHistoryModal channelCommonSettingId={channelCommonSetting._id}/>
                                <button className="btn bg-green-500 text-white transition-colors duration-200"
                                        onClick={onClickCopy}>
                                    사본생성
                                </button>
                            </div>
                        }
                        <button className="btn btn-primary transition-colors duration-200" onClick={onClickSubmit}>
                            {channelCommonSetting ? intl.formatMessage({id: "i0200"}) : intl.formatMessage({id: "i0201"})}
                        </button>
                        {channelCommonSetting &&
                            <button className="btn btn-danger transition-colors duration-200" onClick={onClickDelete}>
                                {intl.formatMessage({id: "i0199"})}
                            </button>
                        }
                        <button className="btn btn-secondary-outline transition-colors duration-200" onClick={onClickCancel}>
                            {intl.formatMessage({id: "i0198"})}
                        </button>
                    </div>
                </div>

                <div className="border border-gray-400 rounded-md shadow-lg p-5 flex flex-col overflow-y-auto">
                    <div className="text-xl font-semibold mb-3 border-b-2 pb-3">
                        <div className="flex items-center justify-between">
                            <div className={"flex justify-between items-center"}>
                                <p>채널 프리셋 정보</p>
                            </div>
                        </div>
                    </div>


                    <div className="form-input-group flex">
                        <label htmlFor="persona-key"
                               className="form-label min-w-[150px] text-lg">
                            Key
                        </label>
                        <div className="w-full">
                            <input id="persona-key"
                                   defaultValue={channelCommonSetting?.key ?? copyChannelCommonSetting?.key}
                                   ref={keyRef}
                                   onChange={onChangeKey}
                                   className="form-input w-full focus:outline-none"
                            />
                            {invalid.key && <small className="text-red-500">이미 존재하는 Key는 사용할수없습니다.</small>}
                        </div>
                    </div>

                    <div className="form-input-group flex">
                        <label
                               className="form-label min-w-[150px] text-lg">
                            {intl.formatMessage({id: "i0185"})}
                        </label>
                        <input
                               defaultValue={channelCommonSetting?.description ?? copyChannelCommonSetting?.description}
                               ref={descriptionRef}
                               className="form-input w-full focus:outline-none"
                        />
                    </div>
                    {channelCommonSetting &&
                        <div className="form-input-group flex items-center">
                            <label
                                   className="form-label min-w-[150px] text-lg">
                                Default
                            </label>
                            <input type="checkbox"
                                   defaultChecked={channelCommonSetting?.default}
                                   ref={defaultRef}
                                   className="form-checkbox focus:outline-none"
                            />
                        </div>
                    }

                    {searchEngineType &&
                        <div className="form-input-group flex">
                            <label className="form-label min-w-[150px] text-lg">
                                검색 엔진
                            </label>
                            <input defaultValue={searchEngineType}
                                   disabled={!!searchEngineType}
                                   className="form-input w-full focus:outline-none"
                            />
                        </div>
                    }

                    <div className="form-input-group">
                        <div className="flex">
                            <label htmlFor="search-specification-key"
                                   className="form-label min-w-[150px] text-lg">
                                검색 설정 키
                            </label>
                            <select id="search-specification-key"
                                    className="form-select w-full px-2 focus:outline-none"
                                    onChange={onChangeSearchSpecificationId}
                                    value={searchSpecificationId}
                            >
                                <option value="-">-</option>
                                {searchSpecifications?.map((search) => {
                                        return (
                                            <option key={search._id} value={search._id}>{search.key}</option>
                                        )
                                    }
                                )}
                            </select>
                        </div>
                        <div className="flex">
                            <label htmlFor="llm-key"
                                   className="form-label min-w-[150px] text-lg">
                            </label>
                            <div>
                                {invalid.searchSpecificationId && <small className="text-red-500">검색 설정 키를 선택해주세요 </small>}
                                {searchEngineCheck && <small className="text-red-500">검색 설정의 엔진타입은 변경할수 없습니다 </small>}
                            </div>
                        </div>
                    </div>

                    {channelCommonSetting &&
                        <>
                            <div className="form-input-group flex items-center">
                                <label className="form-label min-w-[150px] text-lg">
                                    {intl.formatMessage({id: "i0196"})}
                                </label>
                                <p>{moment.utc(channelCommonSetting.createdAt).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                            </div>

                            <div className="form-input-group flex items-center">
                                <label className="form-label min-w-[150px] text-lg">
                                    {intl.formatMessage({id: "i0197"})}
                                </label>
                                <p>{moment.utc(channelCommonSetting.updatedAt).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                            </div>
                        </>
                    }
                </div>

                <div className="flex flex-col overflow-y-auto mt-5">
                    <SettingsTab
                        selectedSearchEngine={selectedSearchEngine}
                        allAgents={allAgents}
                        allSetAgents={allSetAgents}
                        agentValidateCheck={agentValidateCheck}
                    />
                </div>

                <div className="border border-gray-400 rounded-md shadow-lg p-5 flex flex-col overflow-y-auto mt-5">
                    <div className="w-full h-full">
                        <div className="text-xl font-semibold mb-3 border-b-2 pb-3">
                            <div className="flex items-center justify-between">
                                <div className={"flex justify-between items-center"}>
                                    <p>커스텀 프롬프트 정보</p>
                                </div>
                                <button className="btn btn-primary"
                                        onClick={onClickResetPrompt}>
                                    초기화
                                </button>
                            </div>
                        </div>
                        <div className="ml-3 mt-3">
                            {globalVariableGroups?.map((globalVariableGroup) => {
                                return (
                                    <div key={globalVariableGroup._id}>
                                        <GlobalVariableList globalVariableGroup={globalVariableGroup}
                                                            selectedGlobalVariable={selectedGlobalVariable}
                                                            setSelectedGlobalVariable={setSelectedGlobalVariable}/>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
};

export default ChannelCommonSettingForm;
