import React from "react";
import {QueryLog} from "../../model/QueryLog";
import {useNavigate} from "react-router-dom";
import {TrashIcon} from "@heroicons/react/24/outline";
import useDeleteQueryLog from "../../query/queryLog/useDeleteQueryLog";
import {useDialog} from "../../hook/useDialog";
import toast from "react-hot-toast";

type QueryLogRowProps = {
    queryLog: QueryLog
}

const QueryLogRow: React.FC<QueryLogRowProps> = (props) => {
    const {queryLog} = props;
    const {mutateAsync: deleteQueryLog} = useDeleteQueryLog();
    const dialog = useDialog();
    const navigate = useNavigate();
    const onClickDetail = (queryLog: QueryLog) => {
        navigate(`/channels/${queryLog.channelId}/query-logs/${queryLog._id}`, {state: {queryLog: queryLog}});
    }
    const offset = 1000 * 60 * 60 * 9 * 2
    const temp = new Date((new Date(queryLog.createdAt)).getTime() + offset)
    const formattedDate = temp.toISOString().replace("T", " ").split('.')[0];

    const onClickDeleteQueryLog = () =>{
        dialog.open({
                        title: "테스트 로그 삭제",
                        content: `테스트 로그를 삭제하시겠습니까?`,
                        variant: "danger",
                        onConfirm: async () => {
                            await deleteQueryLog(queryLog._id);
                            toast.success("테스트 로그를 삭제했습니다.");
                        },
                        confirmText: "삭제",
                    })
    }
    return (
        <div key={queryLog._id} className="flex justify-between">
            <button onClick={() => {
                        onClickDetail(queryLog)
                    }}>
                <div>
                    {queryLog.payload} [{formattedDate}]
                </div>
            </button>
            <button onClick={onClickDeleteQueryLog}>
                <TrashIcon className="w-6 h-6"/>
            </button>
        </div>
    )
}

export default QueryLogRow;