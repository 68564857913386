import {useIntl} from "react-intl";
import classNames from "classnames";
import React from "react";

interface PageBarProps {
    page: number
    lastPage: number
    onClickPage: (page: number) => void
}

const PageBar:React.FC<PageBarProps> = (props) => {
    const {page, lastPage, onClickPage} = props;

    const intl = useIntl();

    const isValidate = (pageNum: number) => pageNum >= 1 && lastPage >= pageNum;

    const pageButton = (pageNum: number) => {
        return (
            <div onClick={() => onClickPage(pageNum)}
                 className={classNames("cursor-pointer px-2 py-1 text-sm rounded-md", {
                     "bg-blue-100/60 text-blue-500 ": pageNum === page,
                     "hover:bg-gray-100": pageNum !== page
                 })}>
                {pageNum}
            </div>
        )
    };

    const pagerButton = (type: "first" | "last") => {
        const isFirst = type === "first";
        const pageNum = isFirst ? 1: lastPage;
        const term = isFirst ? intl.formatMessage({id: "i1007"}) : intl.formatMessage({id: "i1008"})

        if (!isValidate(pageNum)) return <></>;

        return (
            <div onClick={() => isValidate(pageNum) && onClickPage(pageNum)}
                 className={"cursor-pointer flex items-center px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white rounded-md gap-x-2 hover:bg-gray-100"}>
                <span>
                    {term}
                </span>
            </div>
        );
    }

    return (
        <div className="flex items-center justify-center mt-5">
            {pagerButton("first")}

            {/* Render 5 pages */}
            <div className="items-center md:flex gap-x-3 mx-5 min-w-[10rem] justify-center">
                {!isValidate(page + 1) && isValidate(page - 4) && pageButton(page - 4)}
                {!isValidate(page + 2) && isValidate(page - 3) && pageButton(page - 3)}
                {isValidate(page - 2) && pageButton(page - 2)}
                {isValidate(page - 1) && pageButton(page - 1)}
                {isValidate(page) && pageButton(page)}
                {isValidate(page + 1) && pageButton(page + 1)}
                {isValidate(page + 2) && pageButton(page + 2)}
                {!isValidate(page - 2) && isValidate(page + 3) && pageButton(page + 3)}
                {!isValidate(page - 1) && isValidate(page + 4) && pageButton(page + 4)}
            </div>

            {pagerButton("last")}
        </div>
    );
}


export default PageBar;
