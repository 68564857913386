import React, {Dispatch, useMemo} from "react";
import GPTForm from "./llmForm/GPTForm";
import {PromptContentItem, LLMType, LLMTypeList} from "../../model/prompt/prompt";
import LLM42Form from "./llmForm/LLM42Form";

type PromptInputProps = {
    llmType?: LLMType,
    invalid: { key: boolean, llmType: boolean, promptCheck: boolean },
    content: PromptContentItem[],
    setContent: Dispatch<React.SetStateAction<PromptContentItem[]>>,
    selectedPromptType: string;
    onChangeLLM: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

const LLMPrompt: React.FC<PromptInputProps> = (props) => {
    const {llmType, content, setContent, invalid, selectedPromptType, onChangeLLM} = props;
    const LLMForm = useMemo(() => {
        if (!llmType) return null
        switch (llmType) {
            case LLMType.GPT:
                return <GPTForm key={llmType} content={content} setContent={setContent}
                                invalid={invalid} selectedPromptType={selectedPromptType}/>
            case LLMType.LLM42:
                return <LLM42Form key={llmType} content={content} setContent={setContent}
                                  invalid={invalid} selectedPromptType={selectedPromptType}/>
            default:
                return undefined
        }
    }, [llmType, invalid, content, setContent, selectedPromptType])

    return (
        <div>
            <div className="form-input-group flex">
                <label htmlFor="llm-id"
                       className="form-label min-w-[200px] text-lg">
                    LLM 타입
                </label>
                <select id="llm-id"
                        className="form-select w-full px-2 focus:outline-none"
                        value={llmType}
                        onChange={onChangeLLM}
                >
                    {LLMTypeList.map((llmType) => {
                            const {type} = llmType;
                            return (
                                <option key={type} value={type}>{type}</option>
                            )
                        }
                    )}
                </select>
            </div>
            <div className="form-input-group flex items-center pb-5">
                <label htmlFor="ses-analyzer-model"
                       className="form-label min-w-[200px] text-lg">
                </label>
                <div className="text-sm text-gray-500">
                    프롬프트 타입을 선택하면 해당 프롬프트 타입에 속하는 프롬프트 입력창을 보여줍니다.
                </div>
            </div>
            <div className="form-input-group flex items-start w-full">
                {LLMForm}
            </div>
        </div>
    )
}

export default LLMPrompt;
