import {GlobalVariableGroup} from "../../model/globalVariable/GlobalVariableGroup";
import useGlobalVariables from "../../query/globalVariables/useGlobalVariables";
import React from "react";
import classNames from "classnames";
import {GlobalVariables} from "../../model/GlobalVariables";

type GlobalVariableListProps = {
    globalVariableGroup: GlobalVariableGroup
    selectedGlobalVariable: string[]
    setSelectedGlobalVariable: React.Dispatch<React.SetStateAction<string[]>>
}

const GlobalVariableList: React.FC<GlobalVariableListProps> = (props) => {
    const {globalVariableGroup, selectedGlobalVariable, setSelectedGlobalVariable} = props;
    const {data: globalVariables} = useGlobalVariables(undefined, globalVariableGroup._id);
    const onClickGlobalVariable = (globalVariable: GlobalVariables) => {
        const id = globalVariable._id
        if (!selectedGlobalVariable.includes(id)) {
            setSelectedGlobalVariable([...selectedGlobalVariable, id])
        } else {
            setSelectedGlobalVariable(selectedGlobalVariable.filter(variable => variable !== id))
        }
    }
    return (
        <div className="mt-3">
            <div className="text-xl">
                {globalVariableGroup.key}
            </div>
            <div className="items-center">
                {globalVariables?.map((globalVariable) => {
                    const {_id, key, content} = globalVariable
                    const checked = !!selectedGlobalVariable.filter(globalVariable => globalVariable === _id)[0];
                    return (
                        <div key={globalVariable._id} className="flex p-2 w-full border-b-2">
                            <button className={classNames("w-[30%] border-2 rounded-2xl mr-2",{"bg-blue-400": checked})}
                                    onClick={() => onClickGlobalVariable(globalVariable)}>
                                {key}
                            </button>
                            <textarea className="w-full overflow-hidden" readOnly={true} defaultValue={content}/>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default GlobalVariableList;