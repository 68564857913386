import {useMutation, useQueryClient} from "@tanstack/react-query";
import {ChannelCommonSetting, ChannelCommonSettingUpdateRequest,} from "../../model/ChannelCommonSetting";
import {AxiosError} from "axios";
import channelCommonSettingRepository from "../../respsitory/ChannelCommonSettingRepository";

const useUpdateChannelCommonSetting = () => {
    const queryClient = useQueryClient();

    return useMutation<ChannelCommonSetting, AxiosError, ChannelCommonSettingUpdateRequest & {_id: string}>({
        mutationKey: ["channelCommonSetting", "update"],
        mutationFn: async (body: ChannelCommonSettingUpdateRequest & {_id: string}) => {
            try {
                const {_id, ...extra} = body
                const {data} = await channelCommonSettingRepository.update(_id, extra);
                return data.result;
            } catch (e) {
                throw e;
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["channelCommonSetting"],
                exact: false
            })
        }
    })
};

export default useUpdateChannelCommonSetting;