import React, { ReactNode, useMemo } from "react";
import classNames from "classnames";

interface DetailCardProps {
    className?: string
    children?: ReactNode
    title: string
    latency?: number
}

const DetailCard: React.FC<DetailCardProps> = (props) => {
    const {className, children, latency, title} = props;

    const _className = useMemo(() => {
        return classNames("w-full border-2 border-black rounded-xl shadow-xl p-5 flex flex-col space-y-3 divide-y-4", {
            [className ?? ""]: className
        })
    }, [className])

    return (
        <div className={_className}>
            <div className="flex justify-between items-center">
                <div className={"w-full flex flex-col"}>
                    <div className={"text-center font-semibold text-3xl mb-2"}>
                        {title}
                    </div>
                    {latency && (
                        <div className={"w-full flex justify-end p-2"}>
                            {`소요시간: ${latency.toFixed(2)} 초`}
                        </div>
                    )}
                </div>
            </div>
            {children && <div className="text-left overflow-hidden text-ellipsis">{children}</div>}
        </div>
    )
};

export default DetailCard;
