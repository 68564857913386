import React, {useEffect, useMemo, useRef, useState} from "react";
import _ from "lodash";
import toast from "react-hot-toast";
import {useDialog} from "../../hook/useDialog";
import moment from "moment";
import {
    ADADefaultSetting,
    ADASetting,
    SearchEngineType,
    PassageSearchSetting,
    Qa42DefaultSetting,
    Qa42Setting,
    SearchSpecification,
    SearchSpecificationBase,
    SearchSpecificationCreateRequest,
    SearchSpecificationUpdateRequest
} from "../../model/searchSpecification";
import SearchEngineSpecificationRepository from "../../respsitory/SearchSpecificationRepository";
import {useIntl} from "react-intl";
import useCreateSearchSpecification from "../../query/searchSpecification/useCreateSearchSpecification";
import useUpdateSearchSpecification from "../../query/searchSpecification/useUpdateSearchSpecification";
import useDeleteSearchSpecification from "../../query/searchSpecification/useDeleteSearchSpecification";
import EngineSetting from "./engine/EngineSetting";
import useModal from "../../hook/useModal";
import SearchSpecificationHistoryModal, {SEARCH_SPECIFICATION_HISTORY_MODAL_ID} from "./SearchSpecificationHistoryModal";
import {useQueryClient} from "@tanstack/react-query";

type SearchEngineSpecificationSettingFormProps = {
    specification?: SearchSpecification
    copySpecification?: SearchSpecificationBase
    onCancel: () => void;
    onDelete: () => void;
    onSubmit: (specificationId: string) => void;
    onClickCopySpecification: (specification: SearchSpecificationBase) => void;
}

const SUPPORTED_MODEL = [
    SearchEngineType.ADA,
    SearchEngineType.QA42
]

const INIT_INVALID = {
    key: false,
    model: false,
}

const SearchSpecificationSettingForm: React.FC<SearchEngineSpecificationSettingFormProps> = (props) => {
    const {specification, onCancel, onDelete, onSubmit, onClickCopySpecification, copySpecification} = props;
    const intl = useIntl();
    const dialog = useDialog();
    const modal = useModal();
    const queryClient = useQueryClient();

    const {mutateAsync: createSpecification} = useCreateSearchSpecification();
    const {mutateAsync: updateSpecification} = useUpdateSearchSpecification();
    const {mutateAsync: deleteSpecification} = useDeleteSearchSpecification();

    const keyRef = useRef<HTMLInputElement>(null);
    const descriptionRef = useRef<HTMLInputElement>(null);
    const searchModelRef = useRef<HTMLSelectElement>(null);

    const [invalid, setInvalid] = useState<{ key: boolean, model: boolean }>(INIT_INVALID);

    const [selectedSearchEngine, setSelectedSearchEngine] = useState<SearchEngineType>(SearchEngineType.ADA);
    const [engineSetting, setEngineSetting] = useState<ADASetting | Qa42Setting>(ADADefaultSetting);

    const searchSpecificationKey = useMemo(() => {
        return copySpecification ? copySpecification.key : specification?.key
    }, [copySpecification, specification])
    const defaultDescription = useMemo(() => {
        return copySpecification ? copySpecification.description : specification?.description
    }, [copySpecification, specification])

    const onChangeKey = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        const key = e.target.value;
        if (specification?.key === key) return;
        SearchEngineSpecificationRepository.existed(key).then(({data}) => {
            setInvalid(prev => ({
                ...prev,
                key: data.result
            }))
        }).catch(() => {
            toast.error(intl.formatMessage({id: "i0167"}))
        })
    }, 200);

    const onChangeModel = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedSearchEngine = e.target.value;
        setSelectedSearchEngine(selectedSearchEngine as SearchEngineType);
        if (selectedSearchEngine === SearchEngineType.QA42) {
            setEngineSetting({
                passageSize: Qa42DefaultSetting.passageSize,
                mrcAnswerThreshold: Qa42DefaultSetting.mrcAnswerThreshold,
                mrcThreshold: Qa42DefaultSetting.mrcThreshold,
                faqThreshold: Qa42DefaultSetting.mrcThreshold,
            })
        } else {
            setEngineSetting({
                chunkSize: ADADefaultSetting.chunkSize,
                chunkCursorRange: ADADefaultSetting.chunkCursorRange,
                passageSize: ADADefaultSetting.passageSize,
                passageThreshold: ADADefaultSetting.passageThreshold,
                faqThreshold: ADADefaultSetting.faqThreshold
            });
        }
    }

    const onClickDelete = () => {
        if (!specification) {
            return;
        }

        dialog.open({
            variant: "danger",
            title: intl.formatMessage({id: "i0172"}),
            content: intl.formatMessage({id: "i0173"}),
            onConfirm: async () => {
                try {
                    await deleteSpecification(specification._id);
                    toast.success(intl.formatMessage({id: "i0174"}));
                    onDelete();
                } catch (e: any) {
                    const code = e.response.data.detail.code
                    if (code === "channelCommonSetting") {
                        toast.error("현재 채널 설정 프리셋에서 사용중인 데이터입니다");
                    } else {
                        toast.error(intl.formatMessage({id: "i0175"}));
                    }
                }
            }
        });
    }

    const onClickCancel = () => {
        onCancel();
    }

    const onClickSubmit = async () => {
        const key = keyRef.current?.value;
        const description = descriptionRef.current?.value;
        const searchEngineModel = searchModelRef.current?.value ?? SearchEngineType.ADA;

        const invalid = {
            key: !key,
            model: !searchEngineModel,
        }
        setInvalid(invalid);
        if (searchEngineModel === SearchEngineType.ADA) {
            if (Object.values(invalid).slice(0, 2).some(v => v)) {
                toast.error(intl.formatMessage({id: "i0176"}))
                return;
            }
        } else {
            if (Object.values(invalid).some(v => v)) {
                toast.error(intl.formatMessage({id: "i0176"}))
                return;
            }
        }

        let passageSearchSetting: PassageSearchSetting;
        if (searchEngineModel === SearchEngineType.ADA) {
            const setting = engineSetting as ADASetting;
            const chunkSize = setting.chunkSize;
            const chunkCursorRange = setting.chunkCursorRange;
            const passageSize = setting.passageSize;
            const passageThreshold = setting.passageThreshold;
            const faqThreshold = setting.faqThreshold;
            if (chunkSize < 100 || chunkSize > 1000) {
                toast.error("chunkSize는 100이상 1000이하로 입력해주세요")
                return;
            }
            if (chunkCursorRange < 0 || chunkCursorRange > 5) {
                toast.error("chunkCursorRange 0이상 5이하로 입력해주세요")
                return;
            }
            if (passageSize < 0 || passageSize > 10) {
                toast.error("chunkCursorRange 0이상 5이하로 입력해주세요")
                return;
            }
            if (passageThreshold < 75 || passageThreshold > 100) {
                toast.error("chunkCursorRange 75이상 100이하로 입력해주세요")
                return;
            }
            if (faqThreshold < 0 || faqThreshold > 100) {
                toast.error("faqThreshold는 0이상 100이하로 입력해주세요")
                return;
            }
            passageSearchSetting = {
                type: SearchEngineType.ADA,
                setting: engineSetting as ADASetting,
            };
        } else if (searchEngineModel === SearchEngineType.QA42) {
            const setting = engineSetting as Qa42Setting;
            const passageSize = setting.passageSize;
            const faqThreshold = setting.faqThreshold;
            const mrcAnswerThreshold = setting.mrcAnswerThreshold;
            const mrcThreshold = setting.mrcThreshold;

            if (passageSize < 0 || passageSize > 10) {
                toast.error("검색할 passageSize는 0이상 10이하로 입력해주세요")
                return;
            }
            if (faqThreshold < 0 || faqThreshold > 100) {
                toast.error("faqThreshold는 0이상 100이하로 입력해주세요")
                return;
            }
            if (mrcAnswerThreshold < 0 || mrcAnswerThreshold > 100) {
                toast.error("mrcAnswerThreshold는 0이상 100이하로 입력해주세요")
                return;
            }
            if (mrcThreshold < 0 || mrcThreshold > 100) {
                toast.error("mrcThreshold는 0이상 100이하로 입력해주세요")
                return;
            }

            passageSearchSetting = {
                type: SearchEngineType.QA42,
                setting: engineSetting as Qa42Setting,
            };
        }
        if (specification) {
            const body: SearchSpecificationUpdateRequest = {
                key: key!,
                description,
                passageSearchSetting: passageSearchSetting!,
            }
            dialog.open({
                variant: "danger",
                title: "검색설정 수정",
                content: "검색설정을 수정하려고 합니다. 계속 하시겠습니까?",
                onConfirm: async () => {
                    try {
                        await updateSpecification({_id: specification._id, ...body});
                        toast.success(intl.formatMessage({id: "i0177"}))
                        await queryClient.invalidateQueries(["searchSpecificationHistory", specification._id], {exact: true});
                        onSubmit(specification._id);
                    } catch (e) {
                        toast.error(intl.formatMessage({id: "i0178"}))
                    }
                }
            });
        } else {
            const body: SearchSpecificationCreateRequest = {
                key: key!,
                description,
                passageSearchSetting: passageSearchSetting!,
            }
            try {
                await createSpecification(body);
                toast.success(intl.formatMessage({id: "i0179"}))
                onSubmit(body.key);
            } catch (e) {
                toast.error(intl.formatMessage({id: "i0180"}))
            }
        }
    }
    const onClickShowHistoryModal = () => {
        modal.open(SEARCH_SPECIFICATION_HISTORY_MODAL_ID)
    }
    useEffect(() => {
        const data = specification ?? copySpecification
        if (data) {
            setSelectedSearchEngine(data.passageSearchSetting.type as SearchEngineType);
            setEngineSetting(data.passageSearchSetting.setting)
        }
    }, [specification, copySpecification])

    const onClickCopy = async () => {
        if (!specification) return;
        const key = specification.key
        const currentDate = moment().format('YYYY-MM-DD HH:mm');
        const copiedKey = `${key}_copied(${currentDate})`
        const copySpecification = {...specification, key: copiedKey}
        onClickCopySpecification(copySpecification)
    }

    return (
        <>
            <div className="flex justify-end space-x-2 text-xl font-semibold">
                {specification &&
                    <div className="flex items-center justify-between space-x-2">
                        <button className="btn bg-purple-800 text-white transition-colors duration-200"
                                onClick={onClickShowHistoryModal}>
                            변경이력 보기
                        </button>
                        <SearchSpecificationHistoryModal searchSpecificationId={specification._id}/>
                        <button className="btn bg-green-500 text-white transition-colors duration-200"
                                onClick={onClickCopy}>
                            사본생성
                        </button>
                    </div>
                }
                <div className="flex items-center space-x-2">
                    <button className="btn btn-primary transition-colors duration-200" onClick={onClickSubmit}>
                        {specification ? intl.formatMessage({id: "i0200"}) : intl.formatMessage({id: "i0201"})}
                    </button>
                </div>

                {specification &&
                    <button className="btn btn-danger transition-colors duration-200" onClick={onClickDelete}>
                        {intl.formatMessage({id: "i0199"})}
                    </button>
                }

                <button className="btn btn-secondary-outline transition-colors duration-200"
                        onClick={onClickCancel}>
                    {intl.formatMessage({id: "i0198"})}
                </button>
            </div>

            <div className="border border-gray-400 rounded-md shadow-lg p-5 flex flex-col overflow-y-auto">
                <div className="text-2xl font-semibold mb-3 border-b-2 pb-3">
                    <div className="flex items-center justify-between">
                        <div className={"flex justify-between items-center"}>
                            <p>검색 설정</p>
                        </div>
                    </div>
                </div>
                <div className="form-input-group flex">
                    <label htmlFor="ses-key"
                           className="form-label min-w-[150px] text-lg">
                        엔진 키
                    </label>
                    <div className="w-full">
                        <input id="ses-key"
                               defaultValue={searchSpecificationKey}
                               ref={keyRef}
                               onChange={onChangeKey}
                               className="form-input w-full focus:outline-none"
                        />
                        {invalid.key && <small className="text-red-500">{intl.formatMessage({id: "i0184"})}</small>}
                    </div>
                </div>

                <div className="form-input-group flex">
                    <label htmlFor="ses-description"
                           className="form-label min-w-[150px] text-lg">
                        설명
                    </label>
                    <input id="ses-description"
                           defaultValue={defaultDescription}
                           ref={descriptionRef}
                           className="form-input w-full focus:outline-none"
                    />
                </div>


                <div className="form-input-group flex">
                    <label htmlFor="ses-model"
                           className="form-label min-w-[150px] text-lg">
                        검색엔진
                    </label>
                    <select id="ses-model"
                            className="form-select w-full px-2 focus:outline-none"
                            ref={searchModelRef}
                            value={selectedSearchEngine}
                            onChange={onChangeModel}
                            disabled={!!specification}
                    >
                        {SUPPORTED_MODEL.map((item) => (
                            <option key={item} value={item}>{item}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <EngineSetting setEngineSetting={setEngineSetting} engineSetting={engineSetting}
                                   selectedSearchEngine={selectedSearchEngine}/>
                </div>

                {specification &&
                    <>
                        <div className="form-input-group flex items-center">
                            <label className="form-label min-w-[150px] text-lg">
                                {intl.formatMessage({id: "i0196"})}
                            </label>
                            <p>{moment.utc(specification.createdAt).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                        </div>

                        <div className="form-input-group flex items-center">
                            <label className="form-label min-w-[150px] text-lg">
                                {intl.formatMessage({id: "i0197"})}
                            </label>
                            <p>{moment.utc(specification.updatedAt).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                        </div>
                    </>
                }
            </div>
        </>
    )
};

export default SearchSpecificationSettingForm;
