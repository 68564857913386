import {useIntl} from "react-intl";
import React, {useCallback, useMemo, useState} from "react";
import _ from "lodash";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import classNames from "classnames";
import useChannelCommonSetting from "../../query/channelCommonSetting/useChannelCommonSetting";
import {ChannelCommonSetting, ChannelCommonSettingBase} from "../../model/ChannelCommonSetting";
import ChannelCommonSettingForm from "../../component/channelCommonSetting/ChannelCommonSettingForm";
// import ChannelCommonSettingGuide from "../../component/channelCommonSetting/ChannelCommonSettingGuide";

const ChannelCommonSettingView = () => {
    const intl = useIntl();
    const [searchKey, setSearchKey] = useState<string>();
    const { data: channelCommonSettings } = useChannelCommonSetting(searchKey);

    const [showForm, setShowForm] = useState(false);
    const [selectedChannelCommonSettingKey, setSelectedChannelCommonSettingKey] = useState<string>();
    const [copyChannelCommonSetting, setCopyChannelCommonSetting] = useState<ChannelCommonSettingBase>();

    const selectedChannelCommonSetting = useMemo(() => {
        return channelCommonSettings?.find(channelCommonSetting => channelCommonSetting.key === selectedChannelCommonSettingKey);
    }, [channelCommonSettings, selectedChannelCommonSettingKey]);

    const hideForm = useCallback(() => {
        setSelectedChannelCommonSettingKey(undefined);
        setShowForm(false);
    }, [setSelectedChannelCommonSettingKey, setShowForm]);

    const onChangeSearch = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchKey(e.target.value);
        hideForm();
    }, 300);

    const onClickChannelCommonSetting = (channelCommonSetting: ChannelCommonSetting) => {
        setSelectedChannelCommonSettingKey(channelCommonSetting.key)
        setShowForm(true);
    }

    const onClickAddGlobalVariables = () => {
        setCopyChannelCommonSetting(undefined);
        setSelectedChannelCommonSettingKey(undefined);
        setShowForm(true);
    }

    const onClickCancelGlobalVariables = () => {
        hideForm();
    }

    const onClickDeleteGlobalVariables = () => {
        hideForm();
    }

    const onClickSubmitGlobalVariables = (specificationKey: string) => {
        setSelectedChannelCommonSettingKey(specificationKey);
    }

    const onClickCopyChannelCommonSetting = (channelCommonSetting: ChannelCommonSettingBase) => {
        setCopyChannelCommonSetting(channelCommonSetting);
        setSelectedChannelCommonSettingKey(undefined);
        setShowForm(true);
    }
    
    return (
        <div className="flex flex-col h-full">
            {/* Content */}
            <div className="flex space-x-5">
                <div className="w-[30%] flex flex-col space-y-2">
                    <div className="flex items-center justify-between">
                        <p className="text-xl pl-2">채널 설정 프리셋 목록</p>
                        <button className="btn btn-info btn-sm" onClick={onClickAddGlobalVariables}>
                            {intl.formatMessage({id: "i0204"})}
                        </button>
                    </div>

                    {/* Search bar */}
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <MagnifyingGlassIcon className="w-5 h-5"/>
                        </div>
                        <input type="search"
                               id="search"
                               className="form-input pl-10"
                               placeholder="Search by key"
                               onChange={onChangeSearch}
                        />
                    </div>

                    {/* List */}
                    <div className="flex-grow space-y-2 overflow-y-auto max-h-[800px]">
                        {channelCommonSettings?.map((channelCommonSetting) => {
                            const description = channelCommonSetting.description === "" ? "설명 없음" : (channelCommonSetting?.description ?? "설명 없음")
                            return (
                                <div key={channelCommonSetting._id}
                                     className={classNames(
                                         "border border-gray-600 rounded-lg px-5 py-3 cursor-pointer transition-colors duration-200 hover:bg-gray-200",
                                         selectedChannelCommonSettingKey === channelCommonSetting.key && "bg-gray-300"
                                     )}
                                     onClick={() => onClickChannelCommonSetting(channelCommonSetting)}>
                                    <div className="flex items-center justify-between space-x-2">
                                        <p className="text-lg truncate">{channelCommonSetting.key}</p>
                                        {channelCommonSetting.default &&
                                            <div className="px-3 py-1 rounded-md bg-blue-600 text-white text-sm">
                                                DEFAULT
                                            </div>
                                        }
                                    </div>
                                    <p className="text-sm">{description}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="flex-grow space-y-2">
                    {showForm &&
                        <ChannelCommonSettingForm key={`specification-setting-form-${selectedChannelCommonSetting?.key}`}
                                                    channelCommonSetting={selectedChannelCommonSetting}
                                                    copyChannelCommonSetting={copyChannelCommonSetting}
                                                    onCancel={onClickCancelGlobalVariables}
                                                    onDelete={onClickDeleteGlobalVariables}
                                                    onSubmit={onClickSubmitGlobalVariables}
                                                    onClickCopyChannelCommonSetting={onClickCopyChannelCommonSetting}
                        />
                    }

                    {/*<ChannelCommonSettingGuide />*/}
                </div>
            </div>
        </div>
    );
}

export default ChannelCommonSettingView;