import React, {useEffect, useMemo, useRef} from "react";
import {AnswerType, replyQuestionsDecode} from "../../model/llmSearchTestPanel/QueryAnswers";
import {useIntl} from "react-intl";
import {DetailSettingRowFragment, DetailSettingTable} from "./component/DetailSettingTable";
import ProcessResultSection from "./proccessResult/ProcessResultSection";
import {QueryLog} from "../../model/QueryLog";
import useChannel from "../../query/channel/useChannel";
import useChannelCommonSettingHistory from "../../query/channelCommonSetting/useChannelCommonSettingHistory";

const LLMResponseReport: React.FC<{type: AnswerType, queryLog?: QueryLog}> = ({type, queryLog}) => {
    const intl = useIntl();

    const questionDetailRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        questionDetailRef.current!.scrollIntoView();
    }, [queryLog])

    return (
        <div ref={questionDetailRef}>
            {queryLog && (
                <div className="flex flex-col space-y-5 p-5 mb-5">
                    <ChannelInfoCard queryLog={queryLog} />

                    <ProcessResultSection queryLog={queryLog} />

                    {type === AnswerType.ANSWER && queryLog.answer && (
                        <div className={"border-2 border-black rounded-xl shadow-xl p-5 flex flex-col space-y-3 divide-y-4  leading-6 whitespace-normal"}>
                            <div className={"text-center font-semibold text-3xl"}>{intl.formatMessage({id: "i11038"})}</div>
                            <div>
                                <div className={"px-4 py-2"}>
                                    {queryLog.answer.split("\n").map(row => row !== "" ? <div>{row}</div> : <br/>)}
                                </div>
                            </div>
                        </div>
                    )}
                    {type === AnswerType.REPLYQUESTION && (
                        <div className={"border-2 border-black rounded-xl shadow-xl p-5 flex flex-col space-y-3 divide-y-4"}>
                            <div className={"text-center font-semibold text-3xl"}>{intl.formatMessage({id: "i11040"})}</div>
                            <DetailSettingTable>
                                {DetailSettingRowFragment(intl.formatMessage({id: "i11097"}), (!!queryLog.answer).toString())}
                            </DetailSettingTable>
                            <div>
                                <div className={"ml-4 mt-2"}>
                                    {queryLog.answer ?
                                        replyQuestionsDecode(queryLog.answer).map((question:string) => <div>{question}</div>)
                                        : (<div>{intl.formatMessage({id: "i11039"})}</div>)}
                                </div>
                            </div>
                        </div>
                    )}
                </ div>
            )}
        </div>
    )
}

const ChannelInfoCard: React.FC<{queryLog: QueryLog}> = ({queryLog}) => {
    const intl = useIntl();

    const {data: channelResponse} = useChannel(queryLog.channelId);
    const channel = useMemo(() => channelResponse?.channel, [channelResponse?.channel]);
    const {data: channelCommonSettingHistory} = useChannelCommonSettingHistory();
    const channelCommonSetting = channelCommonSettingHistory?.find(item => item._id === queryLog.channelCommonSettingHistoryId)?.channelCommonSetting;
    return (
        <>
            {channel && channelCommonSetting && (
                <div className={"border-2 border-black rounded-xl shadow-xl p-5 flex flex-col space-y-3 divide-y-4"}>
                    <div className={"text-center font-semibold text-3xl"}>{intl.formatMessage({id: "i11041"})}</div>
                    <DetailSettingTable>
                        {DetailSettingRowFragment(intl.formatMessage({id: "i11042"}), channel.name || channel._id)}
                        {DetailSettingRowFragment(intl.formatMessage({id: "i11008"}), channelCommonSetting.key)}
                    </DetailSettingTable>
                </div>
            )}
        </>
    );
}

export default LLMResponseReport;
