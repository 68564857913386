import PassageSearchResult, {
    FoundedPassages, PerfectAnswer,
    FoundedUnstableAnswers
} from "../../../model/llmSearchTestPanel/extra/PassageSearchResult";
import React, { ReactNode, useMemo } from "react";
import { useIntl } from "react-intl";
import { DetailSettingRowFragment, DetailSettingTable, renderText } from "../component/DetailSettingTable";
import DetailCard from "../component/DetailCard";
import DetailDisclosure from "../component/DetailDisclosure";

interface PassageSearchSectionProps {
    type: "perfect-answer" | "unstable-answers" | "passages"
    latency: number
    result: PassageSearchResult
}

const PassageSearchSection: React.FC<PassageSearchSectionProps> = (props) => {
    const {type, latency, result} = props;

    if (type === "perfect-answer") return <AnswerCard latency={latency}
                                                      result={result as PerfectAnswer} />

    if (type === "passages") return <PassagesCard latency={latency}
                                                  result={result as FoundedPassages} />

    if (type === "unstable-answers") return <UnstableAnswersCard latency={latency}
                                                                 result={result as FoundedUnstableAnswers} />

    return <div></div>
};

const PassagesCard: React.FC<{latency: number, result: FoundedPassages}> = ({latency, result}) => {
    const intl = useIntl();

    const summary: ReactNode = useMemo(() => {
        const highestScore = Math.max(...result.passages.map(passage => passage.passageScore));

        return (
            <DetailSettingTable>
                {DetailSettingRowFragment(intl.formatMessage({id: 'i11003'}), result.passages.length)}
                {!!result.threshold && DetailSettingRowFragment(intl.formatMessage({id: 'i11004'}), result.threshold.toFixed(2))}
                {!!result.passages.length && highestScore && DetailSettingRowFragment(intl.formatMessage({id: 'i11005'}), highestScore.toFixed(2))}
            </DetailSettingTable>
        )
    }, [intl, result]);

    if (!result.passages.length) {
        return (
            <DetailCard title={intl.formatMessage({id: "i11051"})}
                        latency={latency}>
                {summary}
            </DetailCard>
        )
    }

    return (
        <DetailDisclosure title={intl.formatMessage({id: "i11051"})}
                          latency={latency}
                          summary={summary}>
            <div className={"flex flex-col space-y-3 divide-y-2 p-2"}>
                <div className={"font-semibold text-xl"}>{intl.formatMessage({id: "i11070"})}</div>
                <div className="flex flex-col space-y-3 divide-y-2 pt-4">{
                    result.passages.map(({content, passageScore, title, titleLink}, index) =>
                        <div className={"flex flex-col space-y-2  leading-6"}>
                            <div className="flex justify-between items-center mt-1">
                                <div className={"font-medium text-xl mt-2"}>{`${intl.formatMessage({id: "i11071"})} ${index+1}`}</div>
                                <div className={"flex items-center"}>
                                    {`${intl.formatMessage({id: "i11050"})}: ${passageScore.toFixed(2)}`}
                                </div>
                            </div>

                            <div className={"p-1 flex flex-col leading-6"}>
                                <div>{`${intl.formatMessage({id: 'i11006'})}: ${title}`}</div>
                                <div>{`${intl.formatMessage({id: 'i11007'})}: ${titleLink}`}</div>
                            </div>

                            <div className={"ml-4 p-1 px-4 flex flex-col w-full border-2 rounded-xl whitespace-normal"}>
                                {renderText(content)}
                            </div>
                        </div>
                    )
                }</div>
            </div>
        </DetailDisclosure>
    )
}

const UnstableAnswersCard: React.FC<{latency: number, result: FoundedUnstableAnswers}> = ({latency, result}) => {
    const intl = useIntl();

    const summary: ReactNode = useMemo(() => {
        const highestScore = Math.max(...result.passages.map(passage => passage.score));
        return (
            <div className={"flex flex-col space-y-2  leading-6 pt-1"}>
                <DetailSettingTable>
                    {DetailSettingRowFragment(intl.formatMessage({id: 'i11003'}), result.passages.length)}
                    {!!result.passages.length && highestScore && DetailSettingRowFragment(intl.formatMessage({id: 'i11005'}), highestScore.toFixed(2))}
                </DetailSettingTable>
                {result.passages.map((passage, index) => (
                    <div>
                        <div
                            className={"flex justify-start items-center font-medium text-xl"}>MRC 답변 {index + 1}</div>
                        <div className={"ml-4 p-1 px-4 flex flex-col w-full border-2 rounded-xl"}>
                            {renderText(passage.answer)}
                        </div>
                    </div>
                ))}
                {result.passages.length === 0 && (
                    <div>{"탐색된 답변이 없습니다."}</div>
                )}
            </div>
        )
    }, [intl, result])

    return (
        <DetailDisclosure title="MRC 문서검색"
                          latency={latency}
                          summary={summary}>
            <div className={"flex flex-col space-y-3 divide-y-2 p-2"}>
                <div className={"font-semibold text-xl"}>{intl.formatMessage({id: "i11001"})}</div>
                <div className="flex flex-col space-y-3 divide-y-2 pt-4">{
                    result.passages.map(({answer, passage, title, titleLink, score}, index) =>
                        <div className={"flex flex-col space-y-2  leading-6"}>
                            <div className="flex justify-between items-center mt-1">
                                <div className={"font-medium text-xl mt-2"}>MRC 결과 {index+1}</div>
                                <div className={"flex items-center"}>
                                    MRC 유사도: {score.toFixed(2)}
                                </div>
                            </div>

                            <div className={"p-1 flex flex-col leading-6"}>
                                {title && <div>{`${intl.formatMessage({id: 'i11006'})}: ${title}`}</div>}
                                {titleLink && <div>{`${intl.formatMessage({id: 'i11007'})}: ${titleLink}`}</div>}
                            </div>

                            <div className={"ml-4 p-1 px-4 flex flex-col w-full border-2 rounded-xl whitespace-normal"}>
                                <div className={"font-medium text-lg my-1"}>{intl.formatMessage({id: "i11011"})}</div>
                                {renderText(answer)}
                            </div>

                            <div className={"ml-4 p-1 px-4 flex flex-col w-full border-2 rounded-xl whitespace-normal"}>
                                <div className={"font-medium text-lg my-1"}>{intl.formatMessage({id: "i11071"})}</div>
                                {renderText(passage)}
                            </div>
                        </div>
                    )
                }</div>
            </div>
        </DetailDisclosure>
    )
}

const AnswerCard: React.FC<{latency: number, result: PerfectAnswer}> = ({latency, result}) => {
    const intl = useIntl();

    return (
        <DetailCard title="FAQ 문서검색"
                    latency={latency}>
            <div className={"flex flex-col space-y-2  leading-6 pt-1"}>
                <div className={"flex justify-start items-center font-medium text-xl"}>{intl.formatMessage({id: "i11011"})}</div>
                <div className={"ml-4 p-1 px-4 flex flex-col w-full border-2 rounded-xl whitespace-normal"}>
                    {renderText(result.answer)}
                </div>
            </div>
        </DetailCard>
    )
}

export default PassageSearchSection;
