const language: Record<string, string> = {
    e0001: "접근 권한 없음",
    e0002: "이 채널에 접근할 권한이 없습니다.",
    e0003: "페이지 없음",
    e0004: "방문하려는 페이지를 찾을 수 없습니다.\n방문하려는 주소가 정확한지 확인해주세요.",
    e0005: "에러가 발생했습니다. 잠시 후 다시 이용해 주세요.",
    e0006: "서비스 사용에 불편함을 드려 죄송합니다.\n서비스에 시스템 에러가 발생하여 페이지를 표시할 수 없습니다.",
    i0001: "SiteBunnyAdmin",
    i0100: "페르소나 설정",
    i0101: "페르소나 리스트",
    i0102: "디폴트 설정",
    i0103: "페르소나 설정",
    i0104: "페르소나 생성",
    i0105: "페르소나 업데이트",
    i0106: "페르소나 삭제",
    i0107: "페르소나 정보",
    i0108: "키",
    i0109: "이름",
    i0110: "프롬프트",
    i0111: "페르소나 설명",
    i0112: "키를 입력하세요",
    i0113: "이름을 입력하세요",
    i0114: "프롬프트를 입력하세요",
    i0115: "해당 페르소나에 대한 설명을 입력하세요",
    i0116: "페르소나 설정에 성공했습니다",
    i0117: "페르소나 설정에 실패했습니다",
    i0118: "페르소나 수정에 성공했습니다",
    i0119: "페르소나 수정에 실패했습니다",
    i0120: "페르소나 생성에 성공했습니다",
    i0121: "페르소나 생성에 실패했습니다",
    i0122: "디폴트 페르소나",
    i0123: "닫기",
    i0124: "생성",
    i0125: "페르소나 삭제에 성공했습니다",
    i0126: "페르소나 삭제에 실패했습니다",
    i0127: "생성 중 입니다",
    i0128: "생성 성공",
    i0129: "생성 실패",
    i0130: "디폴트 페르소나 설정",
    i0131: "디폴트 페르소나 설정 성공",
    i0132: "디폴트 페르소나 설정 실패",
    i0133: "수정",
    i0134: "삭제",
    i0135: "수정 중",
    i0136: "수정 성공",
    i0137: "수정 실패",
    i0138: "삭제 중",
    i0139: "페르소나 삭제 성공",
    i0140: "페르소나 삭제 실패",
    i0141: "키값은 영어나 숫자를 이용하여 작성해주세요",
    i0142: "선택한 페르소나를 디폴트 페르소나로 설정합니다.",
    i0143: "페르소나 삭제",
    i0144: "선택한 페르소나를 삭제합니다.",
    i0145: "페르소나 생성",
    i0146: "페르소나 수정",
    i0147: "디폴트로 설정된 페르소나를 삭제할수 없습니다.",
    i0148: "선택된 페르소나를 삭제 합니다.",
    i0149: "페르소나를 삭제에 성공했습니다.",
    i0150: "페르소나를 삭제하는 도중 에러가 발생했습니다.",
    i0151: "키값과 프롬프트는 필수값입니다.",
    i0152: "페르소나 수정에 실패했습니다.",
    i0153: "선택된 페르소나를 수정합니다.",
    i0154: "페르소나 수정에 성공했습니다.",
    i0155: "페르소나 생성에 성공했습니다.",
    i0156: "페르소나 생성에 실패했습니다.",
    i0157: "페르소나 이름을 검색하세요",
    i0158: "Instruction Prompt를 입력하세요.",
    i0159: "페르소나 프롬프트를 입력하세요.",
    i0160: "명령어 프롬프트",
    i0161: "프롬프트",
    i0162: "챗봇의 답변을 생성하는데 사용되는 페르소나 관리 페이지입니다.",
    i0163: "사용자 질의를 분석하는데 사용되는 페르소나 관리 페이지입니다.",
    i0164: "답변생성 페르소나 리스트",
    i0165: "질의분석 페르소나 리스트",
    i0166: "검색 설정 가이드",
    i0167: "중복을 체크하는 도중 에러가 발생했습니다.",
    i0168: "중요 파라미터 삭제",
    i0169: "파라미터는 검색 설정에 꼭 필요한 파라미터일 수 있습니다. 그래도 삭제하시겠습니까?",
    i0170: "삭제",
    i0171: "디폴트로 설정된 검색 설정은 삭제할 수 없습니다.",
    i0172: "검색 설정 정보 삭제",
    i0173: "검색 설정 정보를 삭제하시겠습니까?",
    i0174: "검색 설정 정보를 삭제했습니다.",
    i0175: "검색 설정 정보를 삭제하는 도중 에러가 발생했습니다.",
    i0176: "빈칸을 채워주세요.",
    i0177: "검색 설정 정보를 수정했습니다.",
    i0178: "검색 설정 정보를 수정하는 도중 에러가 발생했습니다.",
    i0179: "검색 설정을 생성했습니다.",
    i0180: "검색 설정을 생성하는 도중 에러가 발생했습니다.",
    i0181: "검색 설정 수정하기",
    i0182: "새로운 검색 설정 추가하기",
    i0183: "Engine Key",
    i0184: "이미 있는 key나 default는 사용할 수 없습니다.",
    i0185: "Description",
    i0186: "Default",
    i0187: "Model",
    i0188: "QA42모델은 질의분석을 통해 사용자의 질의를 분석하여 데이터를 검색합니다. QA42모델에서 사용할 Prompt에 대한 설정이 필요합니다.",
    i0189: "System Prompt",
    i0190: "User Prompt",
    i0191: "문서검색에서 사용되는 질의 분석 과정에서 사용할 질의분석 시스템 프롬프트를 입력하세요",
    i0192: "문서검색에서 사용되는 질의 분석 과정에서 사용할 질의분석 유저 프롬프트를 입력하세요",
    i0193: "파라미터 이름",
    i0194: "파라미터 값",
    i0195: "+ 파라미터 추가하기",
    i0196: "생성된 날짜",
    i0197: "수정된 날짜",
    i0198: "취소하기",
    i0199: "삭제하기",
    i0200: "수정하기",
    i0201: "저장하기",
    i0202: "검색 설정 관리",
    i0203: "검색 설정 목록",
    i0204: "추가하기",

    i0206: "검색 설정",
    i0207: "연동 QA42 키: ",
    i0208: "검색 설정 키",
    i0209: "System Prompt는 필수값입니다.",
    i0210: "User Prompt는 필수값입니다.",
    i0211: "QA42 Key는 QA42와 연동하기위한 필수값입니다.",
    i0212: "Key값을 입력해주세요",
    i0213: "prompt를 입력해주세요",
    i0214: "Instruction Prompt값을 입력해주세요",
    i0215: "파라미터는 프롬프트작성에 필요한 파라미터입니다. 그래도 삭제하시겠습니까?",


    // Channel List & Setting View
    i1000: "채널 리스트",
    i1001: "채널명",
    i1002: "채널 설명",
    i1003: "고객 이름",
    i1004: "LLM 키",
    i1005: "상세 페이지",
    i1006: "없음",
    i1007: "<<",
    i1008: ">>",
    i1009: "전체 검색",
    i10010: "검색 설정 키",
    i10011: "수정 성공",
    i10012: "수정 실패",
    i10013: "채널 상세정보",
    i10014: "저장",
    i10015: "설명",
    i10016: "설명 없음",
    i10017: "채널 프리셋 설정",
    i10018: "페르소나 설정",
    i10019: "DEFAULT",
    i10020: "로딩 중",
    i10021: "IN USE",
    i10022: "삭제 여부",
    i10023: "삭제됨",
    i10024: "제공",
    i10025: "모델",
    i10026: "로딩 중...",
    i10027: "결과를 찾을 수 없습니다",
    i10028: "뒤로",
    i10029: "검색 세팅키",
    i10030: "LLM 세팅키",
    i10031: "고객 이름",
    i10032: "그룹 이름",

    // Question Test Panel
    i11000: "문맥 개수",
    i11001: "답변 및 문서들",
    i11002: "쿼리",
    i11003: "검색된 문서 수",
    i11004: "문서 사용 기준 점수",
    i11005: "최고 유사도",
    i11006: "문서 제목",
    i11007: "문서 출처",
    i11008: "채널 설정",
    i11009: "기본 설정",
    i11010: "채널 설정",
    i11011: "답변",
    i11012: "문맥 생성 결과",
    i11013: "LLM 정보",
    i11014: "기본 검색 설정",
    i11015: "쿼리 분석 결과",
    i11016: "검색 설정",
    i11017: "모델",
    i11018: "유저 프롬프트",
    i11019: "시스템 프롬프트",
    i11020: "파라미터",
    i11021: "페르소나 키",
    i11022: "검색 설정",
    i11023: "페르소나",
    i11024: "기본 페르소나",
    i11025: "기본 LLM",
    i11026: "질의분석 프롬프트",
    i11027: "프롬프트",
    i11028: "챗봇 테스트",
    i11029: "테스트할 채널을 선택해주세요",
    i11030: "에러가 발생했습니다.",
    i11031: "채널 정보를 받는데 실패했습니다.",
    i11032: "질의 응답 테스트",
    i11033: "채널명",
    i11034: "LLM",
    i11035: "선택",
    i11036: "Reply Question Detail 보기",
    i11037: "Reply Question이 없습니다.",
    i11038: "답변",
    i11039: "답변이 없습니다",
    i11040: "Reply Question Response",
    i11041: "채널 정보",
    i11042: "채널",
    i11043: "검색 설정 키",
    i11044: "LLM 키",
    i11045: "관련 문서 검색",
    i11046: "LLM 생성용 문맥",
    i11047: "LLM 답변 생성",
    i11048: "소요 시간",
    i11049: "쿼리 분석",
    i11050: "문서 유사도",
    i11051: "Passage 문서 검색",
    i11052: "맥락 생성 조건",
    i11053: "문서 사용 여부",
    i11054: "최대 토큰수",
    i11055: "모델 최대 토큰수",
    i11056: "레퍼런스 노출 여부",
    i11057: "Yes",
    i11058: "No",
    i11059: "프롬프트",
    i11060: "시스템",
    i11061: "명령어",
    i11062: "LLM 응답",
    i11063: "LLM 답변",
    i11064: "결과",
    i11065: "닫기",
    i11066: "펼치기",
    i11067: "검색 설정",
    i11068: "임베딩 방식",
    i11069: "청크 범위",
    i11070: "검색된 문서들",
    i11071: "문서",
    i11072: "맥락",
    i11073: "키",
    i11074: "설명",
    i11075: "제공사",
    i11076: "모델",
    i11077: "파라미터",
    i11078: "LLM 결과 문맥",
    i11079: "Turn",
    i11080: "테스트",
    i11081: "검색 설정",
    i11082: "LLM",
    i11083: "원형 쿼리",
    i11084: "Keywords",
    i11085: "Paraphrased",
    i11086: "Extended",
    i11087: "에러 발생",
    i11088: "타입",
    i11089: "상세",
    i11090: "에러 추적",
    i11091: "ETC",
    i11092: "키워드 없음",
    i11093: "의역 없음",
    i11094: "확장 없음",
    i11095: "에러 발생 프로세스",
    i11096: "에러 발생: ",
    i11097: "Reply question 생성 여부",
    i11098: "채널 데이터 로드",
    i11099: "쿼리 임베딩",
    i11100: "에러 코드",
    i11101: "채널 설정 프리셋",
    i11102: "채널 그룹",
    i11103: "문서 검색",

}

export default language;
