import React, {Fragment} from "react";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import {ChannelCommonSetting} from "../../model/ChannelCommonSetting";

type SelectionMenuProps = {
    channelCommonSettings: ChannelCommonSetting[] | [],
    selectedKey: string,
    title?: string,
    onClickItem: (id: string, key: string) => void,
    itemNode?: (item: any) => React.ReactNode
}

const KeySelectionMenu: React.FC<SelectionMenuProps> = (props) => {
    const {channelCommonSettings, selectedKey, title, onClickItem, itemNode} = props;

    return (
        <Menu as="div" className="relative">
            {({ open }) =>
                <>
                    <Menu.Button className={classNames(
                        "transition-colors duration-200 cursor-pointer w-full text-start px-3 p-2 rounded-lg border-2 border-gray-320",
                        open ? "bg-blue-100 text-blue-400 border-blue-100" : "hover:bg-gray-200 text-gray-500"
                    )}>
                        {title ?? selectedKey}
                    </Menu.Button>
                    <Transition as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95">
                        <Menu.Items className={classNames(
                            "absolute border-2 border-gray-320 w-full rounded-2xl bg-white shadow-lg z-50 left-0 max-h-96 overflow-auto",
                        )}>
                            {channelCommonSettings.map(item => {
                                return (
                                    <div key={item.key}>
                                        <div className="p-3 space-y-3">
                                            <Menu.Item>
                                                <div className={
                                                    classNames("w-full transition-colors duration-200 text-gray-500 rounded-md px-3 py-1 cursor-pointer",
                                                        selectedKey === item.key ? "bg-blue-100" :"hover:bg-gray-100"
                                                    )}
                                                        onClick={() => onClickItem(item._id, item.key)}>
                                                    {itemNode && itemNode(item)}
                                                </div>
                                            </Menu.Item>
                                        </div>
                                    </div>
                                )
                            })}
                        </Menu.Items>
                    </Transition>
                </>
            }
        </Menu>
    )
};

export default KeySelectionMenu;
