import AnalyzedQueryResult from "../../../model/llmSearchTestPanel/extra/AnalyzedQueryResult";
import React, { ReactNode, useMemo } from "react";
import { useIntl } from "react-intl";
import { DetailSettingRowFragment, DetailSettingTable, renderText } from "../component/DetailSettingTable";
import DetailCard from "../component/DetailCard";
import DetailDisclosure from "../component/DetailDisclosure";

interface AnalyzedQuerySectionProps {
    latency: number
    result: AnalyzedQueryResult
}

const AnalyzedQuerySection: React.FC<AnalyzedQuerySectionProps> = (props) => {
    const {latency, result} = props;
    const intl = useIntl();

    const summary: ReactNode = useMemo(() => (
        <div className={"flex flex-col space-y-3 divide-y-2  leading-6 p-4"}>
            <div className="flex flex-col space-y-3">
                <div className={"font-semibold text-xl"}>{intl.formatMessage({id: "i11002"})}</div>
                <div className={"ml-4 p-1 px-4 flex flex-col w-full border-2 rounded-xl"}>
                    {renderText(result.query)}
                </div>
            </div>
            <div className="flex flex-col space-y-3 pt-2">
                <div className={"font-semibold text-xl"}>{intl.formatMessage({id: "i11083"})}</div>
                <div className={"ml-4 p-1 px-4 flex flex-col w-full border-2 rounded-xl"}>
                    {renderText(result.originalQuery)}
                </div>
            </div>
        </div>
    ), [intl, result]);

    if (!('keywords' in result)) {
        return (
            <DetailCard title={intl.formatMessage({id: "i11049"})} latency={latency}>
                <div className="flex flex-col space-y-3 p-4  leading-6">
                    <div className={"font-semibold text-xl"}>{intl.formatMessage({id: "i11002"})}</div>
                    <div className={"ml-4 p-1 px-4 flex flex-col w-full border-2 rounded-xl"}>
                        {renderText(result.query)}
                    </div>
                </div>
            </DetailCard>
        );
    }

    return (
        <DetailDisclosure title={intl.formatMessage({id: "i11049"})}
                          latency={latency}
                          summary={summary}>
            <DetailSettingTable>
                <label className="text-xl">
                    질의분석 검색 결과
                </label>
                {DetailSettingRowFragment(intl.formatMessage({id: 'i11103'}), result.searchPassage.toString())}
                {DetailSettingRowFragment(intl.formatMessage({id: 'i11084'}), result.keywords)}
                {DetailSettingRowFragment(intl.formatMessage({id: 'i11085'}), result.paraphrased)}
                {DetailSettingRowFragment(intl.formatMessage({id: 'i11086'}), result.extended)}
            </DetailSettingTable>
            <div className="flex flex-col space-y-3 divide-y-2 p-2">
                <div className={"flex flex-col space-y-3"}>
                    <div className="leading-6 whitespace-normal flex flex-col divide-y-4">
                        {result.context.slice(0,2).map(({role, content}, index: number) => (
                            <div key={`context-${role}`}>
                                <div className={"font-semibold text-xl mt-2 mb-2"}>{`${intl.formatMessage({id: "i11072"})} ${index+1}`}</div>
                                <div className={"flex flex-col justify-start space-y-2 py-2"}>
                                    <div className={"text-lg"}>{"role"}</div>
                                    <div className={"ml-4 p-1 px-4 flex flex-col w-full border-2 rounded-xl"}>
                                        {role}
                                    </div>
                                </div>
                                <div className={"flex flex-col justify-start space-y-2 py-2"}>
                                    <div className={"text-lg"}>{"content"}</div>
                                    <div className={"ml-4 p-1 px-4 flex flex-col w-full border-2 rounded-xl"}>
                                        {renderText(content)}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </DetailDisclosure>
    );
};

export default AnalyzedQuerySection;
