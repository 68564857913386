import React, { ReactNode, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { Disclosure, Transition } from "@headlessui/react";
import classNames from "classnames";

interface DetailDisclosureProps {
    title: string
    summary: ReactNode | undefined
    latency?: number
    children: ReactNode
}

const DetailDisclosure: React.FC<DetailDisclosureProps> = (props) => {
    const {title, summary, latency, children} = props
    const intl = useIntl();

    // close disclosure when switch answer or reply question
    const disclosureRef = useRef<HTMLButtonElement>(null);
    useEffect(() => {
        if (disclosureRef.current?.getAttribute("data-headlessui-state") === "open") {
            disclosureRef.current?.click();
        }
    }, [summary])

    return (
        <Disclosure as="div" className={"w-full"}>
            {({ open, close }) => (
                <div className={"border-2 border-black rounded-xl shadow-xl p-5 flex flex-col space-y-3 divide-y-4"}>
                    <>
                        <Disclosure.Button ref={disclosureRef}
                                           className="transition-colors duration-200 flex w-full justify-between items-center hover:bg-gray-100">
                            <div className={"w-full flex flex-col"}>
                                <div className={"relative text-center font-semibold text-3xl mb-2"}>
                                    {title}
                                </div>
                                {latency && (
                                    <div className={"w-full flex justify-end p-2"}>
                                        {`소요시간: ${latency.toFixed(2)} 초`}
                                    </div>
                                )}
                            </div>
                        </Disclosure.Button>
                        <div className={classNames("text-left cursor-default", {"max-h-[15rem] overflow-hidden text-ellipsis": !open})}>{summary}</div>
                        {!open && (
                            <div onClick={() => disclosureRef.current?.click()}
                                 className={"w-full flex justify-center py-2 font-semibold cursor-pointer hover:bg-gray-100"}>
                                {intl.formatMessage({id: "i11066"})}
                            </div>
                        )}
                    </>
                    <Transition enter="transition duration-300 ease-out transform"
                                enterFrom="-translate-x-3 opacity-0"
                                enterTo="opacity-100">
                        <Disclosure.Panel className="py-2">
                            <div className={"flex flex-col divide-y-4"}>
                                {children}
                                <div onClick={() => close()}
                                     className={"w-full flex justify-center py-2 font-semibold cursor-pointer hover:bg-gray-100"}>
                                    {intl.formatMessage({id: "i11065"})}
                                </div>
                            </div>
                        </Disclosure.Panel>
                    </Transition>
                </div>
            )}
        </Disclosure>
    );
}

export default DetailDisclosure;
