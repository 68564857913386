import {Modal} from "../../hook/useModal";
import React, {useState} from "react";
import moment from "moment/moment";
import {useIntl} from "react-intl";
import useSearchSpecificationHistory from "../../query/searchSpecification/useSearchSpecificationHistory";
import {SearchSpecification, SearchSpecificationHistory} from "../../model/searchSpecification";
import classNames from "classnames";

export const SEARCH_SPECIFICATION_HISTORY_MODAL_ID = "search-specification-history-modal";

type SearchSpecificationHistoryModalProps = {
    searchSpecificationId: string
}

const SearchSpecificationHistoryModal:React.FC<SearchSpecificationHistoryModalProps> = (props) => {
    const {searchSpecificationId} = props;
    const intl = useIntl();
    const {data: searchSpecificationHistories} = useSearchSpecificationHistory(searchSpecificationId);

    const [showHistoryDetail, setShowHistoryDetail] = useState(false);
    const [selectedSearchSpecification, setSelectedSearchSpecification] = useState<SearchSpecification>();
    const [selectedId, setSelectedId] = useState<string>();

    const onClickHistoryDetail = (searchSpecificationHistory: SearchSpecificationHistory) => {
        setShowHistoryDetail(true);
        setSelectedSearchSpecification(searchSpecificationHistory.searchSpecification)
        setSelectedId(searchSpecificationHistory._id);
    }

    return (
        <Modal id={SEARCH_SPECIFICATION_HISTORY_MODAL_ID}
               size="2xl"
               title="검색 설정 변경이력"
        >
            <div className="border-b-2 pb-5"/>
            <div className="flex w-full min-h-[800px]">
                <div className="flex-[0_0_20%] min-w-[300px] bg-gradient-to-b from-gray-900 to-gray-800 border-r border-r-2 flex flex-col text-white">
                    {
                        searchSpecificationHistories?.length === 0 &&
                        <div className="flex pt-3 text-xl justify-center items-center font-semibold">
                            변경 이력 없음
                        </div>
                    }
                    {
                        searchSpecificationHistories && searchSpecificationHistories?.length > 0 &&
                        <div className="w-full">
                            <label className="flex pt-3 text-xl justify-center items-center font-semibold">변경 이력</label>
                            <div className="py-5 space-y-2">
                                {searchSpecificationHistories.map((searchSpecificationHistory) => {
                                    const selectedHistory = selectedId === searchSpecificationHistory._id;
                                    return (
                                        <div key={searchSpecificationHistory.version} className={classNames("flex justify-center items-center py-2 mx-2",
                                            selectedHistory && "bg-gray-700 text-blue-500")}>
                                            <button onClick={() => {onClickHistoryDetail(searchSpecificationHistory)}}>
                                                Version {searchSpecificationHistory.version}
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </div>
                <div className="w-full py-5 px-5 flex justify-between">
                    {
                        showHistoryDetail &&
                        <div className="w-[1000px]">
                            <div className="border border-gray-400 rounded-md shadow-lg p-5 flex flex-col overflow-y-auto">
                                <div className="text-2xl font-semibold mb-3 border-b-2 pb-3">
                                    <div className="flex items-center justify-between">
                                        <div className={"flex justify-between items-center"}>
                                            <p>검색 설정</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-input-group flex">
                                    <label className="form-label min-w-[200px] text-lg">
                                        프롬프트 키
                                    </label>
                                    <div className="form-input w-full focus:outline-none">
                                        {selectedSearchSpecification?.key}
                                    </div>
                                </div>

                                <div className="form-input-group flex">
                                    <label className="form-label min-w-[200px] text-lg">
                                        설명
                                    </label>
                                    <div className="form-input w-full focus:outline-none">
                                        {selectedSearchSpecification?.description}
                                    </div>
                                </div>

                                <div className="form-input-group flex">
                                    <label className="form-label min-w-[200px] text-lg">
                                        검색엔진
                                    </label>
                                    <div className="form-input w-full focus:outline-none">
                                        {selectedSearchSpecification?.passageSearchSetting.type}
                                    </div>
                                </div>

                                {
                                    selectedSearchSpecification &&
                                    <div className="form-input-group flex items-start">
                                        <label className="form-label min-w-[200px] text-lg whitespace-pre-wrap">
                                            검색엔진 설정
                                        </label>
                                        <div className="w-full space-y-2">
                                            {Object.entries(selectedSearchSpecification.passageSearchSetting.setting).map((setting) => {
                                                const [name, value] = setting;
                                                return (
                                                    <div className="flex space-x-2">
                                                        <input className="form-input w-auto"
                                                               value={name}
                                                               readOnly={true}
                                                        />
                                                        <input className="form-input w-auto"
                                                               value={value}
                                                               readOnly={true}
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                }

                                <div className="form-input-group flex items-center">
                                    <label className="form-label min-w-[150px] text-lg">
                                        {intl.formatMessage({id: "i0196"})}
                                    </label>
                                    <p>{moment.utc(selectedSearchSpecification?.createdAt).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                                </div>

                                <div className="form-input-group flex items-center">
                                    <label className="form-label min-w-[150px] text-lg">
                                        {intl.formatMessage({id: "i0197"})}
                                    </label>
                                    <p>{moment.utc(selectedSearchSpecification?.updatedAt).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                                </div>
                            </div>
                        </div>
                    }
                </div>

            </div>
        </Modal>
    )
}

export default SearchSpecificationHistoryModal;

