import React, {useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useIntl } from "react-intl";
import useChannels from "../../query/channel/useChannels";
import channelRepository from "../../respsitory/ChannelRepository";
import { SiteBunnyAdminError } from "../../error";
import toast from "react-hot-toast";
import { QuestionTestProps } from "./LLMResponseTestPanel";
import ObjectKeySelect, { ObjectKeySelectRef } from "../ObjectKeySelect";
import { TestSetting } from "../../respsitory/QueryAnswerRepository";
import useChannelCommonSetting from "../../query/channelCommonSetting/useChannelCommonSetting";
import { SearchEngineType } from "../../model/searchSpecification";
import Select from 'react-select'
export interface PanelSettingHeaderRef {
    getSetting: () => TestSetting | undefined
    getShowQuestionReply: () => boolean | undefined
}

interface PanelSettingHeaderProps extends QuestionTestProps {
    close: () => void
}

const PanelSettingHeader = React.forwardRef<PanelSettingHeaderRef, PanelSettingHeaderProps>((props, ref) => {
    const {close, channelId} = props;

    const intl = useIntl();

    const [testChannelId, setTestChannelId] = useState<string | undefined>(channelId);
    const [showQuestionReply, setShowQuestionReply] = useState<boolean>();
    const [defaultCommonSetting, setDefaultCommonSetting] = useState<string | undefined>();

    const channelSelectRef = useRef<PanelSettingSelectRef>(null);
    const channelSettingSelectRef = useRef<PanelSettingSelectRef>(null);

    useImperativeHandle(ref, () => {
        return {
            getSetting: () =>  {
                if (!testChannelId || !channelSettingSelectRef.current?.get()) return;

                return {
                    channelId: testChannelId,
                    channelCommonSettingId: channelSettingSelectRef.current.get()!,
                }
            },
            getShowQuestionReply: () => showQuestionReply
        }
    }, [testChannelId, showQuestionReply])

    const getChannelInfo = useCallback(async (channelId: string) => {
        const { data } = await channelRepository.read(channelId);
        if (!data) throw new SiteBunnyAdminError("404", "Channel Not Found");
        return data;
    }, []);

    useEffect(() => {
        if(!testChannelId) return;
        getChannelInfo(testChannelId)
            .then((data) => {
                setDefaultCommonSetting(data.result.channel.setting.channelCommonSettingId);
                setShowQuestionReply(data.result.channel.setting.showQuestionReply);
            })
            .catch(() => {
                toast.error(intl.formatMessage({id: "i11031"}));
            });
    }, [testChannelId, getChannelInfo, intl]);

    const onChangeChannel = (channelId: string) => {
        setTestChannelId(channelId);
    };
    const onChangeChannelCommonSetting = (channelCommonSettingId: string) => {
        setDefaultCommonSetting(channelCommonSettingId);
    };

    return (
        <div className={"flex flex-col gap-5 border-gray-900 border-b h-full bg-indigo-600 text-white p-5"}>
            <div className={"h-2/5 flex justify-between"}>
                <div className={"text-3xl font-semibold"}>
                    {intl.formatMessage({id: "i11032"})}
                </div>
                <button className={"border border-white w-8 h-8 rounded-lg"} onClick={close}>X</button>
            </div>
            <div className={"flex gap-3 h-3/5 break-keep"}>
                <ChannelSettingSelect ref={channelSelectRef}
                                      defaultValue={channelId}
                                      onChange={onChangeChannel} />
                <ChannelCommonSettingSelect defaultValue={defaultCommonSetting} ref={channelSettingSelectRef}
                                      onChange={onChangeChannelCommonSetting}/>
            </div>
        </div>
    )
});

interface PanelSettingSelectRef {
    get: () => string | undefined
    set: (newValue: string) => void
}

interface PanelSettingChannelSelectProps {
    onChange?: (newValue: string) => void
    defaultValue?: string
}

interface PanelSettingSelectProps {
    onChange?: (newValue: string) => void
    defaultValue?: string
}


const ChannelSettingSelect = React.forwardRef<PanelSettingSelectRef, PanelSettingChannelSelectProps>((props, ref) => {
    const {defaultValue, onChange} = props

    const channelIdRef = useRef<ObjectKeySelectRef>(null);
    const {data: channelResponse} = useChannels(1, 2000, undefined, undefined, false);

    useImperativeHandle(ref, () => {
        return {
            get: () => channelIdRef.current?.get(),
            set: (newValue) => {
                channelIdRef.current?.set(newValue);
            }
        }
    });

    const onChangeSelect = (e: any) => {
        onChange && onChange(e.value);
        channelIdRef.current?.set(e.value);
    }

    if (!channelResponse?.channels) return <div></div>
    const channel = channelResponse.channels.filter(channel => channel._id === defaultValue)[0]
    const channelName = `${channel.name} [${channel.user?.fullName}]`
    return (
        <div className="flex items-center">
            <div className="mr-2">
                채널 이름 :
            </div>
            <div className="w-[400px]">
                <Select
                    className="text-black w-full"
                    defaultValue={{value: defaultValue, label: channelName}}
                    onChange={(e) =>onChangeSelect(e)}
                    options={channelResponse.channels.map((channel) => {
                        return {value: channel._id, label: `${channel.name} [${channel.user?.fullName}]`}
                    })}/>
            </div>
        </div>

    )
})

const ChannelCommonSettingSelect = React.forwardRef<PanelSettingSelectRef, PanelSettingSelectProps>((props, ref) => {
    const {defaultValue, onChange} = props
    const intl = useIntl();
    const settingKeyRef = useRef<ObjectKeySelectRef>(null);
    const {data: commonSettings} = useChannelCommonSetting();

    const [searchEngineType, setSearchEngineType] = useState<SearchEngineType>();

    useImperativeHandle(ref, () => {
        return {
            get: () => settingKeyRef.current?.get(),
            set: (newValue) => {
                // determine current setting' search engine type
                if (commonSettings) {
                    const target = commonSettings.find(setting => setting._id === newValue);
                    if (!target) return;
                    setSearchEngineType(target.searchEngineType);
                    settingKeyRef.current?.set(newValue);
                }
            }
        }
    }, [commonSettings, setSearchEngineType]);

    useEffect(() => {
        if (defaultValue && settingKeyRef.current && commonSettings) {
            const target = commonSettings.find(setting => setting._id === defaultValue);
            if (!target) return;
            setSearchEngineType(target.searchEngineType);
            settingKeyRef.current.set(defaultValue);
        }
    }, [defaultValue, commonSettings])

    if (!commonSettings) return <div></div>

    return (
        <ObjectKeySelect ref={settingKeyRef}
                         label={intl.formatMessage({id: "i11010"})}
                         items={commonSettings?.filter(setting => setting.searchEngineType === searchEngineType) || []}
                         onChange={onChange}
                         defaultValue={defaultValue}/>
    )
});

export default PanelSettingHeader;
