import axios from "axios";
import {API_HOST} from "../core/variables";
import {
    SearchEngineType,
    SearchSpecificationCreateRequest,
    SearchSpecificationUpdateRequest
} from "../model/searchSpecification";

class SearchSpecificationRepository {
    async find(key?: string, SearchEngineType?: SearchEngineType) {
        return axios.get(`${API_HOST}/v1/search`,{
            params: {
                key: key,
                search_engine_type: SearchEngineType
            }
        })
    }

    async findHistory(id: string) {
        return axios.get(`${API_HOST}/v1/search-specification-history/${id}`)
    }

    async existed(key: string) {
        return axios.get(`${API_HOST}/v1/search/existed`,{params: {key}})
    }

    async create(body: SearchSpecificationCreateRequest) {
        return axios.post(`${API_HOST}/v1/search`, body)
    }

    async update(id: string, body: SearchSpecificationUpdateRequest) {
        return axios.put(`${API_HOST}/v1/search/${id}`, body)
    }

    async delete(id: string) {
        return axios.delete(`${API_HOST}/v1/search/${id}`)
    }
}

const searchSpecificationRepository = new SearchSpecificationRepository();

export default searchSpecificationRepository;
