import {useQuery} from "@tanstack/react-query";
import {SiteBunnyAdminError, toSiteBunnyAdminError} from "../../error";

import {GlobalVariables} from "../../model/GlobalVariables";
import globalVariablesRepository from "../../respsitory/GlobalVariablesRepository";

const getData = async (key?: string, group?: string) => {
    try {
        const {data} = await globalVariablesRepository.find(key, group);
        return data.result;
    } catch (e) {
        throw toSiteBunnyAdminError(e);
    }
}

const DEFAULT_QUERY_OPTIONS = {retry: false, suspense: false, useErrorBoundary: true}

const useGlobalVariables = (key?: string, group?: string, props: { useErrorBoundary?: boolean } = {}) => {
    const options = {...DEFAULT_QUERY_OPTIONS, ...props}
    return useQuery<GlobalVariables[], SiteBunnyAdminError>({
        queryKey: ['globalVariable', key, group],
        queryFn: async () => getData(key, group),
        ...options
    })
}

export default useGlobalVariables;
