import LLMResponseResult from "../../../model/llmSearchTestPanel/extra/LLMResponseResult";
import React from "react";
import { useIntl } from "react-intl";
import { renderText } from "../component/DetailSettingTable";
import DetailCard from "../component/DetailCard";

interface LLMResponseSectionProps {
    latency: number
    result: LLMResponseResult
}

const LLMResponseSection: React.FC<LLMResponseSectionProps> = (props) => {
    const {latency, result} = props;
    const intl = useIntl();

    return (
        <DetailCard title={intl.formatMessage({id: "i11062"})}
                    latency={latency}>
            <>
                {result.answer && (
                    <div className="flex flex-col space-y-3 p-2  leading-6 whitespace-normal">
                        <div className={"font-semibold text-xl"}>{intl.formatMessage({id: "i11063"})}</div>
                        <div className={"ml-4 p-1 px-4 flex flex-col w-full border-2 rounded-xl"}>
                            {renderText(result.answer)}
                        </div>
                    </div>
                )}
            </>
        </DetailCard>
    );
};

export default LLMResponseSection;
