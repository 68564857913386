import {useQuery} from "@tanstack/react-query";
import {SiteBunnyAdminError, toSiteBunnyAdminError} from "../../error";
import {GlobalVariablesHistory} from "../../model/GlobalVariables";
import globalVariablesRepository from "../../respsitory/GlobalVariablesRepository";

const getData = async (id: string) => {
    try {
        const {data} = await globalVariablesRepository.findHistory(id);
        return data.result;
    } catch (e) {
        throw toSiteBunnyAdminError(e);
    }
}


const useGlobalVariableHistory = (id: string) => {
    return useQuery<GlobalVariablesHistory[], SiteBunnyAdminError>({
        queryKey: ['globalVariableHistory', id],
        queryFn: async () => getData(id),
    })
}

export default useGlobalVariableHistory;

