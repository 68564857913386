import React, {useCallback, useMemo, useState} from "react";
import {ChannelResponse} from "../../../model/channel/channel";
import moment from "moment";
import toast from "react-hot-toast";
import {useDialog} from "../../../hook/useDialog";
import channelRepository from "../../../respsitory/ChannelRepository";
import {RESTORATION_FEATURE_DEVELOPED_AT} from "../../../core/variables";
import {useQueryClient} from "@tanstack/react-query";

type ChannelDeleteAndRestoreTabProps = {
    channel: ChannelResponse
};

const ChannelDeleteAndRestoreTab: React.FC<ChannelDeleteAndRestoreTabProps> = (props) => {
    const { channel } = props;

    const dialog = useDialog();

    const [loading, setLoading] = useState(false);

    const queryClient = useQueryClient();

    const onMutate = useCallback(async () => {
        try {
            await queryClient.invalidateQueries({
                queryKey: ["channel", channel._id],
                exact: true
            })
        } catch (e) {
            toast.error("자동 새로고침에 실패했습니다. 페이지를 새로고침 해주세요.")
        }
    }, [queryClient, channel._id])

    const isDeletable = useMemo(() => {
        return !loading && !channel.deleted && !channel.user?.deleted;
    }, [channel, loading])

    const isRestorable = useMemo(() => {
        return !loading && channel.deleted && !channel.hardDeleted && !channel.user?.deleted;
    }, [channel, loading]);

    const isHardDeletable = useMemo(() => {
        return !loading && channel.deleted && !channel.hardDeleted;
    }, [channel, loading])

    const onClickDelete = () => {
        if (channel.deleted) {
            return;
        }

        dialog.open({
            variant: "danger",
            title: "채널 삭제",
            content: "채널을 삭제하시겠습니까? 삭제된 채널은 30일 이후에 자동으로 영구 삭제됩니다.",
            onConfirm: async () => {
                setLoading(true)

                try {
                    await channelRepository.deleteChannel(channel._id);
                    toast.success("채널을 삭제했습니다.")
                    await onMutate();
                } catch (e) {
                    toast.error("채널을 삭제하는 도중 에러가 발생했습니다.")
                } finally {
                    setLoading(false)
                }
            }
        })
    }

    const onClickRestore = () => {
        if (!channel.deleted || channel.hardDeleted) {
            return;
        }

        if (moment.utc(channel.deletedAt).isBefore(moment.utc(RESTORATION_FEATURE_DEVELOPED_AT))) {
            toast.error("복구 기능이 개발되기 이전에 삭제된 채널입니다.")
            return;
        }

        dialog.open({
            variant: "danger",
            title: "삭제된 채널 복구",
            content: "채널을 복구하시겠습니까?",
            onConfirm: async () => {
                setLoading(true)

                try {
                    await channelRepository.restoreChannel(channel._id);
                    toast.success("채널을 복구했습니다.")
                    await onMutate();
                } catch (e) {
                    toast.error("채널을 복구하는 도중 에러가 발생했습니다.")
                } finally {
                    setLoading(false)
                }
            }
        })
    }

    const onClickHardDelete = () => {
        if (!channel.deleted || channel.hardDeleted) {
             return;
        }

        if (moment.utc(channel.deletedAt).isBefore(moment.utc(RESTORATION_FEATURE_DEVELOPED_AT))) {
            toast.error("영구 삭제 기능이 개발되기 이전에 삭제된 채널입니다.")
            return;
        }

        dialog.open({
            variant: "danger",
            title: "채널 영구 삭제",
            content: "삭제 시점으로부터 30일이 지나면 자동으로 영구 삭제됩니다. 그래도 영구 삭제하시겠습니까?",
            onConfirm: async () => {
                setLoading(true)

                try {
                    await channelRepository.hardDeleteChannel(channel._id);
                    toast.success("채널을 영구 삭제했습니다.")
                    await onMutate();
                } catch (e) {
                    toast.error("채널을 영구 삭제하는 도중 에러가 발생했습니다.")
                } finally {
                    setLoading(false)
                }
            }
        })
    }

    return (
        <div className="space-y-3">
            <div className="w-full h-[100px] rounded-xl border border-red-400 bg-red-100 p-5 space-y-3">
                <p className="text-red-500">Danger Zone</p>
                <p className="text-sm">고객 요청 혹은 특수 목적이 아니라면 이 탭을 사용하지 마세요. 서비스 중인 채널에 영향을 줄 수 있습니다.</p>
            </div>

            <p>채널 삭제하기</p>
            <button className="btn btn-danger w-[120px]" disabled={!isDeletable} onClick={onClickDelete}>
                Delete
            </button>

            <p>삭제된 채널 복구하기 (삭제되지 않았거나 이미 영구 삭제된 채널은 복구할 수 없습니다. 또한, 고객이 탈퇴한 경우에도 복구할 수 없습니다.)</p>
            <button className="btn btn-danger w-[120px]" disabled={!isRestorable} onClick={onClickRestore}>
                Restore
            </button>

            <p>채널 영구 삭제하기 (삭제되지 않았거나, 이미 영구 삭제된 채널은 영구 삭제할 수 없습니다.)</p>
            <button className="btn btn-danger w-[120px]" disabled={!isHardDeletable} onClick={onClickHardDelete}>
                Hard Delete
            </button>
        </div>
    );
};

export default ChannelDeleteAndRestoreTab;
