import ChannelCommonSettingRepository from "../../respsitory/ChannelCommonSettingRepository";
import {ChannelCommonSetting} from "../../model/ChannelCommonSetting";
import {useQuery} from "@tanstack/react-query";

const getData = async (key?: string) => {
    try {
        const {data} = await ChannelCommonSettingRepository.find(key);
        return data.result
    } catch (e) {
        throw e;
    }
}

const useChannelCommonSetting = (key?: string) => {
    return useQuery<ChannelCommonSetting[], Error>({
        queryKey: ['channelCommonSetting', key],
        queryFn: async () => getData(key),
        retry: false,
        suspense: false,
    })
}

export default useChannelCommonSetting;
