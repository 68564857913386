import React, {useCallback, useMemo, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import LLMResponseReport from "../LLMResponseTestPanel/LLMResponseReport";
import useQueryLogs from "../../query/queryLog/useQueryLogs";
import QueryLogRow from "./QueryLogRow";
import PageBar from "../PageBar";
import {ChevronLeftIcon} from "@heroicons/react/24/outline";
import {IncomingRequestEventType} from "../../model/QueryLog";
import {AnswerType} from "../../model/llmSearchTestPanel/QueryAnswers";
import useQueryLog from "../../query/queryLog/useQueryLog";

const pageCount = 20;
const QueryLogDetail = () => {
    const { channelId, queryLogId } = useParams<{ channelId: string, queryLogId: string }>();
    const navigate = useNavigate();

    const {data: queryLog} = useQueryLog(queryLogId!)
    const [page, setPage] = useState<string>();
    const defaultPage: number = useMemo(() => {
        return parseInt(page ?? "1")
    }, [page]);
    const {data: queryLogResponsePage} = useQueryLogs(defaultPage, channelId!, queryLog?.type || IncomingRequestEventType.QUERY, pageCount);

    const onClickPage = useCallback((page: number) => {
        setPage(page.toString())
    }, [setPage]);

    const lastPage = useMemo(() => {
        if (!queryLogResponsePage) return 0;
        return Math.floor(queryLogResponsePage.count / pageCount + ((queryLogResponsePage.count % pageCount) && 1));
    }, [queryLogResponsePage])

    const onClickBack = (e: React.MouseEvent) => {
        e.preventDefault();
        navigate(`/channels/${channelId}`);
    };

    if (!queryLog) return <></>;

    return (
        <div className={"w-full h-full border p-5"}>
            <div className="flex justify-between mb-4">
                <div className={"flex items-center text-2xl font-semibold"}>
                    <button  onClick={onClickBack}>
                        <ChevronLeftIcon className="w-6 h-6 mr-5"/>
                    </button>
                    <div className="text-2xl font-bold">
                        테스트 로그 상세보기 페이지
                    </div>
                </div>
            </div>
            <div className={"flex justify-between"}>
                <div className="w-full">
                    {queryLog.type === IncomingRequestEventType.QUERY ?
                        <LLMResponseReport type={AnswerType.ANSWER} queryLog={queryLog} />
                        :
                        <LLMResponseReport type={AnswerType.REPLYQUESTION} queryLog={queryLog} />
                    }
                </div>
                <div className="border border-black rounded-2xl overflow-hidden min-w-[20%]">
                    <div className="p-2 bg-gray-100 border-b border-black">
                        {">> Logs"}
                    </div>
                    {queryLogResponsePage?.queryLogs.map((queryLog) => {
                        return (
                            <div key={queryLog._id} className="border-b border-black p-3">
                                <QueryLogRow queryLog={queryLog}/>
                            </div>
                        )
                    })}
                    <div>
                        <PageBar page={defaultPage} lastPage={lastPage} onClickPage={onClickPage}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QueryLogDetail;
