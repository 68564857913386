import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {LLMSpecificationHistory} from "../../model/llmSpecification";
import llmSpecificationRepository from "../../respsitory/LLMSpecificationRepository";

const getData = async (id: string) => {
    try {
        const { data } = await llmSpecificationRepository.findHistory(id);
        return data.result;
    } catch (e) {
        throw e;
    }
}

const useLLMSpecificationHistory = (id: string) => {
    return useQuery<LLMSpecificationHistory[], AxiosError>({
        queryKey: ["llmSpecificationHistory", id],
        queryFn: async () => getData(id),
        retry: false,
        suspense: false,
    })
}

export default useLLMSpecificationHistory;
