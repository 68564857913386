import axios from "axios";
import {API_HOST} from "../core/variables";
import {LLMSpecificationCreateRequest, LLMSpecificationUpdateRequest} from "../model/llmSpecification";

class LLMSpecificationRepository {
    async find(key?: string) {
        return axios.get(`${API_HOST}/v1/llms`, {params: {key}});
    }

    async findHistory(id: string) {
        return axios.get(`${API_HOST}/v1/llm-specification-history/${id}`);
    }

    async existed(key: string) {
        return axios.get(`${API_HOST}/v1/llms/existed`, {params: {key}});
    }

    async read(llmSpecificationKey: string) {
        return axios.get(`${API_HOST}/v1/llms/${llmSpecificationKey}`);
    }

    async create(body: LLMSpecificationCreateRequest) {
        return axios.post(`${API_HOST}/v1/llms`, body);
    }

    async update(llmSpecificationId: string, body: LLMSpecificationUpdateRequest) {
        return axios.put(`${API_HOST}/v1/llms/${llmSpecificationId}`, body);
    }

    async delete(llmSpecificationId: string) {
        return axios.delete(`${API_HOST}/v1/llms/${llmSpecificationId}`);
    }
}

const llmSpecificationRepository = new LLMSpecificationRepository();

export default llmSpecificationRepository;
