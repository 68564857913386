import channelRepository from "../../respsitory/ChannelRepository";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {ChannelSubscriptionHistory} from "../../model/subscription/channelSubscriptionHistory";

const getDate = async (channelId: string) => {
    try {
        const {data} = await channelRepository.findSubscriptionHistories(channelId);
        return data.result;
    } catch (e) {
        throw e;
    }
}

const useChannelSubscriptionHistories = (channelId: string) => {
    return useQuery<ChannelSubscriptionHistory[],AxiosError>({
        queryKey: ["channelSubscriptionHistories", channelId],
        queryFn: async () => getDate(channelId),
        retry: false,
        suspense: false
    })
}

export default useChannelSubscriptionHistories;
