import {DictType} from "./index";

type GPTPromptRole ='system' | 'assistant' |'user';

export interface GPTPrompt {
    role: GPTPromptRole
    content: string
}


export enum LLMVendorType {
    OPENAI = "openai",
    LLM42 = "llm42",
    AZURE_OPENAI = "azure-openai",
    NAVER = "naver",
}

export const OPENAI_SUPPORTED_MODELS = [
    "gpt-3.5-turbo",
    "gpt-3.5-turbo-16k",
    "gpt-4",
    "gpt-4-32k"
]

export const LLM42_SUPPORTED_MODELS = [
    "LLM42",
    "LLM42_qa",
    "LLM42_qc"
]

export const AZURE_OPENAI_SUPPORTED_MODELS = [
    "azure-chatgpt",
    "azure-chatgpt-1106"
]

export const NAVER_SUPPORTED_MODELS = [
    "HCX-002",
]

export interface LLMSpecificationBase {
    key: string;
    description?: string;
    dependency: boolean;
    vendor: LLMVendorType;
    model: string;
    parameters: DictType<any>
}

export interface LLMSpecification extends LLMSpecificationBase{
    _id: string;
    createdAt: string;
    updatedAt: string;
}

export interface LLMSpecificationHistoryBase {
    llmSpecification: LLMSpecification;
    version: number
}

export interface LLMSpecificationHistory extends LLMSpecificationHistoryBase {
    _id: string;
    createdAt: string;
    updatedAt: string;
}

export type LLMSpecificationCreateRequest = Omit<LLMSpecificationBase, "dependency">
export type LLMSpecificationUpdateRequest = Omit<LLMSpecificationBase, "dependency">
