import React, { ReactNode } from "react";

export const renderText: (text:string) => ReactNode = (text: string) => text.split("\n").map(row => row !== "" ? <div>{row}</div> : <br/>);

export const DetailSettingTable: React.FC<{children: ReactNode}> = ({children}) => (
    <div className={"w-full flex justify-center"}>
        <div className={"w-2/5 pt-2 flex flex-col items-center text-lg"}>
            {children}
        </div>
    </div>
)

export const DetailSettingRowFragment: (key: string, value: (string | number) | (string | number)[]) => ReactNode = (key, value) => (
    <div className="w-full flex start p-2 font-medium text-gray-900 rounded-lg">
        <span className="flex-1 ml-3 whitespace-nowrap">{key}</span>
        {!Array.isArray(value) &&
            <span className="inline-flex items-center justify-center px-2 ml-3 font-medium text-gray-800 bg-gray-200 rounded-full">{value}</span>}
        {Array.isArray(value) &&
            <div className={"flex flex-col space-y-1"}>{value.map((v) => <span className="inline-flex items-center justify-center px-2 ml-3 font-medium text-gray-800 bg-gray-200 rounded-full">{v}</span>)}</div>}
    </div>
)
