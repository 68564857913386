import {useMutation, useQueryClient} from "@tanstack/react-query";
import {
    GlobalVariableGroup,
    GlobalVariableGroupUpdateRequest,
} from "../../model/globalVariable/GlobalVariableGroup";
import {AxiosError} from "axios";
import GlobalVariableGroupRepository from "../../respsitory/GlobalVariableGroupRepository";

const useUpdateGlobalVariableGroup = () => {
    const queryClient = useQueryClient();
    return useMutation<GlobalVariableGroup, AxiosError, GlobalVariableGroupUpdateRequest & {_id: string}>({
        mutationKey: ["globalVariableGroup", "update"],
        mutationFn: async (body: GlobalVariableGroupUpdateRequest & {_id: string}) => {
            try {
                const {_id, ...extra} = body;
                const {data} = await GlobalVariableGroupRepository.update(_id, extra);
                return data.result
            } catch (e) {
                throw e;
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["globalVariableGroup"],
                exact: false
            })
        }
    })
}

export default useUpdateGlobalVariableGroup;
