import React from "react";
import {Disclosure, Transition} from "@headlessui/react";
import classNames from "classnames";

interface SettingDisclosureProps {
    title: string;
    description?: string;
    defaultOpen?: boolean;
    children: React.ReactNode;
    disabledColor?: boolean;
    border?: boolean;
}

const SettingDisclosure: React.FC<SettingDisclosureProps> = ({title, description, defaultOpen, disabledColor, border, children}) => {
    return (
        <Disclosure as="div" defaultOpen={defaultOpen} className={"w-full"}>
            {({open}) => (
                <div
                    className={classNames("border-2 px-4 py-2 rounded-lg flex flex-col gap-2", {
                        "border-blue-500  hover:border-blue-500": !disabledColor && open
                    },{"border-red-500": border})}
                >
                    <Disclosure.Button
                        className="transition-colors duration-200 flex w-full min-h-[4rem] justify-between items-center text-sm">
                        <div className={"text-lg font-bold flex flex-col items-start"}>
                            {title}
                            <div
                                className={"pt-1 pr-3 text-base font-normal text-stone-400 break-keep"}>{description}</div>
                        </div>
                        <div className={classNames(open && "rotate-90 transform", "mr-5")}>
                            <i className={"fa-solid fa-chevron-right"}/>
                        </div>
                    </Disclosure.Button>
                    <Transition enter="transition duration-300 ease-out transform"
                                enterFrom="-translate-x-3 opacity-0"
                                enterTo="opacity-100">
                        <Disclosure.Panel className="py-2 mx-2">
                            {children}
                        </Disclosure.Panel>
                    </Transition>
                </div>
            )}
        </Disclosure>
    );
};

export default SettingDisclosure;