import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {SearchSpecification} from "../../model/searchSpecification";
import searchSpecificationRepository from "../../respsitory/SearchSpecificationRepository";

const useDeleteSearchSpecification = () => {
    const queryClient = useQueryClient();

    return useMutation<SearchSpecification, AxiosError, string>({
        mutationKey: ["searchSpecification-specification","delete"],
        mutationFn: async (id: string) => {
            try {
                const {data} = await searchSpecificationRepository.delete(id);
                return data.result
            } catch (e) {
                throw e;
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["searchSpecification-specification"],
                exact: false,
            });
        }
    })
};

export default useDeleteSearchSpecification;
