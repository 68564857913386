import axios from "axios";
import {API_HOST} from "../core/variables";
import {ChannelSettingUpdate, Language} from "../model/channel/channel";
import {ChannelUpdateStarRequest} from "../query/channel/useUpdateChannelStar";
import {ChannelUpdateGroupRequest} from "../query/channel/useUpdateGroup";

class ChannelRepository {
    async find(pageNum: number, size?: number, searchType?: SearchType, searchKeyword?: string, deleted?: boolean,
               language?: Language, star?: boolean, groupId?: string, userId?: string) {
        return axios.get(`${API_HOST}/v1/channels`, {
            params: {
                page: pageNum,
                size: size,
                search_type: searchType,
                search_keyword: searchKeyword,
                deleted: deleted,
                language: language,
                star: star,
                group_id: groupId,
                user_id: userId
            }
        });
    };

    async read(channelId: string) {
        return axios.get(`${API_HOST}/v1/channels/${channelId}`);
    };

    async updateSetting(channelId: string, settingUpdate: ChannelSettingUpdate) {
        return axios.put(`${API_HOST}/v1/channels/${channelId}/setting`, settingUpdate);
    };

    async updateStar(body: ChannelUpdateStarRequest) {
        return axios.put(`${API_HOST}/v1/channels/star`, body);
    }

    async updateGroup(body: ChannelUpdateGroupRequest) {
        return axios.put(`${API_HOST}/v1/channels/group`, body);
    }

    async findSubscriptionHistories(channelId: string) {
        return axios.get(`${API_HOST}/v1/subscription-histories/${channelId}`);
    }

    async readChannelCard(channelId: string) {
        return axios.get(`${API_HOST}/v1/channel-card/${channelId}`)
    }

    async deleteChannel(channelId: string) {
        return axios.delete(`${API_HOST}/v1/channels/${channelId}`)
    }

    async hardDeleteChannel(channelId: string) {
        return axios.delete(`${API_HOST}/v1/channels/${channelId}/hard-delete`)
    }

    async restoreChannel(channelId: string) {
        return axios.put(`${API_HOST}/v1/channels/${channelId}/restore`)
    }
}

export enum SearchTypeEnum {
    CHANNELNAME = 'channel_name',
    USERNAME = 'user_name',
    GROUPNAME = 'group_name'
}

export type SearchType = SearchTypeEnum.CHANNELNAME | SearchTypeEnum.USERNAME | SearchTypeEnum.GROUPNAME

export const isSearchType = (text?: unknown): text is SearchType => {
    return (text === SearchTypeEnum.CHANNELNAME || text === SearchTypeEnum.USERNAME || text === SearchTypeEnum.GROUPNAME)
}
const channelRepository = new ChannelRepository();

export default channelRepository;
